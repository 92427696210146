import { gql } from "@apollo/client";
import { ASSESSMENT_FIELDS, QUESTION_FIELDS } from "graphql/fragments";

const GET_MISSION_REQUIREMENTS = gql`
  ${QUESTION_FIELDS}
  ${ASSESSMENT_FIELDS}
  query assessmentFetch($id: ID!) {
    assessmentFetch(id: $id) {
      ...AssessmentFields
      questions {
        ...QuestionFields
        children {
          ...QuestionFields
        }
      }
    }
  }
`;

export { GET_MISSION_REQUIREMENTS };
