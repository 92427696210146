import { gql } from "@apollo/client";

const SHORTLIST_APPLICANT = gql`
  mutation shortlistMissionRoleApplicants($input: ShortlistMissionRoleApplicantsInput!) {
    shortlistMissionRoleApplicants(input: $input) {
      shortlisted {
        id
        message
        profile {
          name
        }
      }
      failedToShortlist {
        id
        message
        profile {
          name
        }
      }
    }
  }
`;

const UNSELECT_APPLICANT = gql`
  mutation unselectMissionRoleApplicants($input: UnselectMissionRoleApplicantsInput!) {
    unselectMissionRoleApplicants(input: $input) {
      unselected {
        id
        message
      }
      failedToUnselect {
        id
        message
      }
    }
  }
`;

const UPDATE_APPLICANT = gql`
  mutation updateMissionRoleApplicant($input: UpdateMissionRoleApplicantInput!) {
    updateMissionRoleApplicant(input: $input) {
      unselected {
        id
        message
      }
      failedToUnselect {
        id
        message
      }
    }
  }
`;

const SELECT_APPLICANT = gql`
  mutation selectMissionRoleApplicants($input: SelectMissionRoleApplicantsInput!) {
    selectMissionRoleApplicants(input: $input) {
      selected {
        id
        state
      }
      failedToSelect {
        id
        state
      }
    }
  }
`;

const INTERVIEW_APPLICANT = gql`
  mutation interviewedMissionRoleApplicants($input: InterviewedMissionRoleApplicantsInput!) {
    interviewedMissionRoleApplicants(input: $input) {
      interviewed {
        id
        state
      }
      failedToInterviewed {
        id
        state
      }
    }
  }
`;

export { SHORTLIST_APPLICANT, UNSELECT_APPLICANT, UPDATE_APPLICANT, SELECT_APPLICANT, INTERVIEW_APPLICANT };
