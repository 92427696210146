import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { LINKS } from "constants/index";
import { formatDate } from "utils";
import { useAuth, useMissionInterviews } from "hooks";
import { CandidateInterviewsForm } from "components/Candidate";
import DisplayBox, { RenderIconWithText, RENDER_SIZE } from "components/DisplayBox";
import { PrimaryLink } from "components/Links";
import { UserPhoto } from "components/User";
import { ShowMore } from "components/Pagination";
import { margins } from "style";
import { RoleLink } from "components/Missions/Roles";

/**
 * CandidateInterviewList
 *
 * @param {String}   profileId
 */
const CandidateInterviewList = ({ profileId, ...props }) => {
  const [showForm, setShowForm] = useState(false);
  const { hasNextPage, handleNextPage, loading, data } = useMissionInterviews({ profileId });
  const { isAdmin, isRecruiter } = useAuth();
  const canAccess = isAdmin || isRecruiter;
  const isEmpty = !loading && (!data || data?.length === 0);

  if (!canAccess) return null;

  return (
    <>
      <DisplayBox
        data={data}
        title={<FormattedMessage id="Candidate.CandidateInterviews.Title" />}
        editButtonText={<FormattedMessage id="Candidate.CandidateInterviews.Create.Link" />}
        marginSize={margins.normal}
        emptyMsg={<FormattedMessage id="Candidate.CandidateInterviews.Empty" />}
        isEmpty={data?.length === 0 || loading}
        canAdd={canAccess}
        isEditable={canAccess}
        numColumns={1}
        onAdd={() => {
          setShowForm(true);
        }}
        itemRenderer={(interviewData) => (
          <RenderIconWithText
            key={interviewData?.id}
            size={RENDER_SIZE.large}
            icon={
              <UserPhoto
                borderRadius="100%"
                data={interviewData?.interviewer?.profile || interviewData?.interviewer}
                width={RENDER_SIZE.large}
                height={RENDER_SIZE.large}
              />
            }
            text={{
              title: interviewData?.interviewer?.profile ? (
                <PrimaryLink target="_blank" to={LINKS.private_profile(interviewData?.interviewer?.profile?.slug)}>
                  {interviewData?.interviewer?.name}
                </PrimaryLink>
              ) : (
                interviewData?.interviewer?.name
              ),
              description: (
                <FormattedMessage
                  id="Profile.Interviews.Information.Description"
                  values={{
                    role: (
                      <RoleLink
                        mission={interviewData?.missionRole.mission}
                        missionRole={interviewData?.missionRole}
                        withOverlay
                      />
                    ),
                    calendlyLink: (
                      <PrimaryLink href={interviewData?.calendlyLink} target="_blank">
                        <FormattedMessage id="Profile.Interviews.Calendly" />
                      </PrimaryLink>
                    ),
                    date: (
                      <div>
                        <FormattedMessage
                          id="Candidate.CandidateInterviews.InterviewRequested"
                          values={{ date: formatDate(interviewData?.createdAt) }}
                        />
                      </div>
                    ),
                  }}
                />
              ),
            }}
            hasLink={true}
          />
        )}
      />
      <ShowMore
        loading={loading}
        hasNextPage={!isEmpty && !loading && hasNextPage}
        onNextPage={handleNextPage}
        text={<FormattedMessage id="Profile.Interviews.ShowMore" />}
      />
      <CandidateInterviewsForm
        show={showForm}
        onClose={() => setShowForm(false)}
        isEditing={false}
        profileId={profileId}
        {...props}
      />
    </>
  );
};

CandidateInterviewList.propTypes = {
  profileId: PropTypes.string,
};

export default CandidateInterviewList;
