import React, { useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { ProposalForm } from "components/Missions/Proposal";
import { UPDATE_MISSION_REQUIREMENTS } from "graphql/mutations";
import { GET_MISSION_REQUIREMENTS } from "graphql/queries";
import { colors, fontSize, margins } from "style";
import { H3 } from "components/Text";
import { Header } from "components/DisplayBox";
import { PrimaryLink } from "components/Links";
import { EditIcon } from "components/Icon";

/**
 * ProposalButton
 *
 * @param {String}   type
 * @param {String}   updateId
 * @param {String}   marginSize
 * @param {Boolean}  isEditing
 * @param {Function} onClose
 * @param {Boolean}  show
 */
const ProposalButton = ({ type, updateId, marginSize, isEditing, onClose, show, ...props }) => {
  const [showForm, setShowForm] = useState(false);

  const handleClose = () => {
    setShowForm(false);
    onClose?.();
  };

  const mutationData = {
    mutationName: {
      update: UPDATE_MISSION_REQUIREMENTS,
    },
    refetchQueries: ["assessment"],
    refetchAfterMutate: false,
  };

  const queryData = {
    queryName: GET_MISSION_REQUIREMENTS,
    keyName: "assessmentFetch",
    variables: {
      id: updateId,
    },
  };

  if (!show) return null;

  return (
    <>
      {isEditing ? (
        <EditIcon onClick={() => setShowForm(true)} />
      ) : (
        <>
          <Header marginSize={marginSize}>
            <H3>
              <FormattedMessage id={`Mission.${type}.Title`} />
            </H3>
            <div></div>
          </Header>
          <div css={styles.description(marginSize)}>
            <PrimaryLink onClick={() => setShowForm(true)}>
              <FormattedMessage id={`Mission.assessment.Button`} />
            </PrimaryLink>
          </div>
        </>
      )}
      <ProposalForm
        show={showForm}
        onClose={handleClose}
        mutationData={mutationData}
        queryData={queryData}
        type={type}
        updateId={updateId}
        title={<FormattedMessage id={`Mission.${type}.FormTitle`} />}
        {...props}
      />
    </>
  );
};

const styles = {
  description: (marginSize) => css`
    font-size: ${fontSize.small};
    line-height: 1.75;
    color: ${colors.purpleRainDark2};
    position: relative;
    margin: 0 ${marginSize} 0 ${marginSize};
  `,
};

ProposalButton.defaultProps = {
  marginSize: margins.normal,
  isEditing: false,
  show: true,
};

ProposalButton.propTypes = {
  type: PropTypes.string.isRequired,
  updateId: PropTypes.string.isRequired,
  marginSize: PropTypes.string,
  isEditing: PropTypes.bool,
  onClose: PropTypes.func,
  show: PropTypes.bool,
};

export default ProposalButton;
