import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useNavigate } from "react-router";
import { UserPhoto } from "components/User";
import { getFullName } from "utils/Format";
import { PrimaryLink, WhiteLink } from "components/Links";
import { LINKS } from "constants/index";
import { Row } from "components/Containers";
import { BADGE_TYPES, FeaturedBadge } from "components/Badges";
import { useAuth } from "hooks";

/**
 * UserFullName
 *
 * @param {Object}   data
 * @param {Boolean}  loading
 * @param {Boolean}  withPhoto
 * @param {Object}   photoProps
 * @param {Boolean}  linkPublic
 * @param {Boolean}  pluralize
 * @param {Boolean}  onDark
 * @param {Boolean}  disableLink
 * @param {Boolean}  withLastInitial
 * @param {Function} onClick
 */
const UserFullName = ({
  data,
  loading,
  withPhoto,
  photoProps,
  linkPublic,
  pluralize,
  onDark,
  disableLink,
  withLastInitial,
  onClick,
  ...props
}) => {
  const navigate = useNavigate();
  const hasFeatureBadge = data?.badges?.find(({ name }) => name === BADGE_TYPES.featured);
  const { isClient, isShowcase, isAdmin } = useAuth();
  const id = isAdmin ? data?.slug : data?.uuid;

  const Link = onDark ? WhiteLink : PrimaryLink;

  const FullName = () => (
    <span>
      {getFullName(data, isClient || isShowcase || withLastInitial)}
      {pluralize && `'s`}
    </span>
  );

  const handleClick = (event) => {
    if (onClick) {
      event.stopPropagation();
      return onClick(data);
    }

    if (isAdmin && !linkPublic) {
      navigate(LINKS.private_profile(id));
    } else {
      window.open(LINKS.public_profile(id), "_blank");
    }
  };

  return (
    <StyledRow {...props}>
      {withPhoto && <UserPhoto data={data} {...photoProps} loading={loading} />}
      {id && !disableLink ? (
        <Link onClick={handleClick}>
          <FullName />
        </Link>
      ) : (
        <FullName />
      )}
      {hasFeatureBadge && <FeaturedBadge width="1rem" height="1rem" />}
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  flex-wrap: nowrap;
  display: inline-flex;
`;

UserFullName.defaultProps = {
  onDark: false,
};

UserFullName.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  withPhoto: PropTypes.bool,
  photoProps: PropTypes.object,
  linkPublic: PropTypes.bool,
  pluralize: PropTypes.bool,
  onDark: PropTypes.bool,
  disableLink: PropTypes.bool,
  withLastInitial: PropTypes.bool,
  onClick: PropTypes.func,
};

export default UserFullName;
