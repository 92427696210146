import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useAuth } from "hooks";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { LINKS } from "constants";
import { PrimaryLink } from "components/Links";

/**
 * MissionOrganizationName
 *
 * @param {Object}   mission
 * @param {String}   iconSize
 * @param {Boolean}  isLoading
 * @param {Boolean}  disableLink
 * @param {Boolean}  hideConfidentialIcon
 */
const MissionOrganizationName = ({ mission, iconSize, isLoading, disableLink, hideConfidentialIcon }) => {
  const { isAdmin, isClient } = useAuth();
  const authorizedUser = isAdmin || isClient;
  const isOrganizationConfidential = !authorizedUser && mission?.isOrganizationConfidential;
  const showConfidentialIcon = !hideConfidentialIcon && authorizedUser && mission?.isOrganizationConfidential;

  const messageValues = {
    name: mission?.organization?.name,
    icon: showConfidentialIcon && <Icon type={ICON_TYPE.eyeBlocked} size={iconSize} color="inherit" />,
  };

  if (isLoading) return null;

  return isAdmin && !disableLink && mission?.organization?.slug ? (
    <PrimaryLink to={LINKS.organization_profile(mission?.organization?.slug)} routerEnabled>
      <FormattedMessage
        id={`Missions.MissionOrganizationName.Name${isOrganizationConfidential ? "Confidential" : ""}`}
        values={messageValues}
      />
    </PrimaryLink>
  ) : (
    <FormattedMessage
      id={`Missions.MissionOrganizationName.Name${isOrganizationConfidential ? "Confidential" : ""}`}
      values={messageValues}
    />
  );
};

MissionOrganizationName.defaultProps = {
  iconSize: ICON_SIZE.large,
  disableLink: false,
  hideConfidentialIcon: false,
};

MissionOrganizationName.propTypes = {
  mission: PropTypes.object,
  iconSize: PropTypes.string,
  isLoading: PropTypes.bool,
  disableLink: PropTypes.bool,
  hideConfidentialIcon: PropTypes.bool,
};

export default MissionOrganizationName;
