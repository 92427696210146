import { gql } from "@apollo/client";

const GET_ORGANIZATIONS = gql`
  query GetOrganizations(
    $paginationInfo: PaginationInfo
    $filters: OrganizationFilter
    $orderBy: OrganizationOrderBy
    $first: Int
    $last: Int
    $after: String
    $before: String
    $limit: Int
  ) {
    organizations(
      paginationInfo: $paginationInfo
      filters: $filters
      orderBy: $orderBy
      first: $first
      last: $last
      after: $after
      before: $before
      limit: $limit
    ) {
      totalCount
      currentPage
      currentOffset
      perPage
      nodes {
        id
        name
        slug
        status
        archivedSince
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export { GET_ORGANIZATIONS };
