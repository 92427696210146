import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import { useQueryData } from "hooks";
import DataTable from "components/DataTable";
import { PAGINATION_TYPE, ShowMore, Paginate } from "components/Pagination";
 
/**
 * DataTableProvider
 *
 * @param {Function} onSelect
 * @param {String}   keyName
 * @param {Object}   queryName
 * @param {String}   fetchPolicy
 * @param {Object}   variables
 * @param {Number}   resultsPerPage
 * @param {Array}    columnData
 * @param {String}   paginationType
 * @param {Function} setTotalCount
 * @param {Object}   paginationProps
 * @param {Boolean}  onLoading
 * @param {Boolean}  selectable
 */
const DataTableProvider = forwardRef(
  (
    {
      onSelect,
      keyName,
      queryName,
      fetchPolicy,
      variables,
      resultsPerPage,
      columnData,
      paginationType,
      setTotalCount,
      paginationProps,
      selectable,
      onLoading,
      ...props
    },
    ref
  ) => {

  const [storedTotalCount, setStoredTotalCount] = useState(0);

  const {
    loadingMore,
    hasPreviousPage,
    hasNextPage,
    handlePreviousPage,
    handleNextPage,
    handlePageChange,
    refetch,
    loading,
    error,
    data,
    pageInfo,
    totalCount,
    currentPage,
    perPage
  } = useQueryData({
    queryName,
    variables,
    fetchPolicy,
    keyName,
    resultsPerPage,
    paginationType,
  });

  useEffect(() => {
    if (typeof totalCount !== "undefined") {
      setStoredTotalCount(totalCount);
    }
  }, [totalCount]);


  useEffect(() => {
    if (setTotalCount) {
      setTotalCount(totalCount || 0, data);
    }
  },[totalCount, data]);

  useEffect(() => {
    onLoading?.(loading);
  },[loading]);

  useImperativeHandle(ref, () => ({
    refetch,
  }),[]);

  const pProps = {
    type: paginationType,
    onNextPage: handleNextPage,
    onPreviousPage: handlePreviousPage,
    handlePageChange: handlePageChange,
    hasNextPage: !loading && hasNextPage,
    hasPreviousPage: !loading && hasPreviousPage,
    loading,
    loadingMore,
    pageInfo,
    totalCount: storedTotalCount,
    currentPage,
    resultsPerPage: perPage,
    ...paginationProps,
  };

  return (
    <>
      {paginationType === PAGINATION_TYPE.classic && <Paginate {...pProps} isTop />}
      <DataTable
        data={data && data[keyName].nodes}
        pagination={data && data[keyName].pageInfo}
        /** For loading state: to show the resultsPerPage rows */
        resultsPerPage={resultsPerPage}
        error={error}
        loading={loading || loadingMore}
        columns={columnData}
        selectable={selectable}
        onSelect={onSelect}
        {...props}
      />
      {paginationType === PAGINATION_TYPE.append && (
        <ShowMore loading={loading} hasNextPage={!loading && hasNextPage} onNextPage={handleNextPage} />
      )}
      {paginationType && (
        <Paginate {...pProps} />
      )}
    </>
  );
});

DataTableProvider.defaultProps = {
  resultsPerPage: 10,
  paginationType: PAGINATION_TYPE.classic,
  paginationProps: {},
};

DataTableProvider.propTypes = {
  onSelect: PropTypes.func,
  keyName: PropTypes.string,
  queryName: PropTypes.object,
  resultsPerPage: PropTypes.number,
  columnData: PropTypes.array,
  paginationType: PropTypes.string,
  fetchPolicy: PropTypes.string,
  variables: PropTypes.object,
  setTotalCount: PropTypes.func,
  paginationProps: PropTypes.object,
  selectable: PropTypes.bool,
  onLoading: PropTypes.func,
};

export default DataTableProvider;
