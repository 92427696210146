import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage, useIntl } from "react-intl";
import { ADD_MISSION_ROLE_APPLICANT } from "graphql/mutations";
import Modal, { ModalForm, MODAL_SIZE } from "components/Modal";
import { RoleApplyError } from "components/Missions/Roles";
import { SIZE, TYPE, DECORATORS } from "components/Form";
import { ICON_TYPE, ICON_SIZE, ICON_POSITION } from "components/Icon";
import { AVAILABILITIES, CURRENCIES, PAYMENT_FREQUENCIES } from "constants/index";
import { MUTATION_TYPE, useToast, useMissionRoleEligibility, useAuth, useProfile } from "hooks";
import { colors } from "style";
import { shortenText } from "utils";

/**
 * Apply Button
 *
 * @param {Object}    role
 * @param {Object}    modalFormProps
 * @param {String}    profileId
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Function}  onLoading
 */
const ApplyButton = ({ role, modalFormProps, profileId, show, onClose, onLoading, ...props }) => {
  const intl = useIntl();
  const { toast } = useToast();
  const { canViewEditRate } = useAuth();
  const [profileRateData, setProfileRateData] = useState({});
  const [missionRoleId, setMissionRoleId] = useState(role?.uuid);
  const [roleRequirements, setRoleRequirements] = useState([]);
  const { isEligible, reason, loading } = useMissionRoleEligibility({ profileId, missionRoleId, skip: !show });
  const { loading: loadingProfile, data } = useProfile({ profileId });

  const handleComplete = () => toast.success(<FormattedMessage id={"Missions.MissionRole.ApplyForm.Success"} />);
  const handleError = () => {
    onClose();
    toast.error(<FormattedMessage id="Missions.MissionRole.ApplyForm.Error" />);
  };

  useEffect(() => {
    setProfileRateData({
      frequency: PAYMENT_FREQUENCIES.hourly,
      amount: data?.profile?.rate?.amount,
      currency: data?.profile?.rate?.currency,
    });
  }, [data?.profile?.rate]);

  useEffect(() => {
    setMissionRoleId(role?.uuid);
    setRoleRequirements(role?.mission?.missionRequirements ? role?.mission?.missionRequirements : []);
  }, [role?.uuid, role?.mission?.missionRequirements]);

  useEffect(() => {
    onLoading(loadingProfile || loading);
  }, [loading, loadingProfile]);

  // If member is not eligible to apply then show an error prompt
  if (!loading && !isEligible) {
    return (
      <Modal
        show={!loadingProfile && !loading && show}
        title={<FormattedMessage id="Missions.MissionRole.ApplyForm.TitleError" />}
        info={shortenText(role?.name, 30)}
        showSave={false}
        showCancel={true}
        secondaryButtonMessageId={"Global.Close"}
        size={MODAL_SIZE.medium}
        onClose={onClose}
      >
        <RoleApplyError isEligible={isEligible} loading={loading} reason={reason} role={role} />
      </Modal>
    );

    // ...otherwise show the apply form
  } else {
    return (
      <>
        <ModalForm
          show={!loadingProfile && !loading && show}
          info={shortenText(role?.name, 35)}
          size={MODAL_SIZE.medium}
          onClose={onClose}
          data={FORM_DATA(
            [
              ...roleRequirements,
              ...[
                {
                  description: intl.formatMessage(
                    { id: "Missions.MissionRole.ApplyForm.Engagement" },
                    { engagement: role?.engagementTime }
                  ),
                },
              ],
            ],
            canViewEditRate
          )}
          title={<FormattedMessage id="Missions.MissionRole.ApplyForm.Title" />}
          description={<FormattedMessage id="Missions.MissionRole.ApplyForm.Description" />}
          initialValues={canViewEditRate ? { rate: profileRateData, profileId } : { profileId }}
          numColumns={4}
          parentKeyValue={missionRoleId}
          parentKeyName={"missionRoleId"}
          mutationType={MUTATION_TYPE.add}
          onCompleted={handleComplete}
          onError={handleError}
          disabled={loading || !isEligible}
          mutationData={{
            mutationName: {
              add: ADD_MISSION_ROLE_APPLICANT,
            },
          }}
          {...props}
          {...modalFormProps}
        />
      </>
    );
  }
};

const OPTIONS_RATE = [
  {
    label: "Hourly Rate",
    type: TYPE.input,
    size: SIZE.medium,
    parentObjectKey: "rate",
    valueFunc: (val) => parseInt(val),
    properties: {
      name: "amount",
      type: "number",
      required: true,
    },
    decorator: {
      type: DECORATORS.icon,
      props: {
        type: ICON_TYPE.dollarBill,
        size: ICON_SIZE.medium,
        position: ICON_POSITION.overlay,
      },
    },
  },
  {
    label: "Currency",
    type: TYPE.select,
    size: SIZE.small,
    parentObjectKey: "rate",
    properties: {
      name: "currency",
      required: true,
      options: CURRENCIES,
    },
    decorator: {
      type: DECORATORS.icon,
      props: {
        type: ICON_TYPE.coin,
        size: ICON_SIZE.medium,
        position: ICON_POSITION.overlay,
      },
    },
  },
];

const OPTION_APPLICATION_TITLE = [
  {
    label: <FormattedMessage id="Missions.MissionRole.ApplyForm.ApplicationLabel" />,
    type: TYPE.h4,
    size: SIZE.xlarge,
  },
];

const OPTION_AVAILABILITIES_AND_MESSAGE = () => [
  {
    label: <FormattedMessage id="Missions.MissionRole.ApplyForm.TimeBeforeStarting" />,
    type: TYPE.select,
    size: SIZE.xlarge,
    properties: {
      name: "noticePeriod",
      required: true,
      options: AVAILABILITIES,
    },
  },
  {
    type: TYPE.checkbox,
    properties: {
      name: "terms",
      label: (
        <span css={styles.terms}>
          <FormattedMessage id="Missions.MissionRole.ApplyForm.Terms" />
        </span>
      ),
      required: true,
    },
  },
  {
    type: TYPE.hidden,
    properties: {
      name: "profileId",
    },
  },
];

const OPTION_REQUIREMENTS = (options) => [
  {
    label: <FormattedMessage id="Missions.MissionRole.ApplyForm.FormLabel" />,
    type: TYPE.h4,
    size: SIZE.xlarge,
  },
  {
    type: TYPE.checkBoxGroup,
    size: SIZE.xlarge,
    properties: {
      name: "requirements",
      options,
      valueKey: "id",
      labelKey: "description",
      requiredAll: true,
    },
  },
];

const FORM_DATA = (options, canViewEditRate) => [
  {
    style: css`
      width: 100%;
    `,
    items:
      options?.length > 0
        ? [
            ...OPTION_REQUIREMENTS(options),
            ...OPTION_APPLICATION_TITLE,
            ...(canViewEditRate ? OPTIONS_RATE : []),
            ...OPTION_AVAILABILITIES_AND_MESSAGE(),
          ]
        : [
            ...OPTION_APPLICATION_TITLE,
            ...(canViewEditRate ? OPTIONS_RATE : []),
            ...OPTION_AVAILABILITIES_AND_MESSAGE(),
          ],
  },
];

const styles = {
  terms: css`
    font-weight: 500;
    color: ${colors.red};
  `,
};

ApplyButton.propTypes = {
  role: PropTypes.object,
  modalFormProps: PropTypes.string,
  profileId: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onLoading: PropTypes.func,
};

export default ApplyButton;
