import React from "react";
import PropTypes from "prop-types";
import DisplayBox from "components/DisplayBox";
import Tags from "components/Tags";
import { FormattedMessage } from "react-intl";

/**
 * RoleCodeChallenges
 *
 * @param {Array}    data
 */
const RoleCodeChallenges = ({ data, ...props }) => (
  <DisplayBox numColumns={1} data={CODE_CHALLENGES_DATA(data)} {...props} />
);

const CODE_CHALLENGES_DATA = (data) => [
  {
    title: null,
    body:
      data?.challenges?.length > 0 ? <Tags data={data?.challenges} /> : <FormattedMessage id="Global.NotProvided" />,
  },
];

RoleCodeChallenges.propTypes = {
  data: PropTypes.object,
};

export default RoleCodeChallenges;
