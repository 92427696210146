import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { formatCurrency } from "utils";
import { TAG_COLORS, TAG_SIZE } from "components/Tags/Tag";
import { Tag } from "components/Tags";

/**
 * PreferredRateTag
 *
 * @param {Object}  rate
 * @param {String}  size
 * @param {Object}  color
 */
const PreferredRateTag = ({ rate, color, size, ...props }) => (
  <Tag color={color} size={size} {...props}>
    {rate?.amount ? (
      <FormattedMessage
        id="Profile.PreferredRateTag.Rate"
        values={{
          rate: formatCurrency(rate.amount, rate?.currency?.isoCode),
        }}
      />
    ) : (
      <FormattedMessage id="Global.NotProvided" />
    )}
  </Tag>
);

PreferredRateTag.defaultProps = {
  size: TAG_SIZE.small,
  color: TAG_COLORS.purple,
};

PreferredRateTag.propTypes = {
  rate: PropTypes.object.isRequired,
  size: PropTypes.string,
  color: PropTypes.object,
};

export default PreferredRateTag;
