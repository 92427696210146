import { gql } from "@apollo/client";

const GET_CONNECTION = gql`
  query connection($id: ID) {
    connection(id: $id) {
      id
      type
      status
      recordId
      recordType
      installationId
      organizationSlug
      repositories {
        fullName
        name
        latestImportAt
      }
      sender {
        id
        login
      }
    }
  }
`;

export { GET_CONNECTION };
