import { useState, useEffect } from "react";
import { useQueryData } from "hooks";
import { GET_CONNECTION } from "graphql/queries";

/**
 * useConnection
 *
 * @param {String}  id
 * @param {String}  type
 * @param {String}  recordType
 * @param {String}  recordId
 * @param {Boolean} shouldSkip
 */
export default function useConnection({ id, type, recordType, recordId, shouldSkip }) {
  const [data, setData] = useState();

  const {
    loading,
    data: response,
    error,
  } = useQueryData({
    queryName: GET_CONNECTION,
    keyName: "connection",
    skip: shouldSkip,
    variables: { id, type, recordType, recordId },
  });

  useEffect(() => {
    if (response) {
      setData(response.connection);
    }
  }, [response]);

  return {
    loading,
    data,
    error,
  };
}
