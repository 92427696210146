import { gql } from "@apollo/client";

const ADD_MISSION_ROLE_APPLICANT = gql`
  mutation AddMissionRoleApplicant($input: AddMissionRoleApplicantInput!) {
    addMissionRoleApplicant(input: $input) {
      missionRoleApplicant {
        missionRole {
          name
          id
          applicantsProfileIds
          missionRoleApplicant {
            id
            appliedAt
          }
        }
        profile {
          id
        }
        noticePeriod
      }
    }
  }
`;

const ADD_MISSION_ROLE_INVITATION = gql`
  mutation AddMissionRoleInvitations($input: AddMissionRoleInvitationsInput!) {
    addMissionRoleInvitations(input: $input) {
      missionRoleInvitations {
        id
        profile {
          id
        }
        missionRole {
          name
        }
      }
      profilesNotInvited {
        name
      }
    }
  }
`;

const REMOVE_MISSION_ROLES = gql`
  mutation RemoveMissionRoles($input: RemoveMissionRolesInput!) {
    removeMissionRoles(input: $input) {
      clientMutationId
      ids
    }
  }
`;
export { ADD_MISSION_ROLE_APPLICANT, ADD_MISSION_ROLE_INVITATION, REMOVE_MISSION_ROLES };
