import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { UserFullName } from "components/User";
import { DataTableProvider } from "components/DataTable";
import { Toggle } from "components/Form";
import { Row } from "components/Containers";
import { Header } from "components/DisplayBox";
import { H3 } from "components/Text";
import { SquadVacationDaysLineItem } from "components/Squads";
import { margins } from "style";

/**
 * SquadVacationDays
 *
 * @param {Array}     data
 * @param {Boolean}   loading
 * @param {Object}    error
 * @param {String}    marginSize
 */
const SquadVacationDays = ({ data, loading, error, marginSize, ...props }) => {
  const [isPastDays, setIsPastDays] = useState(false);
  const handleChange = () => setIsPastDays(!isPastDays);

  return (
    <>
      <Header marginSize={marginSize}>
        <H3>
          <FormattedMessage id="Squads.Squad.Profile.VacationDaysList.Title" />
        </H3>
        <Row>
          <FormattedMessage id="Squads.Squad.Profile.VacationDaysList.PastDays" />
          <Toggle onChange={handleChange} value={isPastDays} name="pastDaysToggle" label="" />
        </Row>
      </Header>
      <DataTableProvider
        data={data}
        error={error}
        loading={loading}
        columns={COLUMNS(isPastDays)}
        paginationType={false}
        {...props}
      />
    </>
  );
};

const COLUMNS = (isPastDays) => [
  {
    width: 250,
    label: <FormattedMessage id="Squads.Squad.Profile.VacationDaysList.ColumnMember" />,
    cell: (row) => <UserFullName data={row.profile} linkPublic />,
  },
  {
    label: <FormattedMessage id="Squads.Squad.Profile.VacationDaysList.ColumnDate" />,
    cell: (row) => <SquadVacationDaysLineItem vacations={row.vacations} isPastDays={isPastDays} />,
  },
];

SquadVacationDays.defaultProps = {
  marginSize: margins.normal,
};

SquadVacationDays.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.object,
  marginSize: PropTypes.string,
};

export default SquadVacationDays;
