import { gql } from "@apollo/client";

const UPDATE_TEAM = gql`
  mutation UpdateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      team {
        id
        slug
        name
        size
        missionId
        status
        gitRepositories
        teamProfilesAccounts {
          id
          accountNames
          profile {
            id
            name
          }
        }
        organization {
          id
          slug
          name
        }
      }
    }
  }
`;

export { UPDATE_TEAM };
