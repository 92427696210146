import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import Card from "components/Card";
import { css } from "@emotion/react";
import { fontSize, colors } from "style";
import { PrimaryButton, BUTTON_SIZE } from "components/Buttons";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { shortenText } from "utils";
import { TextLoader } from "components/Loader";
import { Table, TableBody, TableRow, TableCell } from "components/Table";
import { LINKS } from "constants/index";
import { Link } from "components/Links";

/**
 * MissionCard
 *
 * @params {Object}     data
 * @params {Boolean}    isLoading
 */
const MissionCard = ({ data, isLoading }) => {
  if (isLoading) {
    return <PlaceHolder />;
  }

  return (
    <Card css={styles.container}>
      <div css={styles.company}>{data?.organization?.name}</div>
      <div css={styles.title}>{data?.name}</div>
      <div css={styles.body}>{shortenText(data?.description, 200)}</div>
      <div css={styles.data_container}>
        <div css={styles.label}>
          <FormattedMessage id="Missions.MissionCard.LabelTimezone" />
        </div>
        <div css={styles.item}>
          <Icon size={ICON_SIZE.small} type={ICON_TYPE.pin} />
          <span>{data?.timezone?.name}</span>
        </div>
        <div css={styles.label}>
          <FormattedMessage id="Missions.MissionCard.LabelStartDate" />
        </div>
        <div css={styles.item}>
          <Icon size={ICON_SIZE.small} type={ICON_TYPE.calendar} />
          <span>{moment(data?.startDate).format("MMMM Do, YYYY")}</span>
        </div>
        <div css={styles.label}>
          <FormattedMessage id="Missions.MissionCard.LabelRoles" />
        </div>
        <Table layout="auto">
          <TableBody>
            {data?.roles?.map((role) => (
              <TableRow key={role.name} selectable={false}>
                <TableCell fullWidth layout="auto">
                  <span>{shortenText(role?.name, 50)}</span>
                </TableCell>
                <TableCell fullWidth alignRight>
                  <Link to={LINKS.mission_role(data?.uuid, role?.slug)} routerEnabled>
                    <PrimaryButton size={BUTTON_SIZE.small}>
                      <FormattedMessage id="Global.View" />
                    </PrimaryButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Card>
  );
};

const PlaceHolder = () => (
  <Card css={styles.container}>
    <div css={styles.company}>
      <TextLoader
        css={css`
          width: 30%;
        `}
      />
    </div>
    <div css={styles.title}>
      <TextLoader
        css={css`
          width: 80%;
          height: 3rem;
        `}
      />
    </div>
    <div css={styles.body}>
      <TextLoader
        css={css`
          width: 100%;
        `}
      />
      <TextLoader
        css={css`
          width: 100%;
        `}
      />
      <TextLoader
        css={css`
          width: 100%;
        `}
      />
      <TextLoader
        css={css`
          width: 100%;
        `}
      />
      <TextLoader
        css={css`
          width: 60%;
        `}
      />
    </div>
  </Card>
);

const styles = {
  container: css`
    padding: 3rem;
  `,
  company: css`
    font-size: ${fontSize.xsmall};
    color: ${colors.grayAnatomyLight1};
    font-weight: 500;
  `,
  title: css`
    font-size: ${fontSize.large};
    font-weight: 600;
    margin-top: 0.5rem;
    line-height: 1.25;
  `,
  body: css`
    font-size: ${fontSize.small};
    margin-top: 2rem;
    line-height: 1.5;
    margin-bottom: 2.5rem;
  `,
  label: css`
    font-size: ${fontSize.xsmall};
    font-weight: 600;
    margin-top: 1.5rem;
    margin-bottom: 0.25rem;
    background: ${colors.grayAnatomyLight5};
    padding: 0.75rem 1rem;
    margin-left: -1rem;
    margin-right: -1rem;
  `,
  item: css`
    font-size: ${fontSize.xsmall};
    color: ${colors.grayAnatomyLight1};
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0rem;
  `,
};

MissionCard.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default MissionCard;
