import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";
import { useParams } from "react-router-dom";
import { PageWrapper } from "components/Containers";
import PageTitle from "components/PageTitle";
import { PrimaryLink } from "components/Links";
import { LINKS, TRACKING_PAGE_VIEWS } from "constants/index";
import {
  RoleDescription,
  RoleDetails,
  ApplyButton,
  RoleAdvancedOptions,
  RelatedRoles,
} from "components/Missions/Roles";
import { MissionAboutClient } from "components/Missions";
import Card from "components/Card";
import { Grid } from "components/Containers";
import { useAuth, useTracking } from "hooks";

/**
 * Mission Role
 */
const MissionRolePage = () => {
  const { roleId } = useParams();
  const { isClient, isShowcase, isAdmin, canApplyForRolesOnBehalf, canViewUnpublishedRoles } = useAuth();
  const { trackPage } = useTracking();
  const [data, loading] = useOutletContext();
  const selectedRole = data?.mission?.roles.filter((role) => role.slug === roleId);
  const mission = data?.mission;
  const role = selectedRole && { ...selectedRole[0], mission };
  const intl = useIntl();

  const MissionBoardBackLink = () => {
    if (isClient || isShowcase) {
      return LINKS.client_mission_board;
    } else {
      return LINKS.mission(mission?.uuid);
    }
  };

  useEffect(() => {
    data && trackPage(TRACKING_PAGE_VIEWS["roleDetails"].page_name, TRACKING_PAGE_VIEWS["roleDetails"]);
  }, [data]);

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Missions.Role.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            backLink={
              <PrimaryLink to={MissionBoardBackLink()} routerEnabled>
                {isClient || isShowcase ? <FormattedMessage id="Missions.Mission.Role.Title" /> : mission?.name}
              </PrimaryLink>
            }
            title={role?.name}
            actionBar={
              <>
                <ApplyButton role={role} canApplyOnBehalf={canApplyForRolesOnBehalf}>
                  <FormattedMessage id={`Global.Apply.Long${canApplyForRolesOnBehalf ? ".ApplyOnBehalf" : ""}`} />
                </ApplyButton>
                <RoleAdvancedOptions roleId={role?.id} data={role} />
              </>
            }
            loading={loading}
          />
        </Grid.col>
        <Grid.col start={1} end={9}>
          <Card>
            <RoleDescription role={role} isLoading={loading} showArcheTypes={isAdmin} />
          </Card>
        </Grid.col>
        <Grid.col start={9} end={13} rowSpan={100} row={2}>
          <Grid>
            <Grid.col start={1} end={13}>
              <Card>
                <RoleDetails role={role} mission={mission} isLoading={loading} />
              </Card>
            </Grid.col>
            <Grid.col start={1} end={13}>
              <Card>
                <RelatedRoles
                  missionId={mission?.uuid}
                  isLoading={loading}
                  canViewUnpublishedRoles={canViewUnpublishedRoles}
                />
              </Card>
            </Grid.col>
            <Grid.col start={1} end={13}>
              <Card>
                <MissionAboutClient mission={mission} isLoading={loading} />
              </Card>
            </Grid.col>
          </Grid>
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default MissionRolePage;
