import { useQueryData } from "hooks";
import {  GET_CHALLENGE_RESPONSES } from "graphql/queries";
import { PAGINATION_TYPE } from "components/Pagination";

/**
 * useProfileCodeChallenge
 *
 * @params {String}   profileId
 * @params {Number}   resultsPerPage
 */
export default function useProfileCodeChallenge({ profileId, resultsPerPage = 2 }) {

  const { hasNextPage, handleNextPage, loading, data, refetch } = useQueryData({
    queryName: GET_CHALLENGE_RESPONSES,
    keyName: "challengeResponses",
    resultsPerPage,
    paginationType: PAGINATION_TYPE.append,
    variables: {
      profileId
    }
  });

  return {
    hasNextPage: hasNextPage,
    handleNextPage,
    refetch,
    loading: loading,
    data: data?.challengeResponses?.nodes
  };
}
