import React, { useState } from "react";
import PropTypes from "prop-types";
import Overlay from "components/Overlay";
import { LINKS } from "constants";
import ProfileCard from "./ProfileCard";
import { UserFullName } from "components/User";
import { useAuth } from "hooks";
import ProfileModal from "./ProfileModal";
import { PrimaryButton } from "components/Buttons";

/**
 * RoleOverlay
 *
 * @param {Object} profile
 * @param {Boolean} withOverlay
 */
const ProfileLink = ({ profile, withOverlay, ...props }) => {
  const { isAdmin } = useAuth();
  const [showProfileModal, setShowProfileModal] = useState(false);
  const handleShowProfile = () => setShowProfileModal(true);
  const Link = () => <UserFullName data={profile} {...props} />;

  if (!withOverlay) {
    return <Link />;
  }

  return (
    <>
      <Overlay
        asCardOverlay
        placement="top"
        width={`30rem`}
        overlay={
          <ProfileCard
            data={{ ...profile }}
            onClick={handleShowProfile}
            primaryAction={<PrimaryButton isExpand>View Profile</PrimaryButton>}
          />
        }
        trigger={<Link />}
        {...props}
      />
      <ProfileModal
        id={profile?.slug}
        show={showProfileModal}
        handleClose={() => setShowProfileModal(false)}
        handlePrimary={() => {
          window.open(isAdmin ? LINKS.private_profile(profile?.slug) : LINKS.public_profile(profile?.uuid), "_blank");
          setShowProfileModal(false);
        }}
      />
    </>
  );
};

ProfileLink.propTypes = {
  profile: PropTypes.object.isRequired,
  withOverlay: PropTypes.bool,
};

export default ProfileLink;
