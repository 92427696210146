import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { useAuth } from "hooks";
import { LINKS } from "constants/index";
import { Link } from "components/Links";
import { UserFullName } from "components/User";
import { Row } from "components/Containers";
import { BUTTON_SIZE, PrimaryButton } from "components/Buttons";
import { breakpoints, fontSize } from "style";
import { NAV_WIDTH } from "components/Nav/SideNav";

/**
 * Impersonating
 */
const Impersonating = (props) => {
  const { isImpersonating, trueUser, memberRole, ...me } = useAuth();

  if (!isImpersonating) return null;

  const profile = {
    firstName: me?.firstName,
    lastName: me?.lastName,
    avatarUrl: me?.avatarUrl,
    ...me?.profile,
  };

  return (
    <Container offset={NAV_WIDTH} {...props}>
      <div>
        <FormattedMessage
          id="Global.Impersonating"
          values={{
            impersonator: <User>{trueUser?.name}</User>,
            impersonatee: <UserFullName data={profile} />,
            role: <Role>{memberRole}</Role>,
          }}
        />
      </div>
      <Link to={LINKS.stop_impersonating}>
        <PrimaryButton size={BUTTON_SIZE.small} href={LINKS.stop_impersonating}>
          <FormattedMessage id="Global.StopImpersonating" />
        </PrimaryButton>
      </Link>
    </Container>
  );
};

const Role = styled.span`
  font-size: ${fontSize.xxsmall};
  padding: 0.25rem 0.5rem;
  border-radius: 0.6rem;
  border: 1px rgba(0, 0, 0, 0.1) solid;
`;

const Container = styled(Row)`
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  position: fixed;
  bottom: 3rem;
  border-radius: 4rem;
  z-index: 10;
  padding: 1rem 2rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  left: calc(50% + ${({ offset }) => parseInt(offset) / 2}rem);
  transform: translate(-50%, 0);
  flex-wrap: nowrap;
  width: fit-content;
  max-width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;

  @media (max-width: ${breakpoints.tablet}) {
    left: 50%;
  }

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    border-radius: 1rem;
    padding: 2rem;
  }
`;

const User = styled.span`
  font-weight: 600;
`;

export default Impersonating;
