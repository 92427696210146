import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { ModalForm, MODAL_SIZE } from "components/Modal";
import { TYPE, METHOD } from "components/Form";
import { PAGES } from "constants/index";
import { useTimeCard } from "hooks";

/**
 * TimeCardReopenForm
 *
 * @param {object} data
 * @param {boolean} show
 * @param {function} setShow
 */
const TimeCardReopenForm = ({ data, show, setShow }) => {
  const { handleReopenTimeCard, acceptTimeCardLoading, reopenTimeCardLoading, sendToDeelLoading } = useTimeCard();

  const handleSubmit = (formData) => {
    const { message } = formData;

    handleReopenTimeCard(data?.timeCardReport?.id, message);
  };

  return (
    <ModalForm
      show={show}
      size={MODAL_SIZE.medium}
      onClose={() => setShow(false)}
      data={FORM_DATA}
      title={<FormattedMessage id="TimeCard.TimeCardsTable.ReopenButton.Title" />}
      onSubmit={handleSubmit}
      method={METHOD.post}
      loading={acceptTimeCardLoading || reopenTimeCardLoading || sendToDeelLoading}
      page={PAGES.reopenTimecards}
    />
  );
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Message",
        type: TYPE.textarea,
        properties: {
          name: "message",
          placeholder: "Optionally, add a brief message",
          autoFocus: true,
        },
      },
    ],
  },
];

TimeCardReopenForm.propTypes = {
  data: PropTypes.object,
  show: PropTypes.bool,
  setShow: PropTypes.function,
};

export default TimeCardReopenForm;
