import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { useAuth } from "hooks";
import ListTable from "components/ListTable";
import { LINKS } from "constants/index";
import { PrimaryLink } from "components/Links";
import { MissionOrganizationName } from "components/Missions";
import { fontSize, margins } from "style";
import { ICON_SIZE, ICON_TYPE, withIcon } from "components/Icon";

/**
 * RoleDetails
 *
 * @params {Object}   role
 * @params {Object}   mission
 * @params {String}   marginSize
 * @params {Boolean}  isLoading
 */
const RoleDetails = ({ role, mission, marginSize, isLoading, ...props }) => {
  const { isShowcase, ...me } = useAuth();
  const missionLinkTo = isShowcase ? LINKS.showcase_mission(mission?.uuid, me?.uuid) : LINKS.mission(mission?.uuid);

  return (
    <ListTable
      data={isLoading ? null : TABLE_DATA(mission, role, missionLinkTo, isLoading)}
      loading={isLoading}
      title={<FormattedMessage id="Missions.MissionRole.RoleDetails" />}
      secondaryTitle={
        <LinkRole
          css={styles.primary_link}
          href={LINKS.public_mission_role(mission?.uuid, role?.slug)}
          target="_blank"
          withMoreIcon
        >
          <FormattedMessage id="Missions.MissionRole.PublicRoleLink" />
        </LinkRole>
      }
      marginSize={marginSize}
      resultsPerPage={TABLE_DATA().length}
      {...props}
    />
  );
};

const TABLE_DATA = (mission, role, missionLinkTo, isLoading) => [
  {
    label: <FormattedMessage id="Missions.MissionRole.RoleTitle" />,
    value: role?.name,
  },
  {
    label: <FormattedMessage id="Missions.MissionRole.RoleId" />,
    value: role?.slug,
  },
  {
    label: <FormattedMessage id="Missions.MissionCard.MissionName" />,
    value: (
      <PrimaryLink to={missionLinkTo} routerEnabled>
        {mission?.name}
      </PrimaryLink>
    ),
  },
  {
    label: <FormattedMessage id="Missions.MissionRole.ExpectedStartDate" />,
    value: moment(role?.startDate).format("MMMM Do, YYYY"),
  },
  {
    label: <FormattedMessage id="Global.Engagement" />,
    value: `${role?.engagementTime} hours/week`,
  },
  {
    label: <FormattedMessage id="Global.Timezone" />,
    value: role?.timezone?.name || mission?.timezone?.name,
  },
  {
    label: <FormattedMessage id="Missions.MissionRole.Company" />,
    value: <MissionOrganizationName mission={mission} isLoading={isLoading} iconSize={ICON_SIZE.medium} />,
  },
];

const styles = {
  primary_link: css`
    font-size: ${fontSize.small};
    line-height: 1.3;
  `,
};

const LinkRole = withIcon(PrimaryLink, {
  type: ICON_TYPE.eye,
  size: ICON_SIZE.medium,
  color: "inherit",
});

RoleDetails.defaultProps = {
  marginSize: margins.normal,
};

RoleDetails.propTypes = {
  role: PropTypes.object,
  mission: PropTypes.object,
  marginSize: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default RoleDetails;
