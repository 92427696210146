import React from "react";
import PropTypes from "prop-types";
import TextLoader from "../Loader/TextLoader";
import { TableRow, TableCell } from "components/Table";

/**
 * Shows a row loader
 *
 * @params {Boolean}  loading
 * @params {Number}   numRows
 * @params {Number}   numColumns
 */
const TableLoader = ({ loading, numRows, numColumns, ...props }) =>
  loading &&
  Array.from(Array(numRows).keys()).map((key) => (
    <TableRow key={key} data-loader>
      <TableCell colSpan={numColumns} alignMiddle {...props}>
        <TextLoader />
      </TableCell>
    </TableRow>
  ));

TableLoader.propTypes = {
  loading: PropTypes.bool,
  numRows: PropTypes.number.isRequired,
  numColumns: PropTypes.number.isRequired,
};

export default TableLoader;
