import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { css } from "@emotion/react";
import { useTeam } from "hooks";
import Card from "components/Card";
import { Spinner } from "components/Loader";
import Form, { SIZE, TYPE } from "components/Form";
import { Header } from "components/DisplayBox";
import { H3 } from "components/Text";
import { FormattedMessage } from "react-intl";
import { Banner } from "components/Banner";
import { BANNER_TYPE, CONNECTION_STATES } from "constants/index";
import { formatDate } from "utils";
import { colors, margins } from "style";

/**
 * ScopedRepositoriesForm
 * @param {Object} data
 * @param {Object} githubApplicationConnection
 * @param {Boolean} loading
 */
const ScopedRepositoriesForm = ({ data, githubApplicationConnection, loading }) => {
  const { updateTeam } = useTeam({});
  const [shouldBeDisabled, setShouldBeDisabled] = useState(false);

  useEffect(() => {
    if (githubApplicationConnection?.status === CONNECTION_STATES.suspended) {
      setShouldBeDisabled(true);
    }
  }, [githubApplicationConnection]);

  const handleSubmit = (values) => {
    const gitRepositories = values.gitRepositories
      .filter((repo) => repo.fullName && repo.fullName.trim() !== "")
      .map((repo) => repo.fullName)
      .join(",");

    updateTeam(data.slug, { gitRepositories });
  };

  const formProps = {
    initialValues: {
      gitRepositories: data?.gitRepositories?.split(",").map((repo) => ({ fullName: repo })),
    },
  };

  return (
    <Card>
      <Header>
        <H3>
          <FormattedMessage id="Squads.Profile.ScopedRepositoriesForm.Title" />
        </H3>
      </Header>
      {loading ? (
        <Spinner size={`1.5rem`} />
      ) : (
        <>
          {githubApplicationConnection && (
            <Form
              data={FORM_DATA(githubApplicationConnection, shouldBeDisabled)}
              onSubmit={(values) => handleSubmit(values)}
              saveButton={{ show: true, disabled: shouldBeDisabled }}
              marginSize={margins.none}
              noPadding
              {...formProps}
            />
          )}
          {!githubApplicationConnection && (
            <Banner type={BANNER_TYPE.warning} isClosable={false}>
              <FormattedMessage id="Squads.Profile.ScopedRepositoriesForm.NoConnection" />
            </Banner>
          )}
        </>
      )}
    </Card>
  );
};

const FORM_DATA = (connection, disabled) => [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        type: TYPE.checkBoxGroup,
        size: SIZE.xlarge,
        disabled: disabled,
        properties: {
          name: "gitRepositories",
          options: connection?.repositories,
          labelFunc: ({ fullName, latestImportAt }) =>
            latestImportAt ? (
              <>
                <span>{fullName}</span>
                <span css={styles.checkbox_label_desc}>
                  <FormattedMessage
                    id="Squads.Settings.SignalInstallation.ScopedRepositories.SyncDate"
                    values={{ date: formatDate(latestImportAt, "MMM DD, YYYY", true) }}
                  />
                </span>
              </>
            ) : (
              <span>{fullName}</span>
            ),
          valueKey: "fullName",
          limit: 100,
        },
      },
    ],
  },
];

const styles = {
  checkbox_label_desc: css`
    color: ${colors.grayAnatomyLight1};
  `,
};

ScopedRepositoriesForm.propTypes = {
  data: propTypes.object.isRequired,
  githubApplicationConnection: propTypes.object,
  loading: propTypes.bool,
};

export default ScopedRepositoriesForm;
