/**
 * Format decimal
 *
 * @param {Number}    number
 * @param {Number}    decimalPlaces
 *
 * @returns {Number} The formatted decimal
 */
export default function formatDecimal(number, decimalPlaces = 2) {
  const factor = Math.pow(10, decimalPlaces);

  return Math.round((Number(number) + Number.EPSILON) * factor) / factor;
}
