import Form from "./Form";
import TextInput, { Password } from "./TextInput";
import InputHidden from "./InputHidden";
import TagsInput from "./TagsInput";
import TextArea from "./TextArea";
import Button from "./Button";
import Link from "./Link";
import DatePicker from "./DatePicker";
import MonthPicker from "./MonthPicker";
import Links from "./Links";
import CheckBox from "./CheckBox";
import Toggle from "./Toggle";
import CheckBoxGroup from "./CheckBoxGroup";
import Select from "./Select";
import Switch from "./Switch";
import Schedule from "./Schedule";
import Photo from "./Photo";
import GeoLocator from "./GeoLocator";
import Resume from "./Resume";
import BoxSelector from "./BoxSelector";
import Radio, { RADIO_DIRECTION } from "./Radio";
import Slider from "./Slider";
import { withIcon } from "components/Icon";
import withText from "./WithText";
import CreateNewInstance from "./CreateNewInstance";
import MinMaxInput from "./MinMaxInput";

export { default as TextInput } from "./TextInput";
export { default as TagsInput } from "./TagsInput";
export { default as TextArea } from "./TextArea";
export { default as Button } from "./Button";
export { default as Link } from "./Link";
export { default as DatePicker } from "./DatePicker";
export { default as Links } from "./Links";
export { default as CheckBox } from "./CheckBox";
export { default as CheckBoxDropDown } from "./CheckBoxDropDown";
export { default as Toggle } from "./Toggle";
export { default as CheckBoxGroup } from "./CheckBoxGroup";
export { default as Select } from "./Select";
export { default as Switch } from "./Switch";
export { default as Photo } from "./Photo";
export { default as Resume } from "./Resume";
export { default as FormElement } from "./FormElement";
export { default as FormError } from "./FormError";
export { default as FormFieldError } from "./FormFieldError";
export { default as Schedule } from "./Schedule";
export { default as Label } from "./Label";
export { default as BoxSelector } from "./BoxSelector";
export { default as Radio } from "./Radio";
export { default as CopyToClipboardInput } from "./CopyToClipboardInput";
export { default as MonthPicker } from "./MonthPicker";
export { Password } from "./TextInput";
export { default as CreateNewInstance } from "./CreateNewInstance";
export { default as FormLabel } from "./FormLabel";
export { default as Slider } from "./Slider";
export { default as MinMaxInput } from "./MinMaxInput";
export { default as Editor } from "./Editor";
export { default as InputHidden } from "./InputHidden";
export { defaultValue as editorDefaultValue } from "./Editor";

const SIZE = {
  small: "small",
  medium: "medium",
  large: "large",
  xlarge: "xlarge",
};

const TYPE_KEYNAMES = {
  radio: "radio",
  textarea: "textarea",
  select: "select",
  slider: "slider",
  createNewInstance: "createNewInstance",
  checkBoxGroup: "checkBoxGroup",
  tags: "tags",
};

const TYPE = {
  input: TextInput,
  schedule: Schedule,
  tags: TagsInput,
  textarea: TextArea,
  password: Password,
  button: Button,
  datePicker: DatePicker,
  monthPicker: MonthPicker,
  checkbox: CheckBox,
  toggle: Toggle,
  checkBoxGroup: CheckBoxGroup,
  link: Link,
  p: "p",
  h4: "h4",
  links: Links,
  select: Select,
  geoLocator: GeoLocator,
  switch: Switch,
  photo: Photo,
  resume: Resume,
  boxSelector: BoxSelector,
  radio: Radio,
  slider: Slider,
  minMaxInput: MinMaxInput,
  createNewInstance: CreateNewInstance,
  hidden: InputHidden,
  label: "label",
};

const METHOD = {
  post: "post",
  get: "get",
  patch: "patch",
  delete: "delete",
  put: "put",
};

const DECORATORS = {
  icon: withIcon,
  text: withText,
};

export { SIZE, TYPE, METHOD, DECORATORS, TYPE_KEYNAMES };

/**
 * The TextArea component is not accessing SIZE constant in
 * defaultProps of TextArea component
 */
TextArea.defaultProps = {
  height: SIZE.xlarge,
};

/**
 * The Radio component is not accessing SIZE constant in
 * defaultProps of TextArea component
 */
Radio.defaultProps = {
  position: RADIO_DIRECTION.vertical,
};

export default Form;
