import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { Row } from "components/Containers";
import { breakpoints, colors, fontSize, margins } from "style";

/**
 * Formats a page title
 *
 * @param {Element}  title
 * @param {Element}  actionBar
 */
const SectionTitle = ({ title, actionBar, ...props }) => (
  <div css={styles.container} {...props}>
    <StyledRow>
      <div css={styles.title}>
        {title}
      </div>
      <div>
        <div css={styles.action_container}>
          <Row>{actionBar}</Row>
        </div>
      </div>
    </StyledRow>
  </div>
);

const StyledRow = styled(Row)`
  justify-content: space-between;
`;

const styles = {
  container: css`
    margin: 1rem ${margins.normal} 0 ${margins.normal};

    @media (max-width: ${breakpoints.portrait}) {
      margin: 1rem 2.5rem 0rem 2.5rem;
    }

    @media (max-width: ${breakpoints.mobile}) {
      margin: 1rem 2rem 0rem 2rem;
    }
  `,
  action_container: css`
    margin-left: auto;
  `,
  title: css`
    color: ${colors.purpleRainDark2};
    font-size: ${fontSize.medium};
    font-weight: 500;

    @media (max-width: ${breakpoints.tablet}) {
      font-size: ${fontSize.small};
    }
  `,
};

SectionTitle.propTypes = {
  title: PropTypes.element.isRequired,
  actionBar: PropTypes.any,
};

export default SectionTitle;
