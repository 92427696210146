import { gql } from "@apollo/client";
import {
  RESULT_FILTER_METADATA,
  DISCIPLINE_FIELDS,
  BADGES_FIELDS,
  SKILLS_FIELDS,
  LOCATION_FIELDS,
  PROFILE_AVAILABILITY_FIELDS,
  RATE_FIELDS,
  PROFILE_ID_FIELDS,
  PROFILE_BASIC_FIELDS,
  WORK_EXPERIENCES_FIELDS,
  TIMESTAMP_FIELDS,
} from "graphql/fragments";

const GET_NETWORK_PROFILES = gql`
  ${RESULT_FILTER_METADATA}
  ${DISCIPLINE_FIELDS}
  ${SKILLS_FIELDS}
  ${LOCATION_FIELDS}
  ${WORK_EXPERIENCES_FIELDS}
  ${RATE_FIELDS}
  ${PROFILE_ID_FIELDS}
  ${PROFILE_BASIC_FIELDS}
  ${PROFILE_AVAILABILITY_FIELDS}
  ${BADGES_FIELDS}
  ${TIMESTAMP_FIELDS}
  query networkProfiles(
    $search: String
    $filters: [NetworkProfileFilter!]
    $resultFilterMetadata: [String!]
    $sort: String
    $sortDirection: String
    $paginationInfo: PaginationInfo
    $includeWorkExperiences: Boolean = true
    $includePartner: Boolean = false
    $includeProfileList: Boolean = false
    $listId: ID
  ) {
    networkProfiles(
      search: $search
      filters: $filters
      resultFilterMetadata: $resultFilterMetadata
      sort: $sort
      sortDirection: $sortDirection
      paginationInfo: $paginationInfo
    ) {
      networkProfiles {
        ...ProfileIdFields
        ...BadgesFields
        ...ProfileBasicFields
        ...ProfileAvailabilityFields
        ...DisciplineFields
        ...SkillsFields
        ...LocationFields
        ...RateFields
        ...WorkExperiencesFields @include(if: $includeWorkExperiences)
        ...TimestampFields
        completedInterviewsCount
        openToEmployment
        canShowInterviews
        sellingPriceEstimate
        assignedSellingRate {
          amount
          currency
          frequency
          symbol
        }
        profileList(listId: $listId) @include(if: $includeProfileList) {
          id
          negotiatedRate
          negotiatedRateCurrency
          note
        }
        partner @include(if: $includePartner) {
          id
        }
      }
      resultFilterMetadata {
        ...ResultFilterMetadata
      }
      pageInfo {
        totalCount
        currentPage
        currentOffset
        hasNextPage
        hasPreviousPage
        perPage
      }
    }
  }
`;

export { GET_NETWORK_PROFILES };
