/** @jsx jsx */
import styled from "@emotion/styled";

/**
 * Col
 */
const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({gap}) => gap || `0`};
`;

export default Col;
