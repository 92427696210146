import React, { useEffect } from "react";
import { forwardRef } from "react";
import { css } from "@emotion/react";
import { useInView } from "react-intersection-observer";
import PropTypes from "prop-types";
import ActionButton from "./Action";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";
import DropDown, { DropDownMenu, DropDownItem, DropDownToggle } from "components/DropDown";
import { Spinner } from "components/Loader";
import { colors } from "style";

/**
 * AdvancedButton with DropDown
 *
 * @param {Array}    options
 * @param {Boolean}  loading
 * @param {Boolean}  loadingMore
 * @param {Boolean}  hasNextPage
 * @param {String}   iconType
 * @param {String}   iconSize
 * @param {Boolean}  onDark
 * @param {Function} handleInView
 * @param {Boolean}  isTransparent
 */
const AdvancedButton = ({
  options,
  loading,
  loadingMore,
  hasNextPage,
  iconType,
  iconSize,
  onDark,
  handleInView,
  isTransparent,
  ...props
}) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      if (typeof handleInView === "function") {
        handleInView();
      }
    }
  }, [inView]);

  return (
    <DropDown>
      <DropDownToggle
        as={CustomToggle}
        css={styles.toggle(onDark, isTransparent)}        
        {...props}
        disabled={(!options || options?.length === 0) || props.disabled}
      >
        {loading ? (
          <Spinner size={`1.5rem`} />
        ) : (
          <Icon
            type={iconType}
            size={iconSize}
            css={css`
              color: ${onDark ? `#fff` : `inherit`};
            `}
          />
        )}
      </DropDownToggle>
      <DropDownMenu css={styles.dropdownMenu}>
        {options?.length > 0 &&
          options?.map((item, index) => (
            <DropDownItem isDisabled={item.disabled} key={index} onClick={item.onClick}>
              {item.icon && <Icon type={item.icon} />}
              <>{item.label}</>
            </DropDownItem>
          ))}
        {(loadingMore || hasNextPage) && (
          <DropDownItem>
            <Spinner size={`1.5rem`} />
          </DropDownItem>
        )}
        <div ref={ref} />
      </DropDownMenu>
    </DropDown>
  );
};

const CustomToggle = forwardRef(({ children, onClick, ...props }, ref) => (
  <ActionButton ref={ref} css={styles.button} onClick={onClick} {...props}>
    {children}
  </ActionButton>
));

const styles = {
  button: css`
    width: 4rem;
  `,
  toggle: (onDark, isTransparent) => css`
    display: inline-flex;
    align-items: center;
    ${onDark && `background: ${colors.purpleRainDark4};`}
    ${isTransparent && `background: none;`}
  `,
  dropdownMenu: css`
    padding: 0;
    overflow: auto;
    max-height: 20rem;
  `,
};

CustomToggle.displayName = "CustomToggle";

CustomToggle.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

AdvancedButton.propTypes = {
  options: PropTypes.array,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  iconType: PropTypes.string,
  iconSize: PropTypes.string,
  onDark: PropTypes.bool,
  handleInView: PropTypes.func,
  isTransparent: PropTypes.bool,
};

AdvancedButton.defaultProps = {
  iconType: ICON_TYPE.more,
  iconSize: ICON_SIZE.xsmall,
  options: [],
  onDark: false,
};

export default AdvancedButton;
