import { gql } from "@apollo/client";

const GET_TIME_SHEETS = gql`
  query GetTimeSheets(
    $month: String
    $year: String
    $filters: TimeSheetsFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderBy: TimeSheetOrderBy
    $paginationInfo: PaginationInfo
  ) {
    timeSheets(
      month: $month
      year: $year
      filters: $filters
      first: $first
      last: $last
      after: $after
      before: $before
      orderBy: $orderBy
      paginationInfo: $paginationInfo
    ) {
      totalCount
      currentPage
      perPage
      submittedCount
      nodes {
        id
        uuid
        firstName
        lastName
        email
        name
        avatarUrl
        memberSince
        slug
        deelContractId
        deelContractUrl
        timeCardReport(month: $month, year: $year) {
          id
          status
          deelLastSyncAt
          offDates {
            startDate
            endDate
          }
          lastEntry
          loggedHours
          loggedMinutes
          daysOffCount
          allocatedHours
          allocatedMinutes
          differenceHours
          differenceMinutes
          usedHoursPercent
          timeSheetsTotalPerTeam {
            team {
              id
              name
              slug
              organization {
                slug
              }
            }
            rate {
              amount
              currency {
                isoCode
              }
            }
            deelStatus
            communication
            review
            planning
            production
            allocatedHours
            allocatedMinutes
            totalLoggedHours
            totalLoggedMinutes
            onboardedAt
            offboardedAt
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

const GET_PROFILE_TIME_SHEETS = gql`
  query GetProfileTimeSheets($orderBy: TimeSheetOrderBy, $first: Int, $last: Int, $after: String, $before: String) {
    profileTimeSheets(orderBy: $orderBy, first: $first, last: $last, after: $after, before: $before) {
      nodes {
        id
        monthPeriod
        notes
        period
        status
        submitted
        submittedAt
        monthlyTeamSurvey {
          id
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export { GET_TIME_SHEETS, GET_PROFILE_TIME_SHEETS };
