import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";

/**
 * Show a generic error message
 *
 * @params {Element}  children
 */
const FormFieldError = ({ children }) => (
  <div data-error css={style}>
    {children}
  </div>
);

const style = css`
  font-size: ${fontSize.xsmall};
  color: ${colors.orangeCountyDark2};
  font-weight: 500;
  margin-top: 0.5rem;
  order: 2;
  padding-left:0.8rem;

  &:nth-of-type(1) {
    margin-top:0.8rem;
  }
`;

FormFieldError.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormFieldError;
