import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { TextArea } from "components/Form";

/**
 * TimeSlipInput
 *
 * @param {Function}    onUpdate
 * @param {Object}      payload
 */
const NotesInput = ({ onUpdate, payload, ...props }) => {
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(payload?.description);
  }, [payload?.description]);

  return (
    <StyledInput
      value={value}
      placeholder="Add details here..."
      onChange={(name, val) => setValue(val)}
      onBlur={(e) => {
        const eventValue = e.target.value;

        // Only update if value has changed
        if (payload?.description !== eventValue) {
          setValue(eventValue);
          onUpdate(e, payload);
        }
        e.target.scrollTo(0, 0);
      }}
      isInvisible
      {...props}
    />
  );
};

const StyledInput = styled(TextArea)`
  padding-left: 1rem;
  height: 3.25rem;
  margin-left: -1rem;
  width: 100%;
  background: #fff;
  border-color: transparent;
  overflow: hidden;
  min-width: 100%;

  &:focus {
    height: 10rem;
    overflow: auto;
  }
`;

NotesInput.propTypes = {
  payload: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default NotesInput;
