import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useToast } from "hooks";
import { DECORATORS, SIZE, TYPE } from "components/Form";
import { ICON_TYPE, ICON_SIZE, ICON_POSITION } from "components/Icon";
import { ADD_MISSION_ROLE, UPDATE_MISSION_ROLE } from "graphql/mutations";
import { MUTATION_TYPE } from "hooks";
import {
  GET_MISSION_ROLES,
  GET_TIME_ZONES,
  GET_ADMINS,
  GET_DISCIPLINES,
  GET_SKILLS,
  GET_LOCATIONS,
  GET_CHALLENGES,
} from "graphql/queries";
import { PAGES, ARCHETYPES } from "constants/index";
import { ModalForm, MODAL_SIZE } from "components/Modal";

/**
 * RoleAddButton
 *
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Object}    data
 * @param {String}    missionId
 * @param {Boolean}   loading
 * @param {Boolean}   isEditing
 */
const RoleAddButton = ({ show, onClose, data, missionId, loading, isEditing }) => {
  const { toast } = useToast();
  const archetypes = Object.values(ARCHETYPES).map(({ id, name }) => ({ id, name }));

  if (isEditing && loading) return null;

  const initialValues = {
    name: data?.name,
    description: data?.description,
    archetype: archetypes.filter((item) => data?.archetype?.some((id) => item.id === id)) || [],
    primarySkills: data?.primarySkills?.map(({ name, id }) => ({ name, id })),
    secondarySkills: data?.secondarySkills?.map(({ name, id }) => ({ name, id })),
    disciplines: data?.disciplines?.map(({ name, id }) => ({ name, id })),
    startDate: data?.startDate,
    engagementTime: data?.engagementTime,
    targetRate: data?.targetRate,
    targetRateCurrency: data?.targetRateCurrency,
    timezone: data?.timezone?.value,
    isVisible: data?.isVisible ? true : false,
    assignedToId: data?.assignedTo?.id,
    remoteAnywhere: data?.remoteAnywhere,
    // TODO: To be removed when upgrading apollo client to 3.8 stable (https://github.com/apollographql/apollo-feature-requests/issues/6)
    /* eslint-disable no-unused-vars */
    challenges: data?.challenges?.map(({ __typename, ...challenge }) => challenge),
    locations: data?.locations?.map(({ __typename, ...location }) => location),
    /* eslint-enable no-unused-vars */
  };

  const formProps = {
    initialValues,
    updateId: data?.id,
    parentKeyValue: missionId,
    parentKeyName: "missionId",
    mutationType: isEditing ? MUTATION_TYPE.update : MUTATION_TYPE.add,
    mutationData: {
      mutationName: {
        add: ADD_MISSION_ROLE,
        update: UPDATE_MISSION_ROLE,
      },
      refetchQueries: [GET_MISSION_ROLES],
      refetchAfterMutate: true,
    },
  };

  const handleComplete = () => {
    onClose();
    toast.success(<FormattedMessage id={`Missions.RoleAddButton.${isEditing ? `EditSuccess` : `CreateSuccess`}`} />);
  };

  const handleError = () => {};

  return (
    <ModalForm
      data={[...FORM_DATA_LEFT_COL, ...FORM_DATA_RIGHT_COL]}
      show={show}
      size={MODAL_SIZE.large}
      onClose={onClose}
      title={<FormattedMessage id={`Mission.RoleForm.${isEditing ? `Edit` : `Create`}FormTitle`} />}
      description={<FormattedMessage id={`Mission.RoleForm.${isEditing ? `Edit` : `Create`}FormDescription`} />}
      onCompleted={handleComplete}
      onError={handleError}
      {...formProps}
      page={isEditing ? PAGES.editMissionRole : PAGES.addMissionRole}
    />
  );
};

const FORM_DATA_LEFT_COL = [
  {
    style: css`
      width: 65%;
      padding-right: 4rem !important;
    `,
    items: [
      {
        label: "Role name",
        type: TYPE.input,
        size: SIZE.xlarge,
        properties: {
          name: "name",
          type: "text",
          maxLength: 100,
          required: true,
          placeholder: "Name the role",
        },
      },
      {
        label: "Matching Archetype",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "archetype",
          placeholder: "Select role archetype",
          required: true,
          minLength: 0,
          options: Object.values(ARCHETYPES),
          labelKey: "name",
          valueKey: "id",
        },
      },
      {
        label: "Role description",
        type: TYPE.textarea,
        size: SIZE.xlarge,
        properties: {
          name: "description",
          maxLength: 3000,
          required: true,
          placeholder: "Describe this role for prospective squad members",
        },
      },
      {
        label: "Required skills",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "primarySkills",
          placeholder: "Type a skill...",
          queryName: GET_SKILLS,
          queryKeyName: "skills",
          required: true,
          minLength: 1,
        },
      },
      {
        label: "Nice to have skills",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "secondarySkills",
          placeholder: "Type a skill...",
          queryName: GET_SKILLS,
          queryKeyName: "skills",
          required: false,
          minLength: 1,
        },
      },
      {
        label: "Required disciplines",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "disciplines",
          placeholder: "Type a discipline...",
          queryName: GET_DISCIPLINES,
          queryKeyName: "disciplines",
          minLength: 0,
          required: true,
        },
      },
      {
        label: "Location Restrictions",
        type: TYPE.checkbox,
        size: SIZE.xlarge,
        properties: {
          type: "checkbox",
          name: "remoteAnywhere",
          label: "Anywhere",
        },
      },
      {
        type: TYPE.tags,
        size: SIZE.xlarge,
        disabledCondition: {
          field: "remoteAnywhere",
          value: true,
        },
        properties: {
          name: "locations",
          placeholder: "Type a location...",
          queryName: GET_LOCATIONS,
          queryKeyName: "locations",
          searchQuery: (search) => ({ filters: { search } }),
          minLength: 0,
          autoFocus: false,
          defaultOpen: false,
        },
      },
      {
        label: "Code Challenges",
        type: TYPE.tags,
        size: SIZE.xlarge,
        properties: {
          name: "challenges",
          placeholder: "Type a code challenge name...",
          queryName: GET_CHALLENGES,
          queryKeyName: "challenges",
          searchQuery: (search) => ({ filters: { name: search, published: true } }),
          minLength: 1,
          autoFocus: false,
          defaultOpen: false,
        },
      },
    ],
  },
];

const FORM_DATA_RIGHT_COL = [
  {
    style: css`
      width: 35%;
      padding-left: 4rem !important;
    `,
    items: [
      {
        label: "Start date",
        type: TYPE.datePicker,
        size: SIZE.xlarge,
        properties: {
          name: "startDate",
          placeholder: "Estimated start date",
          required: true,
        },
      },
      {
        label: "Commitment",
        type: TYPE.input,
        size: SIZE.xlarge,
        valueFunc: (val) => parseInt(val),
        properties: {
          name: "engagementTime",
          placeholder: "Expected weekly hours",
          required: true,
          type: "number",
        },
        decorator: {
          type: DECORATORS.icon,
          props: {
            type: ICON_TYPE.hourglass,
            size: ICON_SIZE.medium,
            position: ICON_POSITION.overlay,
          },
        },
      },
      {
        label: "Target rate",
        type: TYPE.input,
        size: SIZE.xlarge,
        valueFunc: (val) => parseFloat(val),
        properties: {
          name: "targetRate",
          required: true,
          type: "number",
        },
        decorator: {
          type: DECORATORS.icon,
          props: {
            type: ICON_TYPE.coin,
            size: ICON_SIZE.medium,
            position: ICON_POSITION.overlay,
          },
        },
      },
      {
        label: "Target rate currency",
        type: TYPE.select,
        size: SIZE.xlarge,
        properties: {
          name: "targetRateCurrency",
          required: true,
          options: [
            { value: "USD", label: "USD" },
            { value: "CAD", label: "CAD" },
            { value: "EUR", label: "EUR" },
          ],
        },
        decorator: {
          type: DECORATORS.icon,
          props: {
            type: ICON_TYPE.coin,
            size: ICON_SIZE.medium,
            position: ICON_POSITION.overlay,
          },
        },
      },
      {
        label: "Preferred time zone",
        type: TYPE.select,
        size: SIZE.xlarge,
        properties: {
          name: "timezone",
          queryName: GET_TIME_ZONES,
          queryKeyName: "timezones",
          labelKey: "name",
          required: true,
        },
        decorator: {
          type: DECORATORS.icon,
          props: {
            type: ICON_TYPE.pin,
            size: ICON_SIZE.medium,
            position: ICON_POSITION.overlay,
          },
        },
      },
      {
        label: "Owner",
        type: TYPE.select,
        size: SIZE.xlarge,
        properties: {
          name: "assignedToId",
          queryName: GET_ADMINS,
          queryKeyName: "admins",
          labelKey: "name",
          valueKey: "id",
          isSearchable: true,
          querySearchFieldName: "search",
          required: true,
          noOptionsMessage: "No admins found",
        },
        decorator: {
          type: DECORATORS.icon,
          props: {
            type: ICON_TYPE.user,
            size: ICON_SIZE.medium,
            position: ICON_POSITION.overlay,
          },
        },
      },
      {
        label: "Status",
        type: TYPE.checkbox,
        size: SIZE.xlarge,
        properties: {
          name: "isVisible",
          label: "This role is open for applications",
        },
        relatedValueFunc: [
          {
            name: "shouldNotifySubscribers",
            valueFunc: () => false,
            relatedState: "isVisible",
            relatedStateReason: "valueChange",
          },
        ],
      },
      {
        type: TYPE.checkbox,
        size: SIZE.xlarge,
        disabledCondition: {
          field: "isVisible",
          value: false,
        },
        properties: {
          type: "checkbox",
          name: "shouldNotifySubscribers",
          label: "Send publish notification",
          description: "Notifications will be sent to all members that subscribed to notifications.",
        },
      },
    ],
  },
];

RoleAddButton.defaultProps = {
  show: false,
  isEditing: false,
};

RoleAddButton.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object,
  missionId: PropTypes.string,
  loading: PropTypes.bool,
  isEditing: PropTypes.bool,
};

export default RoleAddButton;
