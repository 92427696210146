import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { TextBlock } from "components/Layouts";

/**
 * MissionAbout
 *
 * @param {Object}   mission
 * @param {Boolean}  isLoading
 */
const MissionAbout = ({ mission, isLoading }) => (
  <TextBlock
    title={<FormattedMessage id="Missions.AboutMission" />}
    body={mission?.description}
    isLoading={isLoading}
  />
);

MissionAbout.propTypes = {
  mission: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default MissionAbout;
