import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { ARCHETYPES, ERROR_CODES, MATCHING_EMAIL_ADDRESS } from "constants/index";
import { MessagesWarning } from "components/Messages";
import { PrimaryLink } from "components/Links";

/**
 * RoleApplyError - shows a message above the form
 *
 * @param {String}  reason
 * @param {Object}  role
 */
const RoleApplyError = ({ reason, role }) => {
  const allowedArchetypesPlural = role?.archetype?.map((item) => {
    const archArray = Object.values(ARCHETYPES);
    const match = archArray?.find((arch) => item === arch.id);
    return `${match?.name}'s`;
  });

  return (
    <MessagesWarning>
      <b>
        <FormattedMessage
          id="Missions.MissionRole.ApplyForm.ErrorHeader"
          values={{
            count: reason.length,
          }}
        />
      </b>
      {reason.map((error) => {
        switch (error) {
          case ERROR_CODES.archetypeMismatch:
            return (
              <FormattedMessage
                id="Missions.MissionRole.ApplyForm.ErrorArchetypeMismatch"
                values={{
                  archetypes: allowedArchetypesPlural.join(" and "),
                }}
              />
            );
          case ERROR_CODES.locationMismatch:
            return <FormattedMessage id="Missions.MissionRole.ApplyForm.ErrorLocationMismatch" />;
          default:
            return <FormattedMessage id="Missions.MissionRole.ApplyForm.ErrorGeneral" />;
        }
      })}
      <FormattedMessage
        id="Missions.MissionRole.ApplyForm.ErrorFooter"
        values={{
          email: <PrimaryLink href={`mailto: ${MATCHING_EMAIL_ADDRESS}`}>{MATCHING_EMAIL_ADDRESS}</PrimaryLink>,
        }}
      />
    </MessagesWarning>
  );
};

RoleApplyError.propTypes = {
  reason: PropTypes.array,
  role: PropTypes.object,
};

export default RoleApplyError;
