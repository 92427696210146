import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { Row } from "components/Containers";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { colors, fontSize } from "style";
import { DEEL_TASK_STATUS } from "constants/index";

/**
 * DeelTaskStatus
 *
 * @param {String} status
 */
const DeelTaskStatus = ({ status }) => {
  const [isSuccess, setIsSuccess] = useState(status === DEEL_TASK_STATUS.SUCCESS);

  useEffect(() => {
    if (status) {
      setIsSuccess(status === DEEL_TASK_STATUS.SUCCESS);
    }
  }, [status]);

  return (
    <Row css={styles.container}>
      {status && (
        <Row css={styles.item}>
          <Row css={styles.icon_container(isSuccess ? colors.green : colors.red)}>
            <Icon type={isSuccess ? ICON_TYPE.checkmark : ICON_TYPE.close} size={ICON_SIZE.small} color="#fff" />
          </Row>
        </Row>
      )}
    </Row>
  );
};

const styles = {
  container: css`
    font-size: ${fontSize.xsmall};
    font-weight: 500;
  `,
  icon_container: (color) => css`
    width: 1.5rem;
    height: 1.5rem;
    align-items: center;
    justify-content: center;
    background: ${color};
    border-radius: 100%;
  `,
  item: css`
    gap: 0.5rem;
  `,
};

DeelTaskStatus.propTypes = {
  status: PropTypes.string,
};

export default DeelTaskStatus;
