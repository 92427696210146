import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Row } from "components/Containers";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { colors, fontSize } from "style";
import { nonUtcFormatDate } from "utils";

/**
 * TimeSheetStatus
 *
 * @param {Object} selectedMonth
 * @param {Object} allSelected
 */
const TimeSheetStatus = ({ selectedMonth, allSelected }) => {
  const isSubmitted = selectedMonth?.submitted === true;

  if (!allSelected && !isSubmitted) {
    return (
      <Row css={styles.container} gap="0.5rem">
        <FormattedMessage id="TimeSheet.TimeSheetStatus.Disabled" />
        <Icon size={ICON_SIZE.xlarge} type={ICON_TYPE.info} color={colors.yellow} />
      </Row>
    );
  } else if (!isSubmitted) {
    return null;
  }

  return (
    <Row css={styles.container}>
      <span>
        <FormattedMessage
          id="TimeSheet.TimeSheetStatus.Submitted"
          values={{
            date: <span css={styles.date}>{nonUtcFormatDate(selectedMonth?.submittedAt, "MMMM Do, h:mm a")}</span>,
          }}
        />
      </span>
      <Icon type={ICON_TYPE.checkDotted} size={ICON_SIZE.xlarge} color={colors.green} />
    </Row>
  );
};

const styles = {
  container: css`
    justify-content: flex-end;
    font-size: ${fontSize.small};
    font-weight: 400;
  `,
  date: css`
    font-weight: 600;
  `,
};

TimeSheetStatus.propTypes = {
  selectedMonth: PropTypes.object,
  allSelected: PropTypes.bool,
};

export default TimeSheetStatus;
