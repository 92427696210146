import { gql } from "@apollo/client";

const GET_CHALLENGES = gql`
  query challenges(
    $filters: ChallengeFilter
    $profileId: ID
    $first: Int
    $last: Int
    $after: String
    $before: String
    $paginationInfo: PaginationInfo
  ) {
    challenges(
      filters: $filters
      profileId: $profileId
      first: $first
      last: $last
      after: $after
      before: $before
      paginationInfo: $paginationInfo
    ) {
      totalCount
      currentPage
      perPage
      nodes {
        id
        uuid
        serviceId
        icon
        name
        privateName
        description
        maxScore
        timeLimit
        published
        testUrl
        inviteUrl
        tasks
        type
        tasksCount
        responsesCount
        publishedAt
        archivedAt
        challengeResponse {
          completedAt
          feedbackUrl
          id
          maxScore
          profileId
          reportUrl
          score
          serviceId
          sessionUrl
          startedAt
          status
          tasks
          testUrl
          type
          uuid
        }
        skills {
          id
          name
        }
        disciplines {
          id
          name
        }
        types {
          id
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export { GET_CHALLENGES };
