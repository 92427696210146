/** @jsx jsx */
import styled from "@emotion/styled";
import { colors, fontSize } from "style";

/**
 * Secondary
 */
const ActionButton = styled.button`
  height: 4rem;
  min-height: 4rem;
  min-width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${fontSize.xsmall};
  font-weight: 600;
  padding: 0.9rem 0.8rem 0.9rem 0.9rem !important;
  color: ${colors.purpleRainBase};
  background: ${colors.white};
  border-radius: 0.6rem;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  text-decoration: none;

  &:hover:not(:disabled) {
    box-shadow: 0 1px 6px 0 rgba(85, 76, 197, 0.16);
  }

  &:focus {
    box-shadow: 0 1px 6px 0 rgba(85, 76, 197, 0.16);
  }

  &:disabled {
    color: ${colors.grayAnatomyLight2};
    box-shadow: none;
  }

  &:after {
    display: none;
  }
`;

export default ActionButton;
