import React from "react";
import PropTypes from "prop-types";
import Image from "components/Image";
import { TextLoader } from "components/Loader";
import { css } from "@emotion/react";
import { margins } from "style";
import { EditIcon } from "components/Icon";
import { withModalForm, MODAL_SIZE } from "components/Modal";
import { FormattedMessage } from "react-intl";
import { UPLOAD_LOGO } from "graphql/mutations";
import { SIZE, TYPE } from "components/Form";
import OrganizationLogoBackground from "assets/images/organizations.png";

/**
 * OrganizationPhoto
 *
 * @param {String}    url
 * @param {String}    width
 * @param {String}    height
 * @param {String}    loading
 */
const OrganizationPhoto = ({ id, url, width, isEditable, height, loading, onEdit, ...props }) => {
  const handleOnEdit = () => onEdit({ logoUrl: url }, id);
  const Edit = () =>
    isEditable ? <EditIcon data-header-edit-icon css={styles.header_edit_icon} onClick={handleOnEdit} /> : null;

  if ((loading || !url) && !isEditable) {
    return <PlaceHolder width={width} height={height} isEditable={isEditable} />;
  }

  return (
    <div css={styles.container}>
      {isEditable && <Edit />}
      <div css={styles.image_container(width, height, isEditable)} {...props}>
        {url ? (
          <Image
            url={url}
            css={isEditable ? styles.editable_image(width, height) : styles.image}
            width="100%"
            height="100%"
          />
        ) : (
          <div className="initials" css={styles.initials}></div>
        )}
      </div>
    </div>
  );
};

/**
 * Placeholder
 *
 * @param {String}   width
 * @param {String}   height
 */
const PlaceHolder = ({ width, height, isEditable }) => (
  <TextLoader css={styles.image_container(width, height, isEditable)} />
);

const styles = {
  container: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  image_container: (width, height, isEditable) => css`
    width: ${width};
    height: ${height};
    position: relative;
    border-radius: 100%;
    overflow: ${!isEditable && `hidden`};
  `,
  image: css`
    object-fit: cover;
    width: 100%;
    height: 100%;
  `,
  initials: css`
    background-image: url(${OrganizationLogoBackground});
    background-size: cover;
    height: 100%;
    width: 100%;
    border-radius: 0.6rem;
  `,
  header_edit_icon: css`
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: -5rem;
  `,
  editable_image: (width, height) => css`
    width: ${width};
    height: ${height};
    border-radius: 0.6rem;
  `,
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        type: TYPE.photo,
        size: SIZE.xlarge,
        properties: {
          name: "logoUrl",
        },
      },
    ],
  },
];

const FORM_SETTINGS = {
  size: MODAL_SIZE.medium,
  data: FORM_DATA,
  title: <FormattedMessage id="Organization.OrganizationPhoto.FormTitle" />,
  description: <FormattedMessage id="Organization.OrganizationPhoto.FormDescription" />,
  mutationData: {
    mutationName: {
      add: UPLOAD_LOGO,
      update: UPLOAD_LOGO,
    },
  },
};

OrganizationPhoto.defaultProps = {
  width: `18rem`,
  height: `18rem`,
  marginSize: margins.normal,
};

OrganizationPhoto.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  loading: PropTypes.bool,
  isEditable: PropTypes.bool,
  onEdit: PropTypes.func,
  marginSize: PropTypes.string,
};

PlaceHolder.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  isEditable: PropTypes.bool,
};

export default withModalForm(OrganizationPhoto, FORM_SETTINGS);
