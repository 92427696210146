import React from "react";
import { forwardRef } from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import { css } from "@emotion/react";

/**
 * DropDownMenu
 *
 * @params {Element}  children
 */
const DropDownMenu = ({ children, ...props }) => (
  <Dropdown.Menu as={CustomMenu} {...props}>
    {children}
  </Dropdown.Menu>
);

const CustomMenu = forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => (
  <div ref={ref} style={style} className={className} aria-labelledby={labeledBy} css={styles.menu}>
    <ul className="list-unstyled">{children}</ul>
  </div>
));

const styles = {
  menu: css`
    background: #fff;
    box-shadow: 0 1px 6px 0 rgba(85, 76, 197, 0.16);
    border-radius: 0.6rem;
    margin-top: 1rem;
    border: 0;
  `,
};

CustomMenu.displayName = "CustomMenu";

CustomMenu.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  "aria-labelledby": PropTypes.string,
};

DropDownMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DropDownMenu;
