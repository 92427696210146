import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Modal, { MODAL_SIZE } from "components/Modal";
import { ProfileBox, ProfileVideoInterviewsList } from "components/Profile";
import Tabs from "components/Tabs";
import { Grid } from "components/Containers";
import { margins } from "style";

/**
 * ProfileModal
 *
 * @param {String}    id
 * @param {Boolean}   show
 * @param {Function}  handleClose
 * @param {Function}  handlePrimary
 */
const ProfileModal = ({ id, show, handleClose, handlePrimary, initialTab, ...props }) => {
  const [selectedTab, setSelectedTab] = useState(initialTab);

  // Go back to default tab when modal opens
  useEffect(() => {
    if (show) {
      setSelectedTab(initialTab);
    }
  }, [show, initialTab]);

  return (
    <Modal
      title={<FormattedMessage id="ClientSearch.ClientSearchProfilePreview.Title" />}
      size={MODAL_SIZE.large}
      show={show}
      showSave={true}
      showCancel={true}
      secondaryButtonMessageId="Global.Close"
      primaryButtonMessageId="Profile.ProfileModal.ButtonViewProfile"
      canSubmit={true}
      onSubmit={handlePrimary}
      onClose={handleClose}
      clickOutsideToClose={true}
      centered={false}
      {...props}
    >
      <Grid>
        <Grid.col start={1} end={13}>
          <Tabs
            data={["Profile", "Interviews"]?.map((name) => name)}
            selected={selectedTab}
            onSelect={setSelectedTab}
            marginSize={margins.none}
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          {selectedTab === 0 && <ProfileBox id={id} isEditable={false} marginSize={margins.normal} />}
          {selectedTab === 1 && <ProfileVideoInterviewsList profileId={id} />}
        </Grid.col>
      </Grid>
    </Modal>
  );
};

ProfileModal.propTypes = {
  id: PropTypes.string.isRequired,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  handlePrimary: PropTypes.func,
  initialTab: PropTypes.number,
};

ProfileModal.defaultProps = {
  initialTab: 0,
};

export default ProfileModal;
