import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useAuth } from "hooks";
import PageTitle from "components/PageTitle";
import { NavList } from "components/Nav";
import { LINKS } from "constants/index";
import { PrimaryLink } from "components/Links";

/**
 * ProfileNav
 *
 * @param {String}   id
 */
const ProfileNav = ({ id }) => {
  const { isAdmin, isPartner, isRecruiter } = useAuth();

  if (!isAdmin && !isRecruiter && !isPartner) return null;

  const adminNavItems = [
    {
      label: "Profile",
      to: LINKS.private_profile(id),
      isActive: true,
    },
    {
      label: "Interviews",
      to: LINKS.profile_video_interviews(id),
      isActive: true,
    },
    {
      label: "Job Preferences",
      to: LINKS.rate(id),
      isActive: true,
    },
    {
      label: "Notes",
      to: LINKS.notes(id),
      isActive: true,
      isAdmin,
    },
    {
      label: "Timeline",
      to: LINKS.timeline(id),
      isActive: true,
      isAdmin,
    },
    {
      label: "Settings",
      to: LINKS.settings(id),
      isActive: true,
    },
  ];

  const partnerNavItems = [
    {
      label: "Profile",
      to: LINKS.private_profile(id),
      isActive: true,
    },
    {
      label: "Interviews",
      to: LINKS.profile_video_interviews(id),
      isActive: true,
    },
    {
      label: "Job Preferences",
      to: LINKS.rate(id),
      isActive: true,
    },
    {
      label: "Settings",
      to: LINKS.settings(id),
      isActive: true,
    },
  ];

  return (
    <PageTitle
      title={<FormattedMessage id="Profile.Title" />}
      backLink={
        <PrimaryLink to={LINKS.network} routerEnabled>
          <FormattedMessage id="Nav.SideNav.LabelNetwork" />
        </PrimaryLink>
      }
      actionBar={<NavList pages={isAdmin || isRecruiter ? adminNavItems : partnerNavItems} routerEnabled end />}
      asHeader
    />
  );
};

ProfileNav.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ProfileNav;
