import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import DisplayBox, { RenderMemberList } from "components/DisplayBox";
import { ShowMore } from "components/Pagination";
import { margins } from "style";
import { MessagesNoResults } from "components/Messages";

/**
 * UserList
 *
 * @param {Array}     data
 * @param {Object}    team
 * @param {Boolean}   loading
 * @param {String}    title
 * @param {Boolean}   hideRate
 * @param {Boolean}   hidePerformance
 * @param {Boolean}   hasNextPage
 * @param {Function}  handleNextPage
 * @param {Function}  onClick
 */
const UserList = ({
  data,
  team,
  loading,
  title,
  hideRate,
  hidePerformance,
  hasNextPage,
  handleNextPage,
  onClick,
  ...props
}) => (
  <>
    <DisplayBox
      id="events"
      title={title}
      emptyMsg={<MessagesNoResults title={"User.UserList.EmptyTitle"} description={"User.UserList.EmptyDescription"} />}
      loading={loading}
      isEmpty={!loading && (!data || data?.length === 0)}
      numColumns={1}
      marginSize={margins.normal}
      data={data}
      itemRenderer={(eventData) => (
        <RenderMemberList
          key={eventData.id}
          team={team}
          data={loading ? [] : eventData}
          loading={loading}
          hideRate={hideRate}
          hidePerformance={hidePerformance}
          onClick={onClick}
        />
      )}
      {...props}
    />
    {hasNextPage && handleNextPage && (
      <ShowMore
        loading={loading}
        hasNextPage={!loading && hasNextPage}
        onNextPage={handleNextPage}
        text={<FormattedMessage id="Mission.Contacts.ShowMore" />}
      />
    )}
  </>
);

UserList.defaultProps = {
  hideRate: true,
};

UserList.propTypes = {
  data: PropTypes.array,
  team: PropTypes.object,
  loading: PropTypes.bool,
  title: PropTypes.any,
  hideRate: PropTypes.bool,
  hidePerformance: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  handleNextPage: PropTypes.func,
  onClick: PropTypes.func,
};

export default UserList;
