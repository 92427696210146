import React, { useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { colors, gradients, fontSize } from "style";
import PasswordRules from "./PasswordRules";
import { breakpoints } from "style";
import { PrimaryButton } from "components/Buttons";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";

const e = React.createElement;

/**
 * TextInput
 * Destructuring props to prevent unknown prop on DOM element error
 * Reference: https://github.com/emotion-js/emotion/issues/183
 */
// eslint-disable-next-line no-unused-vars
const TextInput = styled(({ hasIcon, hasText, isInvisible, hasError, formData, children, ...props }) =>
  e("input", props, children)
)`
  display: block;
  font-size: ${fontSize.xsmall};
  background: #fff;
  font-weight: normal;
  height: 4rem;
  padding-left: ${(props) => (props.hasIcon ? `3.4rem` : `1rem`)};
  padding-right: 1.4rem;
  line-height: 2.4rem;
  border-radius: ${(props) => (props.hasText ? `0 0.6rem 0.6rem 0` : `0.6rem`)};
  border: 1px solid ${colors.grayAnatomyLight3};
  width: 100%;
  color: ${colors.secondary};
  order: 1;
  resize: none;
  appearance: none;
  background-clip: padding-box;
  outline: none;
  transition: all 0.3s ease;

  ${(props) =>
    props.isInvisible &&
    `
    border-color: transparent;
  `}

  ${(props) => props.hasButton && `padding-right:6rem;`}

  ${(props) =>
    props.hasText &&
    `
    border-width:0 !important;
    height:auto;
  `};

  &::placeholder {
    color: ${colors.grayAnatomyLight2};
  }

  &:focus {
    border-color: ${colors.purpleRainBase};
    box-shadow: 0px 0px 0px 3px ${colors.purpleRainLight3};

    & + label {
      color: ${colors.purpleRainBase};
    }
  }

  &:hover:not(:disabled) {
    border-color: ${colors.purpleRainBase};
  }

  &:disabled {
    border: 1px solid ${colors.grayAnatomyLight3};
    color: ${colors.grayAnatomyLight2};
    background-color: ${colors.grayAnatomyLight4};

    &:placeholder {
      color: ${colors.grayAnatomyLight1};
    }
  }

  ${(props) =>
    props.hasError &&
    `
    box-shadow: none;
    border-image-source: ${gradients.errorBorderGradient};
    border-width: 2px;
    border-image-slice: 1;
    background-image: linear-gradient(to right, ${colors.orangeCountyLight3}, #fff), ${gradients.errorBorderGradient};
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-color: transparent !important;

    &::placeholder {
      color: ${colors.orangeCountyLight1};
    }
  `}
`;

/**
 * Regular TextInput
 *
 * @param {Function}   onChange
 * @param {Function}   onSubmit
 * @param {Object}     inputRef
 * @param {String}     name
 * @param {Boolean}    withButton
 * @param {String}     value
 */
const TextInputWithOnChange = ({ onChange, onSubmit, inputRef, name, withButton, value: valueProp, ...props }) => {
  const [value, setValue] = useState(valueProp);
  const [hasChanges, setHasChanges] = useState(false);

  const handleChange = (e) => {
    if (onChange) {
      onChange(name, e.target.value.length === 1 ? e.target.value.trim() : e.target.value);
    }
  };

  const handleStateChange = (e) => {
    if (onChange) {
      setValue(e.target.value);
      setHasChanges(true);
    }
  };

  const handleButton = () => {
    onChange(name, value.length === 1 ? value.trim() : value);
    setHasChanges(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (withButton) {
        onChange(name, value.length === 1 ? value.trim() : value);
        setHasChanges(false);
      } else {
        onSubmit();
      }
    }
  };

  if (withButton) {
    return (
      <div css={styles.input_container}>
        <TextInput
          {...props}
          name={name}
          value={value}
          ref={inputRef}
          onChange={handleStateChange}
          onKeyPress={handleKeyPress}
          hasButton
        />
        <PrimaryButton onClick={handleButton} disabled={!value || !hasChanges}>
          <Icon type={ICON_TYPE.arrowRight} size={ICON_SIZE.large} color="inherit" />
        </PrimaryButton>
      </div>
    );
  } else {
    return (
      <TextInput
        {...props}
        value={valueProp}
        name={name}
        ref={inputRef}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
    );
  }
};

/**
 * Password Input (shows Rules on right-side)
 *
 * @params {String}     value
 * @params {Function}   onChange
 */
const Password = ({ value, onChange, ...props }) => {
  const [password, setPassword] = useState(value);
  const [isFocused, setFocus] = useState(false);

  const handleChange = (e) => {
    if (onChange) {
      setPassword(e.target.value);
      onChange(e.target.name, e.target.value);
    }
  };

  return (
    <div css={styles.password_container}>
      <TextInput
        {...props}
        value={value}
        onChange={handleChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <div css={styles.rules_container(isFocused)}>
        <PasswordRules password={password} />
      </div>
    </div>
  );
};

const styles = {
  input_container: css`
    width: 100%;
    position: relative;
    order: 1;

    > button {
      position: absolute;
      right: 1px;
      top: 1px;
      bottom: 1px;
      min-height: 0;
      padding-left: 0;
      padding-right: 0;
      width: 3.8rem;
      height: calc(4rem - 2px);
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  `,
  input: css`
    padding-right: 5rem;
  `,
  password_container: css`
    position: relative;
    order: 1;
  `,
  rules_container: (isFocused) => css`
    ${!isFocused && `display:none;`}
    position: absolute;
    left: 100%;
    margin-left: 2rem;
    top: -50%;
    padding: 1.5rem;
    border: 1px solid ${colors.grayAnatomyLight3};
    border-radius: 1rem;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    z-index: 10;
    background: ${colors.white};

    @media (max-width: ${breakpoints.portrait}) {
      bottom: 100%;
      top: auto;
      right: 0;
      left: auto;
      margin-bottom: 3rem;
      margin-left: 0;
    }
  `,
};

TextInputWithOnChange.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  inputRef: PropTypes.object,
  name: PropTypes.string,
  withButton: PropTypes.bool,
  value: PropTypes.string,
};

TextInputWithOnChange.defaultProps = {
  onSubmit: () => {},
};

Password.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export { TextInput, Password };

export default TextInputWithOnChange;
