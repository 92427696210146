import { useEffect, useState, useMemo } from "react";
import { GET_PULSE } from "graphql/queries";
import { useQueryData } from "hooks";
import { getMonthsBetween } from "utils";
import { FEEDBACK_OPTIONS } from "constants/index";
import { PULSE_MODE } from "components/Squads/Pulse";

const keyName = "pulse";

export default function usePulse({ teamId, startDate, endDate, mode } = {}) {
  const [membersData, setMembersData] = useState({});
  const [selectedMember, setSelectedMember] = useState(0);

  const {
    loading,
    data: response,
    error,
    refetch,
  } = useQueryData({
    queryName: GET_PULSE,
    keyName,
    skip: !teamId,
    variables: {
      teamId,
      filters: {
        startDate,
        endDate,
      },
      mode,
    },
  });
  const tabOptions = useMemo(() => Object.keys(membersData).sort((a, b) => a.localeCompare(b)), [membersData]);
  const selectedMemberName = useMemo(() => tabOptions[selectedMember], [tabOptions, selectedMember]);
  const teamMembers = useMemo(() => getTeamMembers(selectedMemberName, membersData), [selectedMemberName, membersData]);
  const data = useMemo(
    () => getTableData(teamMembers, membersData, selectedMemberName, startDate, endDate),
    [teamMembers, membersData, selectedMemberName, startDate, endDate]
  );
  const questionText = useMemo(() => response && response[keyName] && response[keyName][0]?.questionText, [response]);

  useEffect(() => {
    if (response && response[keyName]) {
      setMembersData(
        (mode === PULSE_MODE.profile ? groupByAssessorAndMonth : groupByAssesseeAndMonth)(response[keyName])
      );
    }
  }, [response, mode]);

  return {
    loading,
    data,
    error,
    refetch,
    selectedMember,
    setSelectedMember,
    selectedMemberName,
    tabOptions,
    questionText,
  };
}

// Transform the members data in the form of rows and columns
const getTableData = (members, data, selectedMemberName, startDate, endDate) =>
  members.map((profile) => {
    const row = { profile };

    getMonthsBetween(startDate, endDate).forEach((month) => {
      const { feedback, openFeedback } = data[selectedMemberName]?.[month]?.[profile?.name] || {};
      const feedbackOption = FEEDBACK_OPTIONS[feedback] || "-";

      row[month] = { value: feedbackOption, label: feedback, description: openFeedback };
    });

    return row;
  });

// Group the plain data to the assessor and month wise
const groupByAssessorAndMonth = (data) =>
  data.reduce((result, { feedback, openFeedback, assessee, assessor, month, year }) => {
    const assesseeName = assessee.name;
    const assessorName = assessor.name;
    const key = `${month} ${year}`;

    if (!result[assessorName]) {
      result[assessorName] = {};
    }

    if (!result[assessorName][key]) {
      result[assessorName][key] = {};
    }

    result[assessorName][key][assesseeName] = { feedback, openFeedback, profile: assessee };

    return result;
  }, {});

// Group the plain data to the assessor and month wise
const groupByAssesseeAndMonth = (data) =>
  data.reduce((result, { feedback, openFeedback, assessee, assessor, month, year }) => {
    const assesseeName = assessee.name;
    const assessorName = assessor.name;
    const key = `${month} ${year}`;

    if (!result[assesseeName]) {
      result[assesseeName] = {};
    }

    if (!result[assesseeName][key]) {
      result[assesseeName][key] = {};
    }

    result[assesseeName][key][assessorName] = { feedback, openFeedback, profile: assessor };

    return result;
  }, {});

// Find all the team members the selected person has worked with from the given data
const getTeamMembers = (name, data) =>
  name && data && Object.keys(data[name] || {}).length
    ? Object.values(
        Object.values(data[name])
          .flatMap(Object.values)
          .flatMap((data) => data.profile)
          .reduce((res, p) => {
            res[p.name] = p;
            return res;
          }, {})
      ).sort((a, b) => a.name.localeCompare(b.name))
    : [];
