import ProfileRateAndAvailability, {
  ProfileAvailability,
  ProfileAvailabilityAndEmployment,
  ProfileRateAvailabilityAndEmployment,
} from "./ProfileRateAndAvailability";
import { ExperienceTag } from "./ProfileWorkExperience";
import { useRender as useRenderProfileAdmin } from "./ProfileAdmin";

const AVAILABILITY_COMPONENTS = {
  Availability: ProfileAvailability,
  AvailabilityRate: ProfileRateAndAvailability,
  AvailabilityEmployment: ProfileAvailabilityAndEmployment,
  AvailabilityRateEmployment: ProfileRateAvailabilityAndEmployment,
};

export { default as ProfileBox } from "./ProfileBox";
export { default as ProfileContact } from "./ProfileContact";
export { default as ProfileEducation } from "./ProfileEducation";
export { default as ProfileBio } from "./ProfileBio";
export { default as ProfilePic } from "./ProfilePic";
export { default as ProfileHeader } from "./ProfileHeader";
export { default as ProfileDescription } from "./ProfileDescription";
export { default as ProfileSocialIcons } from "./ProfileSocialIcons";
export { default as ProfileMemberSince } from "./ProfileMemberSince";
export { default as ProfileSkills } from "./ProfileSkills";
export { default as ProfileDisciplines } from "./ProfileDisciplines";
export { default as ProfileWorkExperience } from "./ProfileWorkExperience";
export { default as ProfileProgress } from "./ProfileProgress";
export { default as ProfileRate } from "./ProfileRate";
export { default as ProfileRateAndAvailability } from "./ProfileRateAndAvailability";
export {
  ProfileAvailability,
  ProfileAvailabilityAndEmployment,
  ProfileRateAvailabilityAndEmployment,
} from "./ProfileRateAndAvailability";
export { default as ProfileWorkingSchedule } from "./ProfileWorkingSchedule";
export { default as ProfileDaysOff } from "./ProfileDaysOff";
export { default as ProfileAvailabilityToggle } from "./ProfileAvailabilityToggle";
export { default as ProfileAdmin } from "./ProfileAdmin";
export { default as ProfileSummary } from "./ProfileSummary";
export { default as ProfileArchiveForm } from "./ProfileArchiveForm";
export { default as ProfileAdminBar } from "./ProfileAdminBar";
export { default as ProfileInterests } from "./ProfileInterests";
export { default as ProfileCodeChallenge } from "./ProfileCodeChallenge";
export { default as ProfileAssignedRateAndCapacity } from "./ProfileAssignedRateAndCapacity";
export { default as ProfileCard } from "./ProfileCard";
export { default as ProfileFlag } from "./ProfileFlag";
export { default as ProfileBadges } from "./ProfileBadges";
export { default as ProfileAddButton } from "./ProfileAddButton";
export { default as ProfileForm } from "./ProfileForm";
export { default as ProfileModal } from "./ProfileModal";
export { default as ProfilePreviewButton } from "./ProfilePreviewButton";
export { default as ProfileViewButton } from "./ProfileViewButton";
export { default as ProfileSettingForm } from "./ProfileSettingForm";
export { default as ProfileBadgesForm } from "./ProfileBadgesForm";
export { default as HireButton } from "./HireButton";
export { default as BookMeetingButton } from "./BookMeetingButton";
export { default as ProfileAdminVideoInterview } from "./ProfileAdminVideoInterview";
export { default as ProfileVideoInterviewsList } from "./ProfileVideoInterviewsList";
export { default as VideoPlayIcon } from "./VideoPlayIcon";
export { default as ProfileEngineeringLevelForm } from "./ProfileEngineeringLevelForm";
export { default as ProfileNav } from "./ProfileNav";
export { default as ProfilePreferences } from "./ProfilePreferences";
export { default as RateTag } from "./RateTag";
export { default as PreferredRateTag } from "./PreferredRateTag";
export { default as ProfileRateRange } from "./ProfileRateRange";
export { default as ProfileStatusUpdateButton } from "./ProfileStatusUpdateButton";
export { default as ProfileLink } from "./ProfileLink";
export { AVAILABILITY_COMPONENTS, ExperienceTag, useRenderProfileAdmin };
export { default as ProfileInterviewsDeleteButton } from "./ProfileInterviewsDeleteButton";
