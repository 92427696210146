import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useAuth, useTeams } from "hooks";
import DisplayBox from "components/DisplayBox";
import RenderMemberGroupList from "components/DisplayBox/Renderers/RenderMemberGroupList";
import { LINKS } from "constants/index";
import { margins } from "style";

/**
 * SquadsHighlight
 *
 * @param {String}    id
 * @param {String}    organizationId
 * @param {String}    emptyMsgId
 */
const SquadsHighlight = ({ organizationId, profileId, emptyMsgId, ...props }) => {
  const { isPartner } = useAuth();
  const { loading, data } = useTeams({ profileId, organizationId });
  const isEmpty = !loading && data?.length === 0;

  return (
    <DisplayBox
      title={<FormattedMessage id="Squads.SquadsHighlight.Title" />}
      marginSize={margins.normal}
      loading={loading}
      numColumns={1}
      data={data}
      isEmpty={isEmpty}
      emptyMsg={<FormattedMessage id={`${emptyMsgId ? emptyMsgId : "Squads.SquadsHighlight.EmptyMsg"}`} />}
      itemRenderer={(item, index, loading) => (
        <RenderMemberGroupList
          key={item.id}
          title={item.name}
          subtitle={item.organization?.name}
          linkTo={!isPartner && LINKS.squad_profile(item?.slug)}
          users={item.assignments}
          thumbCount={item.size}
          loading={loading}
          index={index}
        />
      )}
      {...props}
    />
  );
};

SquadsHighlight.propTypes = {
  profileId: PropTypes.string,
  organizationId: PropTypes.string,
  emptyMsgId: PropTypes.string,
};

export default SquadsHighlight;
