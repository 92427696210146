import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { SENTIMENTS } from "constants";
import { toUcFirst } from "utils";
import { getEntityName } from "components/Squads/Insights";

/**
 * LabelForOutlierInverseMetric
 *
 * @param {Object}  insight
 * @param {String}  word
 */
const LabelForOutlierInverseMetric = ({ insight, word }) => {
  const {
    sentiment,
    insightType,
    insightMetric,
    observationPeriod,
    observationPeriodComparative,
    valueDeltaPercent,
    valueDelta,
  } = insight;
  const value = insight.value.toFixed();
  const isSentimentPositive = sentiment === SENTIMENTS.positive;

  return (
    <FormattedMessage
      id={`Squads.SquadInsights.Metric.${sentiment === SENTIMENTS.neutral ? `Steady` : `Directional`}`}
      values={{
        entityName: getEntityName(insight),
        insightType: toUcFirst(insightType),
        direction: isSentimentPositive ? "DOWN" : "UP",
        valueDelta: isSentimentPositive ? valueDelta : Math.abs(valueDelta),
        valueDeltaPercent: isSentimentPositive ? Math.abs(valueDeltaPercent) : valueDeltaPercent,
        word,
        value,
        insightMetric,
        observationPeriod,
        observationPeriodComparative,
      }}
    />
  );
};

LabelForOutlierInverseMetric.propTypes = {
  insight: PropTypes.object,
  word: PropTypes.string,
};

export default LabelForOutlierInverseMetric;
