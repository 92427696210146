import React, { useEffect, useState, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { GET_EXISTING_TIME_SLIPS, GET_PROFILE_TIME_SHEETS } from "graphql/queries";
import { UPDATE_TIME_SLIP } from "graphql/mutations";
import { useMutation } from "@apollo/client";
import { useQueryData } from "hooks";
import { useToast } from "hooks";
import debounce from "lodash.debounce";
import { convertDateToUTC } from "utils";

export default function useTimeSlip({ profileId, selectedMonth, teamSlug = "0" }) {
  const { toast } = useToast();
  const [data, setData] = useState([]);
  const [teams, setTeams] = useState([]);
  const [updateTimeSlip] = useMutation(UPDATE_TIME_SLIP);
  const date = convertDateToUTC(selectedMonth);
  const selectedYear = date?.getFullYear();
  const selectedMonthNumber = date?.getMonth();
  const firstDayCurrentMonth = new Date(selectedYear, selectedMonthNumber, 1);
  const lastDayCurrentMonth = new Date(selectedYear, selectedMonthNumber + 1, 0);
  const invalidDate = typeof selectedMonth == "undefined";
  const getTotalHours = (arr, key) => arr.reduce((previous, current) => previous + (current[key]?.timeLogged || 0), 0);

  const showSuccess = useMemo(
    () => debounce(() => toast.success(<FormattedMessage id={"useTimeSlip.Success"} />), 1200),
    []
  );

  const { loading: loadingProfileTimeSheets, data: profileTimeSheets } = useQueryData({
    queryName: GET_PROFILE_TIME_SHEETS,
    keyName: "profileTimeSheets",
  });

  const {
    loading,
    refetch,
    refetching,
    data: queryData,
    error,
    client,
  } = useQueryData({
    queryName: GET_EXISTING_TIME_SLIPS,
    keyName: "timeSlipsFetch",
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      startDate: firstDayCurrentMonth.toDateString(),
      endDate: lastDayCurrentMonth.toDateString(),
      profileId,
      teamSlug,
    },
    skip: invalidDate,
  });

  const updateTime = (params) => {
    updateTimeSlip({
      variables: {
        input: {
          id: profileId,
          params,
        },
      },
      ignoreResults: true, // Prevents unnecessary re-renders
      onQueryUpdated() {
        showSuccess();
        return true;
      },
      onError() {
        toast.error(<FormattedMessage id={"useTimeSlip.Error"} />);
      },
    });
  };

  /**
   * Because we don't update our local cache when modifying a timesheet, we need to refetch
   * on unmount so visitor sees latest data when they return
   */
  useEffect(() => {
    return () => refetch();
  }, []);

  /**
   * Format time slip data
   */
  useEffect(() => {
    const flattenedData = [];

    queryData?.timeSlipsFetch?.nodes.forEach((parentItem) => {
      parentItem?.assignments?.forEach((item) => {
        flattenedData.push({
          ...item,
          ...{ assignmentDate: parentItem?.assignmentDate },
        });
      });
    });

    setData(flattenedData);

    return () => {
      setData([]);
    };
  }, [queryData, client]);

  /**
   * Format team data
   */
  useEffect(() => {
    // extract unique teams on the basis of team id
    let uniqueTeams = Array.from(new Set(data?.map(({ teamId }) => teamId)))
      .map((id) => data?.find(({ teamId }) => teamId == id))
      .filter(Boolean); // to remove any undefined values

    setTeams(uniqueTeams.map(({ teamId, teamName }) => ({ id: teamId, name: teamName })));
  }, [data]);

  return {
    loading: loading || refetching,
    updateTime,
    data,
    teams,
    error,
    loadingProfileTimeSheets,
    profileTimeSheets,
    getTotalHours,
  };
}
