import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { SocialIcon, SOCIAL_ICON_TYPE, EditIcon } from "components/Icon";
import { PrimaryLink } from "components/Links";
import { withModalForm, MODAL_SIZE } from "components/Modal";
import { SIZE, TYPE } from "components/Form";
import { UPDATE_PROFILE } from "graphql/mutations";
import { GET_PROFILE } from "graphql/queries";
import { EditButton } from "components/Buttons";
import { PAGES } from "constants/index";
import { useAuth } from "hooks";
import { margins } from "style";

/**
 * ProfileSocialIcons
 *
 * @params {Object}   data
 * @params {Boolean}  loading
 * @params {Boolean}  isEditable
 * @params {Function} onEdit
 * @params {String}   marginSize
 */
const ProfileSocialIcons = ({ data, loading, isEditable, onEdit, marginSize }) => {
  const { isClient, isShowcase } = useAuth();

  if (loading || isClient || isShowcase) {
    return null;
  }

  const handleOnEdit = () =>
    onEdit(
      {
        externalLinks: data?.externalLinks.map((i) => ({
          name: i.name,
          type: i.type,
          url: i.url,
          uuid: i.uuid,
        })),
      },
      data?.id
    );

  if (!data?.externalLinks?.length && isEditable) {
    return (
      <div css={styles.edit_container}>
        <EditButton onClick={handleOnEdit}>
          <FormattedMessage id="Profile.ProfileSocialIcons.AddLinks" />
        </EditButton>
      </div>
    );
  }

  return (
    <div css={styles.social_links_container}>
      {data?.externalLinks?.map((link) => (
        <PrimaryLink key={link.uuid} href={link.url} target="_blank">
          <SocialIcon type={SOCIAL_ICON_TYPE[link.type.toLocaleLowerCase()]} />
        </PrimaryLink>
      ))}
      {isEditable && <EditIcon css={styles.edit_icon(marginSize)} onClick={handleOnEdit} />}
    </div>
  );
};

const styles = {
  edit_container: css`
    margin-top: 1.6rem;
    width: 100%;
  `,
  social_links_container: css`
    align-items: center;
    width: 13.6rem;
    margin-left: auto;
    margin-right: auto;
    gap: 0.8rem;
    margin-top: 1.6rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
  `,
  edit_icon: (marginSize) => css`
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: inherit;
    right: calc(-${marginSize} - 4rem);
  `,
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        type: TYPE.links,
        size: SIZE.xlarge,
        properties: {
          name: "externalLinks",
        },
      },
    ],
  },
];

const FORM_SETTINGS = {
  size: MODAL_SIZE.large,
  data: FORM_DATA,
  title: <FormattedMessage id="Profile.ProfileSocialIcons.FormTitle" />,
  description: <FormattedMessage id="Profile.ProfileSocialIcons.FormDescription" />,
  mutationData: {
    mutationName: {
      add: UPDATE_PROFILE,
      update: UPDATE_PROFILE,
    },
    refetchQueries: [GET_PROFILE],
    refetchAfterMutate: false,
  },
  page: PAGES.externalLink,
};

ProfileSocialIcons.defaultProps = {
  marginSize: margins.normal,
};

ProfileSocialIcons.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  isEditable: PropTypes.bool,
  onEdit: PropTypes.func,
  marginSize: PropTypes.string,
};

export default withModalForm(ProfileSocialIcons, FORM_SETTINGS);
