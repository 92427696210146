import { GET_MISSION_ROLE, GET_PUBLIC_MISSION_ROLE } from "graphql/queries";
import { useQueryData } from "hooks";

export default function useMissionRole({ id, isPublicRole, skip }) {
  const keyName = isPublicRole ? "publicMissionRole" : "missionRole";
  const {
    loading,
    data: { [keyName]: missionRole } = {},
    error,
  } = useQueryData({
    queryName: isPublicRole ? GET_PUBLIC_MISSION_ROLE : GET_MISSION_ROLE,
    keyName: keyName,
    skip: !id || skip,
    variables: { slug: id },
  });

  return {
    loading,
    data: missionRole,
    error,
  };
}
