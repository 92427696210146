import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import { FormattedMessage } from "react-intl";
import { DataTableProvider } from "components/DataTable";
import { PAGINATION_TYPE } from "components/Pagination";
import { LINKS, TAB_OPTIONS } from "constants/index";
import Tabs from "components/Tabs";
import Status from "components/Status";
import { TextInput } from "components/Form";
import { ICON_POSITION, ICON_SIZE, ICON_TYPE, withIcon } from "components/Icon";
import { Grid } from "components/Containers";
import { GET_ORGANIZATIONS } from "graphql/queries";
import { useOrganization } from "hooks";

/**
 * OrganizationList
 */
const OrganizationList = () => {
  const resultsPerPage = 30;
  const [selectedTab, setSelectedTab] = useState(0);
  const [keyword, setKeyword] = useState();
  const [debounceKeyword, setDebounceKeyword] = useState();
  const { removeOrganization, restoreOrganization } = useOrganization();
  const navigate = useNavigate();
  const debouncedSetKeyword = useMemo(() => debounce((val) => setDebounceKeyword(val), 500), []);
  const tabOptionKeys = Object.keys(TAB_OPTIONS);

  const handleSearchInputChange = (val) => {
    setKeyword(val);
    debouncedSetKeyword(val);
  };

  const rowOptions = (row) => {
    const tabRowOptions = {
      [TAB_OPTIONS.active.toLowerCase()]: [
        {
          label: <FormattedMessage id="Global.Archive" />,
          icon: ICON_TYPE.trash,
          onClick: () => removeOrganization(row?.id),
        },
      ],
      [TAB_OPTIONS.archived.toLowerCase()]: [
        {
          label: <FormattedMessage id="Global.Restore" />,
          icon: ICON_TYPE.plus,
          onClick: () => restoreOrganization(row?.id),
        },
      ],
    };

    return tabRowOptions[tabOptionKeys[selectedTab]];
  };

  return (
    <Grid>
      <Grid.col start={1} end={7}>
        <Tabs
          data={tabOptionKeys.map((key) => TAB_OPTIONS[key])}
          selected={selectedTab}
          onSelect={setSelectedTab}
          withUrlParams
        />
      </Grid.col>
      <Grid.col start={7} end={13}>
        <SearchInput
          value={keyword}
          onChange={(name, val) => handleSearchInputChange(val)}
          type="search"
          placeholder="Search by organization name"
        />
      </Grid.col>
      <Grid.col start={1} end={13}>
        <DataTableProvider
          keyName={"organizations"}
          queryName={GET_ORGANIZATIONS}
          variables={{ filters: { search: debounceKeyword, status: tabOptionKeys[selectedTab].toUpperCase() } }}
          resultsPerPage={resultsPerPage}
          columns={COLUMN_DATA((row) => navigate(LINKS.organization_profile(row?.id)))}
          paginationType={PAGINATION_TYPE.classic}
          selectable={false}
          rowOptions={rowOptions}
          paginationProps={{
            countMessage: "Global.Organizations.Count",
          }}
        />
      </Grid.col>
    </Grid>
  );
};

const COLUMN_DATA = (onClick) => [
  {
    label: <FormattedMessage id="Organizations.OrganizationsList.OrganizationName" />,
    cell: (row) => row?.name,
    onClick,
  },
  {
    label: <FormattedMessage id="Organizations.OrganizationsList.OrganizationStatus" />,
    cell: (row) => (
      <Status enabled={!row?.archivedSince}>
        <FormattedMessage id={!row?.archivedSince ? "Global.Active" : "Global.Archived"} />
      </Status>
    ),
  },
];

const SearchInput = withIcon(TextInput, {
  type: ICON_TYPE.search,
  size: ICON_SIZE.medium,
  position: ICON_POSITION.overlay,
  order: 0,
});

export default OrganizationList;
