import React from "react";
import PropTypes from "prop-types";
import { colors } from "style";
import Tooltip from "components/Tooltip";

/**
 * FeaturedBadge
 *
 * @param {String}   fill
 */
const FeaturedBadge = ({ fill, ...props }) => (
  <Tooltip tooltipId={"Badges.Featured"} hideIcon>
    <svg viewBox="0 0 130 130" {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" 
        d="M24 0C10.7452 0 0 10.7452 0 24V106C0 119.255 10.7452 130 24 130H106C119.255 130 130 119.255 130 106V24C130 10.7452 119.255 0 106 0H24ZM64.9883 23C66.9915 23 68.8155 24.1346 69.6787 25.9176L80.1507 47.3623L103.554 50.7986C105.508 51.0904 107.121 52.452 107.739 54.316C108.358 56.1801 107.853 58.2386 106.453 59.6164L89.4827 76.328L93.5053 99.9447C93.8311 101.89 93.0167 103.851 91.4044 105.018C89.7921 106.185 87.6586 106.315 85.8997 105.391L65.0046 94.2877L44.1095 105.391C42.3506 106.315 40.2171 106.169 38.6048 105.018C36.9924 103.867 36.1781 101.89 36.5039 99.9447L40.5102 76.328L23.5401 59.6164C22.1395 58.2386 21.6509 56.1801 22.2535 54.316C22.8561 52.452 24.4847 51.0904 26.439 50.7986L49.8259 47.3623L60.2979 25.9176C61.1773 24.1346 62.9851 23 64.9883 23Z"
        fill={fill || colors.pinkPantherBase}
      />
    </svg>
  </Tooltip>
);

FeaturedBadge.propTypes = {
  fill: PropTypes.string,
};

export default FeaturedBadge;
