import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import DataTable from "components/DataTable";
import { UserFullName } from "components/User";
import { TagTicker } from "components/Tags";
import { Row } from "components/Containers";
import { useTeamProfilesScores } from "hooks";
import { TEAM_CATEGORY_SCORES } from "constants/index";
import { Paginate } from "components/Pagination";
import { formatNumber } from "utils";

/**
 * MissionMemberList
 *
 * @params {String}   teamId
 */
const MissionMemberList = ({ teamId }) => {
  const resultsPerPage = 20;
  const { hasNextPage, handleNextPage, hasPreviousPage, handlePreviousPage, data, loading, loadingMore, error } =
    useTeamProfilesScores({ teamId, resultsPerPage });

  return (
    <>
      <DataTable
        data={data}
        loading={loading || loadingMore}
        error={error}
        resultsPerPage={resultsPerPage}
        columns={COLUMN_DATA}
      />
      <Paginate
        resultsPerPage={resultsPerPage}
        onNextPage={handleNextPage}
        onPreviousPage={handlePreviousPage}
        hasNextPage={!loading && hasNextPage}
        hasPreviousPage={!loading && hasPreviousPage}
      />
    </>
  );
};

/**
 * TableRowRenderer
 *
 * @params {Object}   categoryScores
 * @params {String}   type
 */
const TableRowRenderer = ({ categoryScores, type, ...rowData }) => {
  const result = categoryScores?.filter((item) => item?.category === type);
  const data = result && type ? result[0] : rowData;

  return (
    <Row>
      <div
        css={css`
          width: 3.5rem;
          white-space: nowrap;
        `}
      >
        {formatNumber(data?.score, "", 2)}
      </div>
      <TagTicker trend={data?.scoreDeltaPercent} />
    </Row>
  );
};

const COLUMN_DATA = [
  {
    label: <FormattedMessage id="Missions.MissionMemberList.ColName" />,
    width: 300,
    cell: (row) => (
      <UserFullName
        data={row?.profile}
        photoProps={{
          width: "2rem",
          height: "2rem",
          borderRadius: "100%",
        }}
        withPhoto
      />
    ),
  },
  {
    label: <FormattedMessage id="Missions.MissionMemberList.ColScore" />,
    cell: (props) => <TableRowRenderer {...props} />,
  },
  {
    label: <FormattedMessage id="Missions.MissionMemberList.ColMetric1" />,
    cell: (props) => <TableRowRenderer {...props} type={TEAM_CATEGORY_SCORES.teamDynamics} />,
  },
  {
    label: <FormattedMessage id="Missions.MissionMemberList.ColMetric2" />,
    cell: (props) => <TableRowRenderer {...props} type={TEAM_CATEGORY_SCORES.engineeringPerformance} />,
  },
  {
    label: <FormattedMessage id="Missions.MissionMemberList.ColMetric3" />,
    cell: (props) => <TableRowRenderer {...props} type={TEAM_CATEGORY_SCORES.productDelivery} />,
  },
  /*
  // To come in V2
  {
    label: "",
    cellProps: {
      alignRight: true,
    },
    cell: () => (
      <PrimaryButton size={BUTTON_SIZE.small}>
        <FormattedMessage id="Missions.MissionMemberList.RowCTA" />
      </PrimaryButton>
    ),
  },
  */
];

MissionMemberList.propTypes = {
  teamId: PropTypes.string.isRequired,
};

TableRowRenderer.propTypes = {
  categoryScores: PropTypes.object,
  type: PropTypes.string,
};

export default MissionMemberList;
