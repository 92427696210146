import React, { useState, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import { GET_PARTNERS } from "graphql/queries";
import { Grid } from "components/Containers";
import { DataTableProvider } from "components/DataTable";
import { DIRECTION, PARTNER_CHANNELS, PARTNER_TYPES } from "constants/index";
import Status from "components/Status";
import { Tag } from "components/Tags";
import { Select, TextInput } from "components/Form";
import { ICON_POSITION, ICON_SIZE, ICON_TYPE, withIcon } from "components/Icon";
import { PAGINATION_TYPE } from "components/Pagination";
import { usePartners } from "hooks";

/**
 * ListPartners
 *
 * @param {Function}  onClick
 * @param {Number}    resultsPerPage
 */
const ListPartners = ({ onClick, resultsPerPage, ...props }) => {
  const [selectedPartnerType, setSelectedPartnerType] = useState();
  const [debounceKeyword, setDebounceKeyword] = useState();
  const [keyword, setKeyword] = useState();
  const { handleArchivePartner, handleActivatePartner, handleRemovePartners } = usePartners({});
  const debouncedSetKeyword = useMemo(() => debounce((val) => setDebounceKeyword(val), 500), []);

  const handleSearchInputChange = (val) => {
    setKeyword(val);
    debouncedSetKeyword(val);
  };

  const rowOptions = ({ id, status }) => [
    {
      label: (
        <FormattedMessage
          id={`Partners.PartnerRowAdvancedOptions.Label${status === "ACTIVE" ? `Archive` : `Activate`}`}
        />
      ),
      icon: status === "ACTIVE" ? ICON_TYPE.close : ICON_TYPE.checkDotted,
      onClick: () => (status === "ACTIVE" ? handleArchivePartner(id) : handleActivatePartner(id)),
    },
  ];

  const headerOptions = (selected) => [
    {
      label: <FormattedMessage id="Partners.PartnerMenu.OptionRemove" />,
      icon: ICON_TYPE.trash,
      onClick: () => handleRemovePartners(selected),
    },
  ];

  return (
    <Grid>
      <Grid.col start={1} end={7}>
        <Select
          value={selectedPartnerType}
          placeholder="Status"
          options={PARTNER_TYPES}
          valueKey="value"
          labelKey="name"
          onChange={(name, val) => setSelectedPartnerType(val)}
          isClearable
        />
      </Grid.col>
      <Grid.col start={7} end={13}>
        <SearchInput
          value={keyword}
          onChange={(name, val) => handleSearchInputChange(val)}
          type="search"
          placeholder="Search by name..."
        />
      </Grid.col>
      <Grid.col start={1} end={13}>
        <DataTableProvider
          keyName={"partners"}
          queryName={GET_PARTNERS}
          fetchPolicy={"cache-and-network"}
          variables={{
            filters: { ...(selectedPartnerType && { status: selectedPartnerType }), name: debounceKeyword },
            orderBy: { name: DIRECTION.ascending },
          }}
          resultsPerPage={resultsPerPage}
          columnData={COLUMN_DATA(onClick)}
          paginationType={PAGINATION_TYPE.classic}
          rowOptions={rowOptions}
          headerOptions={headerOptions}
          selectable={true}
          paginationProps={{
            countMessage: "Global.Partners.Count",
          }}
          {...props}
        />
      </Grid.col>
    </Grid>
  );
};

const COLUMN_DATA = (onClick) => [
  {
    label: <FormattedMessage id="Partners.ListPartners.TableColumnName" />,
    cell: (row) => row?.name,
    onClick,
    width: "20%",
  },
  {
    label: <FormattedMessage id="Partners.ListPartners.TableColumnWebsite" />,
    cell: (row) => row?.website,
    width: "20%",
  },
  {
    label: <FormattedMessage id="Partners.ListPartners.TableColumnChannel" />,
    cell: (row) => PARTNER_CHANNELS.filter((channel) => channel.value === row?.channel)[0]?.label,
  },
  {
    label: <FormattedMessage id="Partners.ListPartners.TableColumnTrusted" />,
    cell: (row) => (
      <Status enabled={row?.trusted}>
        <FormattedMessage id={`Global.${row?.trusted ? "Yes" : "No"}`} />
      </Status>
    ),
  },
  {
    label: <FormattedMessage id="Partners.ListPartners.TableColumnRateRestricted" />,
    cell: (row) => (
      <Tag>
        <FormattedMessage id={`Global.${row?.canControlRate ? "Yes" : "No"}`} />
      </Tag>
    ),
  },
  {
    label: <FormattedMessage id="Partners.ListPartners.TableColumnTryMembers" />,
    cell: (row) => (
      <Tag>
        <FormattedMessage id={`Global.${row?.memberTrial ? "Yes" : "No"}`} />
      </Tag>
    ),
  },
  {
    label: <FormattedMessage id="Partners.ListPartners.TableColumnActive" />,
    cell: (row) => (
      <Status enabled={row?.status === "ACTIVE"}>
        <FormattedMessage id={`Partners.ListPartners.Label${row?.status === "ACTIVE" ? `Active` : `Inactive`}`} />
      </Status>
    ),
  },
];

const SearchInput = withIcon(TextInput, {
  type: ICON_TYPE.search,
  size: ICON_SIZE.medium,
  position: ICON_POSITION.overlay,
  order: 0,
});

ListPartners.defaultProps = {
  resultsPerPage: 50,
};

ListPartners.propTypes = {
  onClick: PropTypes.func,
  resultsPerPage: PropTypes.number,
};

export default ListPartners;
