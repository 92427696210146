import React, { useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { formatDate, formatNumber } from "utils";
import { useAuth, useMission } from "hooks";
import { colors, margins } from "style";
import { PrimaryLink } from "components/Links";
import { LINKS } from "constants";
import ListTable from "components/ListTable";
import { EditSquadMissionForm } from "components/Squads";
import { EditButton } from "components/Buttons";

/**
 * SquadDetails
 *
 * @params {Array}    data
 * @params {Boolean}  loading
 */
const SquadDetails = ({ data, loading, ...props }) => {
  const isMissionConnected = data?.missionId;
  const [editMissionForm, setEditMissionForm] = useState(false);
  const { data: missionData, loading: missionLoading } = useMission({ id: data?.missionId });
  const { isAdmin } = useAuth();
  const isLoading = loading || missionLoading;

  return (
    <>
      <ListTable
        title={<FormattedMessage id="Squads.Squad.Profile.ListTable.Title" />}
        secondaryTitle={
          isAdmin &&
          isMissionConnected && (
            <PrimaryLink withMoreIcon onClick={() => setEditMissionForm(true)}>
              <FormattedMessage id="Squads.Squad.Profile.ListTable.EditMission" />
            </PrimaryLink>
          )
        }
        marginSize={margins.normal}
        resultsPerPage={3} // resultsPerPage determines the number of loading row in the datatable.
        data={isLoading ? [] : ADDITIONAL_INFO(data, missionData, isAdmin)}
        loading={isLoading}
        {...props}
      />
      {missionData?.description && <div css={styles.description}>{missionData?.description}</div>}

      {isAdmin && !isLoading && (
        <>
          {!isMissionConnected && (
            <EditButton data-header-edit-link onClick={() => setEditMissionForm(true)}>
              <FormattedMessage id="Squads.Squad.Profile.ListTable.LinkMission" />
            </EditButton>
          )}

          {editMissionForm && (
            <EditSquadMissionForm
              show={editMissionForm}
              onClose={() => setEditMissionForm(false)}
              mission={missionData}
              team={data}
            />
          )}
        </>
      )}
    </>
  );
};

const ADDITIONAL_INFO = (data, missionData, isAdmin) => {
  return [
    {
      label: <FormattedMessage id="Squads.Squad.Profile.ListTable.SquadName" />,
      value: data?.name,
    },
    ...(missionData?.name
      ? [
          {
            label: <FormattedMessage id="Squads.Squad.Profile.ListTable.Name" />,
            value: (
              <PrimaryLink
                to={isAdmin ? LINKS.mission_edit(missionData?.uuid) : LINKS.mission(missionData?.uuid)}
                routerEnabled
                withMoreIcon
              >
                {missionData?.name}
              </PrimaryLink>
            ),
          },
        ]
      : []),
    {
      label: <FormattedMessage id="Squads.Squad.Profile.ListTable.Details.ActiveSince" />,
      value: data?.activeSince && formatDate(data?.activeSince),
    },
    {
      label: <FormattedMessage id="Squads.Squad.Profile.ListTable.Details.NumberOfDaysActive" />,
      value: data?.activeDays && `${formatNumber(data?.activeDays)} days`,
    },
    ...(missionData?.description
      ? [
          {
            label: <FormattedMessage id="Squads.Squad.Profile.ListTable.Details.description" />,
          },
        ]
      : []),
  ];
};

const styles = {
  description: css`
    color: ${colors.grayAnatomyBase};
    line-height: normal;
    white-space: pre-wrap;
  `,
};

SquadDetails.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default SquadDetails;
