import { gql } from "@apollo/client";

const GET_TEAM_PERMISSIONS = gql`
  query teamPermissionsFetch($id: ID!) {
    teamPermissions(id: $id) {
      canAccessProfile
      canAccessInsights
      canAccessTermSheet
      canAccessDailyReport
      canAccessEffortReport
      canAccessEngineering
      canAccessWeeklyReport
      canAccessSettings
      canAccessGeneralSettings
      canAccessClientFeedback
      canAccessSignalInstallation
      canAccessMetricsSettings
      canAccessActivityLogMeta
      canAccessPulse
    }
  }
`;

export { GET_TEAM_PERMISSIONS };
