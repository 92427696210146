import { gql } from "@apollo/client";

const GET_ALL_TIME_ACTIVITY = gql`
  query GetAllTimeActivity($teamId: ID!, $filters: AllTimeActivityFilter) {
    teamAllTimeActivity(teamId: $teamId, filters: $filters) {
      profile {
        name
        uuid
        slug
        avatarUrl
        firstName
        lastName
        archetype
        location
        oldLocation {
          city
          country
          countryFlag
          state
        }
      }
      assignment {
        id
      }
      activities {
        name
        value
      }
      userHandle
    }
  }
`;

export { GET_ALL_TIME_ACTIVITY };
