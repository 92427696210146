import React from "react";
import { css } from "@emotion/react";
import Form, { TYPE, SIZE } from "components/Form";
import { DATE_FORMAT_TYPE, TIME_CARD_STATUS } from "constants/index";
import { GET_TEAMS } from "graphql/queries";
import { FormattedMessage } from "react-intl";

/**
 * TimeCardFilterForm
 */
const TimeCardFilterForm = (props) => <Form data={COLUMNS} {...props} />;

const COLUMNS = [
  {
    style: css`
      width: 100%;
      padding: 0;
    `,
    items: [
      {
        label: "Period",
        type: TYPE.datePicker,
        size: SIZE.small,
        properties: {
          name: "period",
          required: true,
          showMonthYearPicker: true,
          showFullMonthYearPicker: true,
          format: DATE_FORMAT_TYPE.MMyyyy,
          popperPlacement: "bottom-start",
          maxDate: new Date(),
        },
      },
      {
        label: "Search",
        type: TYPE.input,
        size: SIZE.small,
        hint: (
          <span>
            <b>enter</b> to submit
          </span>
        ),
        properties: {
          name: "search",
          type: "search",
          maxLength: 100,
          required: true,
          placeholder: "Type keywords...",
        },
      },
      {
        label: "Team",
        type: TYPE.select,
        size: SIZE.small,
        properties: {
          name: "teamId",
          required: true,
          placeholder: "Select team...",
          queryName: GET_TEAMS,
          queryKeyName: "teams",
          labelKey: "name",
          valueKey: "id",
          isClearable: true,
        },
      },
      {
        label: "Status",
        type: TYPE.select,
        size: SIZE.small,
        properties: {
          name: "status",
          placeholder: "Select status...",
          required: true,
          labelKey: "name",
          valueKey: "value",
          options: Object.keys(TIME_CARD_STATUS).map((item) => ({
            name: <FormattedMessage id={`TimeCardStatus.${TIME_CARD_STATUS[item]}`} />,
            value: TIME_CARD_STATUS[item],
          })),
          isClearable: true,
        },
      },
      {
        type: TYPE.checkbox,
        size: SIZE.medium,
        properties: {
          name: "overAllocatedHours",
          label: <FormattedMessage id="TimeCard.TimeCardFilterMenu.AllocatedOptionDescription" />,
          required: true,
        },
      },
      {
        type: TYPE.checkbox,
        size: SIZE.medium,
        properties: {
          name: "withOnboardedOffboardedDates",
          label: <FormattedMessage id="TimeCard.TimeCardFilterMenu.DatesOptionDescription" />,
          required: true,
        },
      },
    ],
  },
];

export default TimeCardFilterForm;
