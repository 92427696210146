import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { ICON_TYPE } from "components/Icon";
import { LINKS } from "constants/index";
import { colors, fontSize } from "style";
import { useAuth } from "hooks";
import { MenuItemLogout, MenuItemSquads } from "components/Nav/SideNav/MenuItems";
import { SideNavLink, SideNavTooltip } from "components/Nav/SideNav";
import { PrimaryButton } from "components/Buttons";
import { SquadFormAsClient } from "components/Squads";

/**
 * MenuItemsByUserType
 */
const MenuItemsByUserType = () => {
  const { isPartner, isFreelancer, isRecruiter, isClient, isSignalClient, isShowcase, isAdmin, teams, ...me } =
    useAuth();

  const MenuItems = () => {
    if (isClient) {
      return <ClientMenu />;
    } else if (isFreelancer) {
      return <MemberMenu {...me} />;
    } else if (isSignalClient) {
      return <SignalClientMenu teams={teams} {...me} />;
    } else if (isShowcase) {
      return <ShowcaseMenu />;
    } else if (isPartner) {
      return <PartnerMenu />;
    } else if (isRecruiter) {
      return <RecruiterMenu />;
    } else if (isAdmin) {
      return <AdminMenu />;
    }
  };

  return (
    <>
      <MenuItems />
      <StyledLabel>
        <FormattedMessage id="Nav.SideNav.LabelAccount" />
      </StyledLabel>
      <MenuItemLogout isShowcase={isShowcase} />
    </>
  );
};

const SignalClientMenu = ({ teams, organization }) => {
  const [showForm, setShowForm] = useState(false);

  return (
    <>
      <SquadFormAsClient
        show={showForm}
        onClose={() => setShowForm(false)}
        onComplete={() => setShowForm(false)}
        organizationId={organization?.slug}
      />
      <StyledLabel>
        <FormattedMessage id={`Nav.SideNav.LabelSquadsSignal`} />
      </StyledLabel>
      {teams?.map((team) => (
        <MenuItemSquads
          key={team?.slug}
          team={team}
          subNavItems={[
            {
              id: "profile",
              title: <FormattedMessage id="Nav.SideNav.Squads.Overview" />,
              link: LINKS.squad_profile(team?.slug),
              icon: ICON_TYPE.analytics,
              permissionKey: "canAccessProfile",
            },
            {
              id: "insights",
              title: <FormattedMessage id="Nav.SideNav.Squads.Insights" />,
              link: LINKS.squad_insights(team?.slug), // FIX ME: REPLACE WITH INSIGHTS PAGE
              icon: ICON_TYPE.analytics, // FIX ME: REPLACE WITH ICON
              permissionKey: "canAccessInsights",
            },
            {
              id: "activity_log",
              title: <FormattedMessage id="Nav.SideNav.Squads.CodeTelemetry" />,
              link: LINKS.squad_engineering_code_telemetry(team?.slug),
              icon: ICON_TYPE.performance,
              permissionKey: "canAccessEngineering",
            },
            {
              id: "engineering",
              title: <FormattedMessage id="Nav.SideNav.Squads.ActivityLog" />,
              link: LINKS.squad_activity_log(team?.slug),
              icon: ICON_TYPE.status,
              permissionKey: "canAccessEngineering",
            },
          ]}
        />
      ))}
      {!teams || teams.length === 0 ? (
        <PrimaryButton variant="green" onClick={() => setShowForm(true)}>
          <SideNavTooltip textId="Nav.SideNav.AddTeamSignal" values={{ icon: "+" }} />
        </PrimaryButton>
      ) : (
        <SideNavLink type={ICON_TYPE.plus} onClick={() => setShowForm(true)}>
          <SideNavTooltip textId="Nav.SideNav.AddTeamSignal" values={{ icon: "" }} />
        </SideNavLink>
      )}
      <StyledLabel>
        <FormattedMessage id="Nav.SideNav.LabelOtherSignal" />
      </StyledLabel>
      <SideNavLink type={ICON_TYPE.organization} to={LINKS.organization_profile(organization?.slug)} routerEnabled end>
        <FormattedMessage id="Nav.SideNav.OrganizationSignal" />
      </SideNavLink>
      <StyledLabel>
        <FormattedMessage id="Nav.SideNav.LabelTalent" />
      </StyledLabel>
      <SideNavLink type={ICON_TYPE.search} to={LINKS.client_search} routerEnabled end>
        <SideNavTooltip textId="Nav.SideNav.LabelTalentSearch" />
      </SideNavLink>
    </>
  );
};

const ClientMenu = () => (
  <>
    <StyledLabel>
      <FormattedMessage id="Nav.SideNav.LabelTalent" />
    </StyledLabel>
    <SideNavLink type={ICON_TYPE.search} to={LINKS.client_search} routerEnabled end>
      <SideNavTooltip textId="Nav.SideNav.LabelTalentSearch" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.star} to={LINKS.featured_teams} routerEnabled>
      <SideNavTooltip textId="Nav.SideNav.LabelFeaturedTeams" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.heart} to={LINKS.favorites} routerEnabled>
      <SideNavTooltip textId="Nav.SideNav.Client.LabelFavorites" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.user} to={LINKS.client_applications} routerEnabled end>
      <SideNavTooltip textId="Nav.SideNav.LabelTalentApplications" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.calendar} to={LINKS.client_mission_board} routerEnabled>
      <SideNavTooltip textId="Nav.SideNav.LabelBoard" />
    </SideNavLink>
    <SquadsMenuItem />
    <StyledLabel>
      <FormattedMessage id="Nav.SideNav.LabelOrganization" />
    </StyledLabel>
    <SideNavLink disabled type={ICON_TYPE.organization}>
      <SideNavTooltip textId="Nav.SideNav.LabelOrganizationSettings" />
    </SideNavLink>
    <StyledLabel>
      <FormattedMessage id="Nav.SideNav.LabelCommunity" />
    </StyledLabel>
    <SideNavLink type={ICON_TYPE.townHall} to={LINKS.community} routerEnabled end>
      <FormattedMessage id="Nav.SideNav.LabelCommunityHub" />
    </SideNavLink>
  </>
);

const MemberMenu = ({ profileSlug, canViewEditRate }) => (
  <>
    <SideNavLink type={ICON_TYPE.home} to={LINKS.home} routerEnabled>
      <FormattedMessage id="Nav.SideNav.LabelHome" />
    </SideNavLink>
    <StyledLabel>
      <FormattedMessage id="Nav.SideNav.LabelMissions" />
    </StyledLabel>
    <SideNavLink type={ICON_TYPE.calendar} to={LINKS.missions} routerEnabled>
      <FormattedMessage id="Nav.SideNav.LabelBoard" />
    </SideNavLink>
    <SquadsMenuItem />
    <StyledLabel>
      <FormattedMessage id="Nav.SideNav.LabelMember" />
    </StyledLabel>
    <SideNavLink type={ICON_TYPE.user} to={LINKS.private_profile(profileSlug)} routerEnabled end>
      <FormattedMessage id="Nav.SideNav.LabelProfile" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.hourglass} to={LINKS.time_sheet} routerEnabled>
      <FormattedMessage id="Nav.SideNav.LabelTimeSheet" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.coin} to={LINKS.rate(profileSlug)} routerEnabled end>
      <FormattedMessage id={canViewEditRate ? "Nav.SideNav.LabelRate" : "Nav.SideNav.LabelAvailability"} />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.video} to={LINKS.profile_video_interviews(profileSlug)} routerEnabled end>
      <FormattedMessage id="Video Interviews" />
    </SideNavLink>
    <StyledLabel>
      <FormattedMessage id="Nav.SideNav.LabelCommunity" />
    </StyledLabel>
    <SideNavLink type={ICON_TYPE.townHall} to={LINKS.community} routerEnabled end>
      <FormattedMessage id="Nav.SideNav.LabelCommunityHub" />
    </SideNavLink>
  </>
);

const ShowcaseMenu = () => (
  <>
    <StyledLabel>
      <FormattedMessage id="Nav.SideNav.LabelTalent" />
    </StyledLabel>
    <SideNavLink type={ICON_TYPE.search} to={LINKS.showcase_search} routerEnabled end>
      <SideNavTooltip textId="Nav.SideNav.LabelTalentSearch" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.mentorship} to={LINKS.showcase_team_builder} routerEnabled end>
      <SideNavTooltip textId="Nav.SideNav.LabelTalentTeamBuilder" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.star} to={LINKS.featured_teams} routerEnabled>
      <SideNavTooltip textId="Nav.SideNav.LabelFeaturedTeams" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.heart} to={LINKS.favorites} routerEnabled>
      <SideNavTooltip textId="Nav.SideNav.Client.LabelFavorites" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.user} to={LINKS.client_applications} routerEnabled end>
      <SideNavTooltip textId="Nav.SideNav.LabelTalentApplications" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.calendar} to={LINKS.client_mission_board} routerEnabled>
      <SideNavTooltip textId="Nav.SideNav.LabelBoard" />
    </SideNavLink>
    <SquadsMenuItem />
    <StyledLabel>
      <FormattedMessage id="Nav.SideNav.LabelCommunity" />
    </StyledLabel>
    <SideNavLink type={ICON_TYPE.townHall} to={LINKS.community} routerEnabled end>
      <FormattedMessage id="Nav.SideNav.LabelCommunityHub" />
    </SideNavLink>
  </>
);

const PartnerMenu = () => (
  <>
    <SideNavLink type={ICON_TYPE.network} to={LINKS.network} routerEnabled>
      <FormattedMessage id="Nav.SideNav.LabelNetwork" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.mentorship} to={LINKS.mission_board} routerEnabled end>
      <FormattedMessage id="Nav.SideNav.LabelMissions" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.clock} to={LINKS.timecards} routerEnabled end>
      <FormattedMessage id="Nav.SideNav.LabelTimeReport" />
    </SideNavLink>
  </>
);

const RecruiterMenu = () => (
  <>
    <SideNavLink type={ICON_TYPE.network} to={LINKS.network} routerEnabled end>
      <FormattedMessage id="Nav.SideNav.LabelNetwork" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.mentorship} to={LINKS.missions} routerEnabled end>
      <FormattedMessage id="Nav.SideNav.LabelMissions" />
    </SideNavLink>
  </>
);

const AdminMenu = () => (
  <>
    <SideNavLink type={ICON_TYPE.performance} to={LINKS.dashboard_home} routerEnabled>
      <FormattedMessage id="Nav.SideNav.LabelDashboard" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.network} to={LINKS.network_with_defaults} routerEnabled>
      <FormattedMessage id="Nav.SideNav.LabelNetwork" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.board} to={LINKS.missions} routerEnabled>
      <FormattedMessage id="Nav.SideNav.LabelMissions" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.mentorship} to={LINKS.squads} routerEnabled>
      <FormattedMessage id="Nav.SideNav.AdminSquads" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.organization} to={LINKS.organizations} routerEnabled>
      <FormattedMessage id="Nav.SideNav.LabelOrganizations" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.listBullet} to={LINKS.lists} routerEnabled>
      <FormattedMessage id="Nav.SideNav.LabelLists" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.businessCard} to={LINKS.partners} routerEnabled>
      <FormattedMessage id="Nav.SideNav.LabelPartners" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.clock} to={LINKS.timecards} routerEnabled>
      <FormattedMessage id="Nav.SideNav.LabelTimeReport" />
    </SideNavLink>
    <SideNavLink type={ICON_TYPE.gear} to={LINKS.platform_settings} routerEnabled>
      <FormattedMessage id="Nav.SideNav.LabelPlatformSettings" />
    </SideNavLink>
  </>
);

const SquadsMenuItem = () => {
  const { teams, isClient, isShowcase } = useAuth();
  const hasSquads = teams?.length > 0;

  if (!hasSquads) return null;

  return (
    <>
      <StyledLabel>
        <FormattedMessage id={isShowcase || isClient ? `Nav.SideNav.LabelActiveMissions` : `Nav.SideNav.LabelSquads`} />
      </StyledLabel>
      {teams?.map((team, index) => (
        <MenuItemSquads key={index} team={team} />
      ))}
    </>
  );
};

const StyledLabel = styled.label`
  text-transform: uppercase;
  font-size: ${fontSize.xxxsmall};
  letter-spacing: 0.2rem;
  font-weight: 600;
  color: ${colors.grayAnatomyLight1};
  margin-top: 2rem;
  margin-bottom: 0.5rem;

  @media (max-height: 850px) {
    margin-bottom: 0.25rem;
    margin-top: 1.5rem;
  }
`;

SignalClientMenu.propTypes = {
  teams: PropTypes.array,
  organization: PropTypes.object,
};

MemberMenu.propTypes = {
  profileSlug: PropTypes.string.isRequired,
  canViewEditRate: PropTypes.bool.isRequired,
};

export default MenuItemsByUserType;
