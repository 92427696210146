import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { useAuth } from "hooks";
import { MissionOrganizationName } from "components/Missions";
import { TextBlock, LAYOUT_SIZE } from "components/Layouts";
import { PrimaryLink } from "components/Links";
import { LINKS } from "constants";

/**
 * MissionAboutClient
 *
 * @param {Object}   mission
 * @param {Boolean}  isLoading
 */
const MissionAboutClient = ({ mission, isLoading }) => {
  const { isAdmin } = useAuth();
  const intl = useIntl();
  const isOrganizationConfidential = !isAdmin && mission?.isOrganizationConfidential;
  const companyDescription = isOrganizationConfidential
    ? intl.formatMessage({ id: "Missions.Mission.Confidential.Description" })
    : mission?.companyDescription;

  return (
    <TextBlock
      title={
        <FormattedMessage
          id="Missions.MissionAboutClient"
          values={{
            name: <MissionOrganizationName mission={mission} isLoading={isLoading} />,
          }}
        />
      }
      cta={
        isAdmin && (
          <PrimaryLink to={LINKS.organization_profile(mission?.organization?.id)} withMoreIcon routerEnabled>
            <FormattedMessage id="Global.ViewMore" />
          </PrimaryLink>
        )
      }
      body={companyDescription}
      size={LAYOUT_SIZE.small}
      isLoading={isLoading}
    />
  );
};

MissionAboutClient.propTypes = {
  mission: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default MissionAboutClient;
