import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { CHANGE_KEYS } from "constants/index";
import {
  CellContainer,
  LabelCell,
  TermSheetInput,
  HeaderCell,
  DataCell,
  StyledInput,
  TermSheetMemberRemoveButton,
  TermSheetMemberAddButton,
} from "components/TermSheets";
import { UserArchetype, UserFullName, UserLocation } from "components/User";

/**
 * TermSheetRow
 *
 * @param {String}    keyName             - The key name for the row
 * @param {String}    type                - The input type
 * @param {Function}  valueFunction       - The value function for each cell in the row
 * @param {Array}     teamMembers         - Array of object of team members
 * @param {Function}  onChange            - Handles input changes
 * @param {Function}  onRevert            - Handles input reverts
 * @param {String}    toCurrency          - Currency for cell
 * @param {String}    total               - Total value
 * @param {String}    totalCurrency       - Total currency
 * @param {Boolean}   hideTotal           - Hide total column
 * @param {Object}    changes             - Scenario changes
 * @param {Boolean}   disabled            - Disable the the entire row
 * @param {String}    marginSize
 * @param {String}    conversionRate      - The rate of conversion for the currency fields
 */
const TermSheetRow = ({
  keyName,
  type,
  valueFunction,
  teamMembers,
  marginSize,
  onChange,
  onRevert,
  total,
  currency,
  changes,
  disabled,
  enabled,
  hideTotal,
  conversionRate,
  ...props
}) => {
  const shoudlDisableInput = (changes, rest, keyName) => {
    if (keyName === CHANGE_KEYS.scheduledAt) {
      return !changes?.[rest.profile?.id];
    } else {
      return !enabled && (disabled || rest.remove);
    }
  };

  return (
    <>
      <CellContainer>
        <LabelCell marginSize={marginSize}>
          <FormattedMessage id={`TermSheets.TermSheetRow.Row_${keyName}`} values={{ currency }} />
        </LabelCell>
      </CellContainer>
      {teamMembers?.map((rest) => (
        <CellContainer
          key={`${keyName}${rest.profile?.id}`}
          disabled={!enabled && (disabled || rest.remove)}
          removed={rest.remove}
        >
          <TermSheetInput
            type={type}
            name={`${keyName}${rest.profile?.id}`}
            value={valueFunction ? valueFunction({ ...rest, conversionRate }) : rest[keyName]}
            onChange={(n, v) => onChange(keyName, rest.profile?.id, v, rest)}
            onRevert={(n, v) => onRevert(keyName, rest.profile?.id, v, true)}
            hasChanges={changes?.[rest.profile?.id]?.[keyName]}
            currency={currency}
            disabled={shoudlDisableInput(changes, rest, keyName)}
            removed={!enabled && rest.remove}
            {...rest}
            {...props}
          />
        </CellContainer>
      ))}
      <CellContainer disabled={!hideTotal}>
        {!hideTotal && (
          <TermSheetInput
            {...props}
            key={`${keyName}-total`}
            name={`${keyName}-total`}
            disabled
            value={total}
            type={type}
            currency={currency}
            toCurrency={null}
          />
        )}
      </CellContainer>
    </>
  );
};

/**
 * HeaderCells
 *
 * @param {Array}     teamMembers
 * @param {Function}  onRemove
 * @param {Boolean}   isScenario
 * @param {Function}  onAdd
 */
const TermSheetHeaderRow = ({ teamMembers, onRemove, onAdd, isScenario }) => (
  <>
    <CellContainer>
      <HeaderCell />
    </CellContainer>
    {teamMembers?.map(({ profile, ...rest }) => (
      <CellContainer key={`${profile?.slug}archetype`} disabled={rest.remove}>
        <HeaderCell disabled={rest.remove}>
          <UserArchetype data={profile} />
          {isScenario && (
            <TermSheetMemberRemoveButton
              data={profile?.id}
              onClick={() => onRemove(profile?.id, rest.remove === true ? false : true)}
              isRemoved={rest.remove}
            />
          )}
        </HeaderCell>
      </CellContainer>
    ))}
    <CellContainer disabled>
      <HeaderCell alignRight>
        <FormattedMessage id="TermSheets.TermSheetLayout.RowTotal" />
        {isScenario && <TermSheetMemberAddButton onAdd={onAdd} />}
      </HeaderCell>
    </CellContainer>
  </>
);

/**
 * HeaderCells
 *
 * @param {Array} teamMembers
 * @param {String} marginSize
 *
 * @returns
 */
const TermSheetMemberRow = ({ teamMembers, marginSize }) => (
  <>
    <CellContainer>
      <LabelCell marginSize={marginSize}>Resource Name</LabelCell>
    </CellContainer>
    {teamMembers?.map(({ profile, ...rest }) => (
      <CellContainer key={`${profile?.slug}name`} disabled={rest.remove} removed={rest.remove}>
        <HeaderCell disabled={rest.remove}>
          <UserFullName
            data={profile}
            photoProps={{
              borderRadius: "100%",
              width: `2rem`,
              height: `2rem`,
            }}
            withPhoto
          />
        </HeaderCell>
      </CellContainer>
    ))}
    <CellContainer disabled>
      <DataCell>
        <StyledInput disabled value={teamMembers.length} />
      </DataCell>
    </CellContainer>
  </>
);

/**
 * HeaderCells
 *
 * @param {Array} teamMembers
 * @param {String} marginSize
 *
 * @returns
 */
const TermSheetLocationRow = ({ teamMembers, marginSize }) => (
  <>
    <CellContainer>
      <LabelCell marginSize={marginSize}>Location</LabelCell>
    </CellContainer>
    {teamMembers?.map(({ profile, ...rest }) => (
      <CellContainer key={`${profile?.slug}location`} disabled={rest.remove} removed={rest.remove}>
        <HeaderCell disabled={rest.remove}>
          <UserLocation abbreviate data={profile} />
        </HeaderCell>
      </CellContainer>
    ))}
    <CellContainer disabled>
      <DataCell>
        <StyledInput disabled value="—" />
      </DataCell>
    </CellContainer>
  </>
);

TermSheetHeaderRow.propTypes = {
  teamMembers: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
  isScenario: PropTypes.bool,
  onAdd: PropTypes.func,
};

TermSheetMemberRow.propTypes = {
  teamMembers: PropTypes.array.isRequired,
  marginSize: PropTypes.string,
};

TermSheetLocationRow.propTypes = {
  teamMembers: PropTypes.array.isRequired,
  marginSize: PropTypes.string,
};

TermSheetRow.propTypes = {
  label: PropTypes.string.isRequired,
  keyName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  valueFunction: PropTypes.func,
  marginSize: PropTypes.string,
  teamMembers: PropTypes.array.isRequired,
  totalValueFunction: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onRevert: PropTypes.func.isRequired,
  total: PropTypes.string,
  currency: PropTypes.string,
  disabled: PropTypes.bool,
  enabled: PropTypes.bool,
  changes: PropTypes.object,
  hideTotal: PropTypes.bool,
  conversionRate: PropTypes.number,
};

export { TermSheetHeaderRow, TermSheetMemberRow, TermSheetLocationRow };

export default TermSheetRow;
