import React from "react";
import { GET_TEAM, GET_TEAMS, GET_TEAM_PERMISSIONS } from "graphql/queries";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { useQueryData, useToast, useAuth, useProfile } from "hooks";
import { REMOVE_TEAM, RESTORE_TEAM, UPDATE_TEAM } from "graphql/mutations";
import { ARCHETYPES } from "constants/index";

export default function useTeam({ id } = {}) {
  const { toast } = useToast();
  const { isAdmin, profileId } = useAuth();
  const { data: ProfileData } = useProfile({ profileId });
  const SLTL = ProfileData?.profile?.archetype?.toUpperCase();
  const isSLTL = SLTL === ARCHETYPES.squad_lead?.id || SLTL === ARCHETYPES.tech_lead?.id;
  const [handleUpdateTeam, { loading: updating }] = useMutation(UPDATE_TEAM);
  const [handleRemoveTeam] = useMutation(REMOVE_TEAM, { refetchQueries: [GET_TEAMS] });
  const [handleRestoreTeam] = useMutation(RESTORE_TEAM, { refetchQueries: [GET_TEAMS] });

  const variables = {
    id,
    organization: true,
    assignments: true,
    limit: null,
  };

  const { loading, data, error, refetch } = useQueryData({
    queryName: GET_TEAM(isAdmin, isSLTL),
    keyName: "team",
    skip: !id,
    variables,
  });

  const { data: permissionsData, loading: permissionsLoading } = useQueryData({
    queryName: GET_TEAM_PERMISSIONS,
    keyName: "teamPermissions",
    skip: !id,
    variables: {
      id,
    },
  });

  const updateTeam = (id, params) => {
    handleUpdateTeam({
      variables: {
        input: {
          id,
          params,
        },
      },
      optimisticResponse: () => ({
        updateTeam: {
          team: {
            id: data?.team?.id,
            ...params,
            __typename: "Team",
          },
          __typename: "UpdateTeamPayload",
        },
      }),
      onCompleted: () => {
        toast.success(<FormattedMessage id="Squads.Squad.Settings.EngineeringMetrics.Form.Success" />);
      },
      onError: () => {
        refetch();
        toast.error(<FormattedMessage id="Squads.Squad.Settings.EngineeringMetrics.Form.Error" />);
      },
    });
  };

  const removeTeam = (id) => {
    handleRemoveTeam({
      variables: {
        input: {
          id,
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id="Squads.AdvancedActions.RemoveSuccess" />),
      onError: ({ message }) => toast.error(message || <FormattedMessage id="Squads.AdvancedActions.RemoveError" />),
    });
  };

  const restoreTeam = (id) => {
    handleRestoreTeam({
      variables: {
        input: {
          id,
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id="Squads.AdvancedActions.RestoreSuccess" />),
      onError: () => toast.error(<FormattedMessage id="Squads.AdvancedActions.RestoreError" />),
    });
  };

  return {
    loading,
    data: data?.team,
    error,
    updating,
    updateTeam,
    removeTeam,
    restoreTeam,
    refetch,
    teamPermissions: permissionsData?.teamPermissions,
    permissionsLoading: permissionsLoading,
  };
}
