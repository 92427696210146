import { gql } from "@apollo/client";

const GET_ACTIVITY_LOGS = gql`
  query GetActivityLogs($filters: ActivityLogsFilter!, $teamId: ID!) {
    teamActivityLogs(teamId: $teamId, filters: $filters) {
      profile {
        name
        uuid
        slug
        avatarUrl
        firstName
        lastName
        archetype
        location
        oldLocation {
          city
          country
          countryFlag
          state
        }
      }
      assignment {
        id
      }
      activityLogs {
        date
        activities {
          type
          count
          metaData {
            repository
            pullRequest
            pullRequestTitle
            title
            length
            status
            messageLength
            diffAdditions
            diffDeletions
            diffTotalChanges
            data
            type
          }
        }
      }
      metaData {
        minutesWorked
        weeklyCost
        costPerCommit
        currency
      }
      userHandle
    }
  }
`;

export { GET_ACTIVITY_LOGS };
