/**
 * convertToPercent
 *
 * @param {String}    val
 *
 * @returns {String} The formatted string
 */
export default function convertToPercent(val) {
  return `${(Number(val) * 100).toFixed()}%`;
}
