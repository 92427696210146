/**
 * Get all the months between the given range
 *
 * @param {String}    mode
 *
 * @returns {Array}
 */
export default function getMonthsBetween(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const months = [];
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  while (start <= end) {
    const month = monthNames[start.getMonth()];
    const year = start.getFullYear();
    months.push(`${month} ${year}`);
    start.setMonth(start.getMonth() + 1);
  }

  return months;
}
