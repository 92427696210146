import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { Grid, PageWrapper } from "components/Containers";
import { ProfileInterviewsDeleteButton, ProfileNav, ProfileVideoInterviewsList } from "components/Profile";
import PageTitle from "components/PageTitle";
import { useAuth } from "hooks";

/**
 * ProfileVideoInterviews
 */
const ProfileVideoInterviews = () => {
  const { id } = useParams();
  const intl = useIntl();
  const { isMember, isAdmin } = useAuth();
  const [interviewId, setInterviewId] = useState();

  return (
    <>
      <ProfileNav id={id} />
      <PageWrapper>
        <Helmet>
          <title>{intl.formatMessage({ id: "Meta.Profiles.VideoInterviews.Title" })}</title>
        </Helmet>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle
              {...(isMember
                ? {
                    title: <FormattedMessage id="Profile.VideoInterviews.Title" />,
                  }
                : {
                    subtitle: <FormattedMessage id="Profile.VideoInterviews.Title" />,
                  })}
              actionBar={<ProfileInterviewsDeleteButton profileId={id} interviewId={interviewId} canDelete={isAdmin} />}
            />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <ProfileVideoInterviewsList profileId={id} setInterviewId={setInterviewId} />
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default ProfileVideoInterviews;
