import { gql } from "@apollo/client";

const GET_PULSE = gql`
  query pulse($teamId: ID!, $filters: PulseFilter!, $mode: SurveyMode!) {
    pulse(teamId: $teamId, filters: $filters, mode: $mode) {
      assessee {
        ... on Profile {
          id
          slug
          name
          firstName
          lastName
        }
        ... on Team {
          id
          name
        }
      }
      assessor {
        id
        slug
        name
        firstName
        lastName
      }
      feedback
      openFeedback
      month
      year
      period
      questionText
    }
  }
`;

export { GET_PULSE };
