import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useSquadInsights } from "hooks";
import { Grid } from "components/Containers";
import { DataTableProvider } from "components/DataTable";
import { PAGINATION_TYPE } from "components/Pagination";
import { SquadInsightItem } from "components/Squads/Insights";

/**
 * SquadInsightsList
 *
 * @param {String}  teamId
 * @param {Object}  formValues
 */
const SquadInsightsList = ({ teamId, formValues, ...props }) => {
  const paginationType = PAGINATION_TYPE.classic;
  const {
    data,
    loading,
    error,
    pageInfo,
    totalCount,
    loadingMore,
    hasNextPage,
    hasPreviousPage,
    handleNextPage,
    handlePreviousPage,
    handlePageChange,
    currentPage,
    resultsPerPage,
  } = useSquadInsights({
    teamId,
    ...formValues,
  });

  const isLoading = loading || loadingMore;

  return (
    <>
      <Grid>
        <Grid.col start={1} end={13}>
          <DataTableProvider
            data={data}
            error={error}
            loading={isLoading}
            resultsPerPage={resultsPerPage}
            columns={COLUMN_DATA(isLoading)}
            paginationProps={{
              type: paginationType,
              resultsPerPage: resultsPerPage,
              onNextPage: handleNextPage,
              handlePageChange: handlePageChange,
              onPreviousPage: handlePreviousPage,
              hasNextPage: !loading && hasNextPage,
              hasPreviousPage: !loading && hasPreviousPage,
              loading,
              pageInfo,
              totalCount,
              currentPage,
              countMessage: "Global.Squads.Insights.Count",
            }}
            {...props}
          />
        </Grid.col>
      </Grid>
    </>
  );
};

const COLUMN_DATA = (loading) => [
  {
    label: <FormattedMessage id="Squads.Insights.TableColumn.Insight" />,
    cell: (row) => <SquadInsightItem insight={row} index={Number(new Date())} loading={loading} />,
  },
];

SquadInsightsList.propTypes = {
  teamId: PropTypes.string.isRequired,
  formValues: PropTypes.object,
};

export default SquadInsightsList;
