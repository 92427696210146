import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { colors, fontSize } from "style";
import { UserPhoto, UserArchetype, UserFullName, UserLocation, UserBadges } from "components/User";
import Tags from "components/Tags";
import { TextLoader } from "components/Loader";
import Card from "components/Card";
import { TAG_COLORS } from "components/Tags/Tag";
import { ExperienceTag, ProfileRateRange } from "components/Profile";

/**
 * ProfileCard
 *
 * @param {Object}      data
 * @param {Array}       actions
 * @param {Boolean}     loading
 * @param {Function}    onClick
 * @param {Boolean}     hideRate
 * @param {ReactNode}   primaryAction
 */
const ProfileCard = ({ data, actions, loading, onClick, hideRate, primaryAction, ...props }) => {
  if (loading) {
    return <PlaceHolder />;
  }

  const handleContainerClick = (event) => event.stopPropagation();

  return (
    <StyledCard noMargin {...props}>
      <a href="#" onClick={() => onClick(data, true)}>
        <div css={styles.container}>
          <UserPhoto
            data={data}
            loading={loading}
            width={`10rem`}
            height={`10rem`}
            borderRadius="100%"
            showInterviews
          />
          <div css={styles.name}>
            <UserFullName data={data} disableLink />
          </div>
          <div css={styles.archetype}>
            <UserArchetype data={data} />
          </div>
          <div css={styles.location}>
            <UserLocation data={data} hideState={true} />
          </div>
          <UserBadges badges={data?.badges} size="2.5rem" limit={5} />
          <div css={styles.disciplines}>
            <ExperienceTag experiences={data?.workExperiences} />
            {!hideRate && (
              <ProfileRateRange
                low={data?.sellingPriceEstimate?.hourly?.low}
                high={data?.sellingPriceEstimate?.hourly?.high}
                currency={data?.sellingPriceEstimate?.currency}
                negotiatedRate={data?.profileList?.negotiatedRate}
                negotiatedRateCurrency={data?.profileList?.negotiatedRateCurrency}
              />
            )}
          </div>
          <div css={styles.disciplines}>
            <Tags data={data?.disciplines} limit={4} unwrap {...TAG_COLORS.purple} />
            <Tags data={data?.skills} limit={4} unwrap {...TAG_COLORS.pink} />
          </div>
          {primaryAction && <div css={styles.primary_action}>{primaryAction}</div>}
          {actions?.length > 0 && (
            <div css={styles.button_container} className="view-profile" onClick={handleContainerClick}>
              {actions?.map((Component, index) => (
                <Component key={index} {...data} />
              ))}
            </div>
          )}
        </div>
      </a>
    </StyledCard>
  );
};

const PlaceHolder = () => (
  <Card noMargin>
    <div css={styles.container}>
      <UserPhoto loading={true} width={`10rem`} height={`10rem`} borderRadius="100%" />
      <div css={styles.name}>
        <TextLoader
          css={css`
            width: 80%;
          `}
        />
      </div>
      <div css={styles.archetype}>
        <TextLoader
          css={css`
            width: 55%;
          `}
        />
      </div>
      <div css={styles.location}>
        <TextLoader
          css={css`
            width: 65%;
          `}
        />
      </div>
      <div css={styles.archetype}>
        <TextLoader
          css={css`
            width: 55%;
          `}
        />
      </div>
      <div css={styles.experience}>
        <TextLoader
          css={css`
            width: 80%;
          `}
        />
      </div>
    </div>
  </Card>
);

const StyledCard = styled(Card)`
  height: 100%;
  padding: 2rem 1rem 0rem 1rem;
  cursor: pointer;
  z-index: 1;

  .view-profile {
    opacity: 0;
    z-index: 0;
  }

  &:hover {
    border-color: ${colors.purpleRainBase};
    box-shadow: 0 0 0 5px ${colors.purpleRainLight3};

    .view-profile {
      opacity: 1;
    }
  }
`;

const styles = {
  container: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
  `,
  primary_action: css`
    margin-bottom: 1rem;
    width: 100%;
    text-align: center;

    > * {
      width: 100%;
    }
  `,
  badge_container: css`
    align-items: center;
    justify-content: center;
  `,
  name: css`
    font-size: ${fontSize.normal};
    margin-top: 1rem;
    font-weight: 600;
    text-align: center;
    width: 100%;
  `,
  location: css`
    font-size: ${fontSize.xsmall};
    color: ${colors.grayAnatomyBase};
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-weight: 400;
    text-align: center;
    line-height: 1.25;
    width: 100%;
  `,
  experience: css`
    margin-top: 2rem;
    color: ${colors.grayAnatomyBase};
    width: 100%;
    text-align: center;
  `,
  rate: css`
    margin-top: 1rem;
    color: ${colors.grayAnatomyDark};
    width: 100%;
    text-align: center;
  `,
  archetype: css`
    font-size: ${fontSize.small};
    margin-top: 0.5rem;
    color: ${colors.grayAnatomyLight1};
    max-width: 80%;
    text-align: center;
    font-weight: 400;
    width: 100%;
  `,
  disciplines: css`
    margin-top: 1rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.4rem;
  `,
  bio: css`
    font-size: ${fontSize.small};
    margin-top: 1.5rem;
    line-height: 1.3;
    font-weight: 300;
    text-align: center;
    margin-bottom: 2rem;
  `,
  link: css`
    font-size: ${fontSize.small};
    margin-top: 1.5rem;
  `,
  badge: css`
    height: 4rem;
  `,
  button_container: css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0 0 0.9rem 0.9rem;
    background: #fff;
    padding: 1rem;
    gap: 0.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-weight: 500;
    color: ${colors.purpleRainBase};
    font-size: ${fontSize.small};

    > * {
      width: 100%;
    }
  `,
  badges_container: css`
    display: flex;
    justify-content: flex-end;
  `,
};

ProfileCard.defaultProps = {
  hideRate: true,
};

ProfileCard.propTypes = {
  data: PropTypes.object,
  actions: PropTypes.array,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  hideRate: PropTypes.bool,
  primaryAction: PropTypes.node,
};

export default ProfileCard;
