import React, { useContext } from "react";
import styled from "@emotion/styled";
import { colors, ThemeContext, fontSize } from "style";

/**
 * Heading 4
 */
const StyledH4 = styled.h4`
  font-size: ${fontSize.normal};
  font-weight: 600;
  margin: 0;
  color: ${colors.purpleRainDark2};
  border-bottom: 1px ${colors.grayAnatomyLight4} solid;
  margin-top: 1rem;
  ${(props) => props.themeStyle}
`;

const H4 = ({ ...props }) => {
  const theme = useContext(ThemeContext);

  return <StyledH4 themeStyle={theme.h1} {...props} />;
};

export default H4;
