import React, { useEffect } from "react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { usePulse } from "hooks";
import { chart_colors, colors } from "style";
import DataTable from "components/DataTable";
import { Grid } from "components/Containers";
import Card from "components/Card";
import Tabs from "components/Tabs";
import Tooltip from "components/Tooltip";
import { PULSE_MODE } from "components/Squads/Pulse";
import { UserFullName } from "components/User";
import { getMonthsBetween } from "utils";

/**
 * PulseTable
 *
 * @param {String}    teamId
 * @param {Array}     dateRange
 * @param {String}    mode
 */
const PulseTable = ({ teamId, dateRange, mode, ...props }) => {
  const [startDate, endDate] = dateRange;
  const isSquadDynamics = mode === PULSE_MODE.profile;
  const { data, loading, error, setSelectedMember, selectedMember, tabOptions, questionText } = usePulse({
    teamId,
    startDate,
    endDate,
    mode,
  });

  // Reset the selected member tab on dateRange change
  useEffect(() => {
    if (dateRange && isSquadDynamics) {
      setSelectedMember(0);
    }
  }, [dateRange]);

  return (
    <Card>
      <Grid>
        {isSquadDynamics && (
          <Grid.col start={1} end={13}>
            <Tabs data={tabOptions} selected={selectedMember} onSelect={setSelectedMember} loading={loading} />
          </Grid.col>
        )}
        <Grid.col start={1} end={13}>
          <DataTable
            css={styles.table}
            data={data}
            error={error}
            loading={loading}
            columns={COLUMN_DATA(startDate, endDate)}
            {...props}
          />
          {questionText && (
            <div css={styles.disclaimer}>
              <FormattedMessage
                id="PulseTable.Disclaimer.Text"
                values={{ question: questionText, entity: isSquadDynamics ? "teammate" : "mission" }}
              />
            </div>
          )}
        </Grid.col>
      </Grid>
    </Card>
  );
};

const COLUMN_DATA = (startDate, endDate) => [
  {
    label: <FormattedMessage id="PulseTable.Header.MemberName" />,
    cell: ({ profile }) => <UserFullName data={profile} />,
    width: "16%",
  },
  ...getMonthsBetween(startDate, endDate).map((month) => ({
    label: <FormattedMessage id={`PulseTable.Header.MonthTitle`} values={{ month }} />,
    cell: (data) => <FeedbackCell data={data[month] || {}} />,
    width: "7%",
  })),
];

/**
 * FeedbackCell
 *
 * @param {String}  data
 */
const FeedbackCell = ({ data }) => {
  const { label, value, description } = data;

  const ValueCell = () => <div css={styles.cell_container(value)}>{value}</div>;

  return description || label ? (
    <Tooltip tooltipId={description || label} hideIcon>
      <ValueCell />
    </Tooltip>
  ) : (
    <ValueCell />
  );
};

FeedbackCell.propTypes = {
  data: PropTypes.object,
};

const styles = {
  table: css`
    td,
    td:last-of-type {
      border: 0;
      padding: 0rem;
      height: 4rem;
    }
  `,
  cell_container: (value) => css`
    background: #fff;
    text-align: center;
    height: 4rem;
    line-height: 4rem;
    border-bottom: 1px rgba(255, 255, 255, 0.2) solid;
    border-right: 1px rgba(255, 255, 255, 0.2) solid;

    ${value &&
    `
      background: ${chart_colors.intensity[value - 1]};
    `}
  `,
  disclaimer: css`
    font-style: italic;
    margin-top: 2rem;
    color: ${colors.grayAnatomyBase};
  `,
};

PulseTable.propTypes = {
  teamId: PropTypes.string,
  dateRange: PropTypes.array,
  mode: PropTypes.string,
};

export default PulseTable;
