import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import { PrimaryLink } from "components/Links";
import { UserPhoto } from "components/User";
import { LINKS } from "constants/index";
import { TextLoader } from "components/Loader";
import { OrganizationPhoto } from "components/Organization";

/**
 * OrganizationCard
 *
 * @param {Object}    data
 * @param {Boolean}   loading
 */
const OrganizationCard = ({ data, loading }) => {
  if (loading) {
    return <PlaceHolder />;
  }

  return (
    <div css={styles.container}>
      <OrganizationPhoto
        url={data?.organization?.logoUrl}
        name={data?.organization?.name}
        loading={loading}
        width={`12rem`}
        height={`12rem`}
      />
      <div css={styles.name}>{data?.organization?.name}</div>
      <div css={styles.archetype}>Organization</div>
      <div css={styles.link}>
        <PrimaryLink to={LINKS.private_profile(data?.profile?.slug)} withMoreIcon>
          <FormattedMessage id="Organization.OrganizationCard.CTA" />
        </PrimaryLink>
      </div>
    </div>
  );
};

const PlaceHolder = () => (
  <div css={styles.container}>
    <UserPhoto loading={true} width={`12rem`} height={`12rem`} borderRadius="100%" />
    <div css={styles.name}>
      <TextLoader
        css={css`
          width: 25rem;
        `}
      />
    </div>
    <div css={styles.archetype}>
      <TextLoader
        css={css`
          width: 15rem;
        `}
      />
    </div>
    <div css={styles.bio}>
      <TextLoader
        css={css`
          width: 35rem;
        `}
      />
      <TextLoader
        css={css`
          width: 25rem;
        `}
      />
      <TextLoader
        css={css`
          width: 10rem;
        `}
      />
    </div>
  </div>
);

const styles = {
  container: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 3rem;
  `,
  name: css`
    font-size: ${fontSize.medium};
    margin-top: 1rem;
    font-weight: 600;
    max-width: 80%;
    text-align: center;
  `,
  archetype: css`
    font-size: ${fontSize.small};
    margin-top: 0.5rem;
    color: ${colors.grayAnatomyLight1};
    max-width: 80%;
    text-align: center;
  `,
  bio: css`
    font-size: ${fontSize.small};
    margin-top: 1.5rem;
    line-height: 1.3;
    font-weight: 300;
    max-width: 80%;
    text-align: center;
  `,
  link: css`
    font-size: ${fontSize.small};
    margin-top: 1.5rem;
  `,
};

OrganizationCard.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default OrganizationCard;
