import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import DataTable from "components/DataTable";
import { colors, fontSize, margins } from "style";
import { H3 } from "components/Text";
import { Header } from "components/DisplayBox";

/**
 * ListTable
 *
 * @params {Array}    data
 * @params {Boolean}  loading
 * @params {Object}   error
 * @params {String}   marginSize
 * @params {String}   labelKey
 * @params {String}   valueKey
 * @params {Any}      title
 * @params {Any}      secondaryTitle
 * @params {Boolean}  singleColumn
 */
const ListTable = ({
  data,
  loading,
  error,
  marginSize,
  labelKey,
  valueKey,
  title,
  secondaryTitle,
  singleColumn,
  descriptionKey,
  ...props
}) => (
  <>
    {(title || secondaryTitle) && (
      <Header marginSize={marginSize} loading={loading}>
        {title && <H3>{title}</H3>}
        {secondaryTitle}
      </Header>
    )}
    <DataTable
      data={data}
      loading={loading}
      error={error}
      columns={singleColumn ? SINGLE_COLUMN_DATA(labelKey, valueKey) : COLUMN_DATA(labelKey, valueKey, descriptionKey)}
      marginSize={marginSize}
      hideHeader
      {...props}
    />
  </>
);

const COLUMN_DATA = (labelKey, valueKey, descriptionKey) => [
  {
    label: "",
    cell: (row) => (
      <div css={styles.single_container}>
        {row[labelKey] && <div css={styles.label}>{row[labelKey]}</div>}
        {row[descriptionKey] && <div css={styles.value}>{row[descriptionKey]}</div>}
      </div>
    ),
  },
  {
    label: "",
    cellProps: {
      alignRight: true,
    },
    cell: (row) => <span css={styles.value}>{row[valueKey]}</span>,
  },
];

const SINGLE_COLUMN_DATA = (labelKey, valueKey) => [
  {
    label: "",
    cell: (row) => (
      <div css={styles.single_container}>
        <div css={styles.label}>{row[labelKey]}</div>
        <div css={styles.value}>{row[valueKey]}</div>
      </div>
    ),
  },
];

const styles = {
  label: css`
    font-size: ${fontSize.small};
    font-weight: 500;
  `,
  value: css`
    font-size: ${fontSize.small};
    white-space: pre-wrap;
    color: ${colors.grayAnatomyLight6};
  `,
  single_container: css`
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  `,
};

ListTable.defaultProps = {
  marginSize: margins.normal,
  labelKey: "label",
  valueKey: "value",
  descriptionKey: "explanation",
};

ListTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.object,
  marginSize: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  title: PropTypes.any,
  secondaryTitle: PropTypes.any,
  singleColumn: PropTypes.bool,
  descriptionKey: PropTypes.string,
};

export default ListTable;
