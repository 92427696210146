import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import debounce from "lodash.debounce";
import { DataTableProvider } from "components/DataTable";
import { PAGINATION_TYPE } from "components/Pagination";
import { useTeam, useToast } from "hooks";
import Tabs from "components/Tabs";
import Status from "components/Status";
import { TextInput } from "components/Form";
import { ICON_POSITION, ICON_SIZE, ICON_TYPE, withIcon } from "components/Icon";
import { Grid } from "components/Containers";
import { UserPhotoList } from "components/User";
import { SquadForm } from "components/Squads";
import { LINKS } from "constants";
import { GET_TEAMS } from "graphql/queries";
import { PrimaryLink } from "components/Links";
import { SQUAD_STATUS } from "constants/index";

/**
 * SquadsList
 *
 * @param {Boolean}   showForm
 * @param {Function}  setShowForm
 * @param {String}    resultsPerPage
 */
const SquadsList = ({ showForm, setShowForm, resultsPerPage }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [keyword, setKeyword] = useState();
  const [debounceKeyword, setDebounceKeyword] = useState();
  const [selectedSquad, setSelectedSquad] = useState(null);
  const { toast } = useToast();
  const { removeTeam, restoreTeam } = useTeam({ id: 0 });
  const navigate = useNavigate();
  const debouncedSetKeyword = useMemo(() => debounce((val) => setDebounceKeyword(val), 500), []);
  const handleDestroy = async (squad) => removeTeam(squad?.id);
  const handleRestore = async (squad) => restoreTeam(squad?.id);
  const onClick = (squad) => navigate(LINKS.squad_profile(squad?.slug));

  const handleComplete = () => {
    setShowForm(false);
    setSelectedSquad(null);
    toast.success(<FormattedMessage id={`Squads.SquadForm.${selectedSquad ? "Update" : "Create"}Success`} />);
  };

  const handleClose = () => {
    setShowForm(false);
    setSelectedSquad(null);
  };

  const handleSearchInputChange = (val) => {
    setKeyword(val);
    debouncedSetKeyword(val);
  };

  const rowOptions = (row) =>
    [
      {
        label: <FormattedMessage id="Global.Edit" />,
        icon: ICON_TYPE.edit,
        onClick: () => setSelectedSquad(row),
      },
      ...[
        row?.versionStatus === SQUAD_STATUS.inactive && {
          label: <FormattedMessage id="Global.Archive" />,
          icon: ICON_TYPE.trash,
          onClick: () => handleDestroy(row),
        },
      ],
      ...[
        row?.status === SQUAD_STATUS.archived && {
          label: <FormattedMessage id="Global.Restore" />,
          icon: ICON_TYPE.plus,
          onClick: () => handleRestore(row),
        },
      ],
    ].filter(Boolean);

  useEffect(() => {
    if (selectedSquad) {
      setShowForm(true);
    }
  }, [selectedSquad]);

  return (
    <>
      <Grid>
        <Grid.col start={1} end={7}>
          <Tabs
            data={TAB_OPTIONS.map(({ name }) => name)}
            selected={selectedTab}
            onSelect={setSelectedTab}
            name="type"
            withUrlParams
          />
        </Grid.col>
        <Grid.col start={7} end={13}>
          <SearchInput
            value={keyword}
            onChange={(name, val) => handleSearchInputChange(val)}
            type="search"
            placeholder="Search by squad or organization name"
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <DataTableProvider
            keyName={"teams"}
            queryName={GET_TEAMS}
            variables={{
              filters: {
                search: debounceKeyword,
                ...(TAB_OPTIONS[selectedTab].status ? { status: TAB_OPTIONS[selectedTab].status } : {}),
                ...(TAB_OPTIONS[selectedTab].versionStatus
                  ? { versionStatus: TAB_OPTIONS[selectedTab].versionStatus }
                  : {}),
              },
              limit: null,
            }}
            resultsPerPage={resultsPerPage}
            columns={COLUMN_DATA(onClick)}
            paginationType={PAGINATION_TYPE.classic}
            selectable={false}
            rowOptions={rowOptions}
            paginationProps={{
              countMessage: "Global.Squads.Count",
            }}
          />
        </Grid.col>
      </Grid>
      <SquadForm show={showForm} onClose={handleClose} onComplete={handleComplete} squad={selectedSquad} />
    </>
  );
};

const isActive = (status, versionStatus) => status === SQUAD_STATUS.active && versionStatus === SQUAD_STATUS.active;

const COLUMN_DATA = (onClick) => [
  {
    label: <FormattedMessage id="Squads.TableColumn.SquadName" />,
    cell: (row) => row?.name,
    width: "25%",
    onClick,
  },
  {
    label: <FormattedMessage id="Squads.TableColumn.SquadType" />,
    cell: (row) => <FormattedMessage id={`Squads.${row?.teamType}`} />,
    width: "10%",
  },
  {
    label: <FormattedMessage id="Squads.TableColumn.Status" />,
    cell: (row) => (
      <Status enabled={isActive(row?.status, row?.versionStatus)}>
        <FormattedMessage id={`Squads.${row?.versionStatus || row?.status}`} />
      </Status>
    ),
    width: "15%",
  },
  {
    label: <FormattedMessage id="Squads.TableColumn.OrganizationName" />,
    cell: ({ organization }) =>
      organization ? (
        <PrimaryLink to={LINKS.organization_profile(organization?.slug)} routerEnabled>
          {organization?.name}
        </PrimaryLink>
      ) : (
        "-"
      ),
    width: "25%",
  },
  {
    label: <FormattedMessage id="Squads.TableColumn.TeamMembers" />,
    cell: (row) => (
      <UserPhotoList
        key={row?.id}
        users={row?.assignments}
        thumbCount={row?.assignments?.length}
        thumbLimit={5}
        thumbSize="4rem"
        thumbBorderRadius="100%"
      />
    ),
    width: "25%",
  },
];

/**
 * Backend accepts the following filters' combinations to differentiate between 3 listings:
 * 1. Active Listing = Team Status - Active AND Version Status - Active
 * 2. Inactive Listing = Team Status - Active AND Version Status - Inactive
 * 3. Archived Listing = Team Status - Archived
 */
const TAB_OPTIONS = [
  {
    name: "All",
  },
  {
    name: "Billing Active",
    status: SQUAD_STATUS.active,
    versionStatus: SQUAD_STATUS.active,
  },
  {
    name: "Billing Inactive",
    status: SQUAD_STATUS.active,
    versionStatus: SQUAD_STATUS.inactive,
  },
  {
    name: "Archived",
    status: SQUAD_STATUS.archived,
  },
];

const SearchInput = withIcon(TextInput, {
  type: ICON_TYPE.search,
  size: ICON_SIZE.medium,
  position: ICON_POSITION.overlay,
  order: 0,
});

SquadsList.defaultProps = {
  resultsPerPage: 30,
  showForm: false,
};

SquadsList.propTypes = {
  showForm: PropTypes.bool,
  setShowForm: PropTypes.func,
  resultsPerPage: PropTypes.number,
};

export default SquadsList;
