import React from "react";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { colors, margins } from "style";
import { useInterviewResponses } from "hooks";
import { GET_INTERVIEWS, GET_INTERVIEW_RESPONSES } from "graphql/queries";
import { HIREFLIX_INVITE_CANDIDATE } from "graphql/mutations";
import DisplayBox, { RENDER_SIZE, RenderIconWithText } from "components/DisplayBox";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { SIZE, TYPE } from "components/Form";
import { MODAL_SIZE, withModalForm } from "components/Modal";

/**
 * ProfileAdminVideoInterview
 *
 * @params {String}   profileId
 * @params {Boolean}  isActiveMember
 */
const ProfileAdminVideoInterview = ({ profileId, isActiveMember, ...props }) => {
  const { data, loading } = useInterviewResponses({ filters: { profileId } });
  const canInvite = isActiveMember && data?.length === 0 && !loading;

  return (
    <>
      <DisplayBox
        id="VideoInterview"
        title={<FormattedMessage id="Candidate.CandidateInterview.Title" />}
        emptyMsg={<FormattedMessage id="Candidate.CandidateInterview.Empty" />}
        editButtonText={<FormattedMessage id="Candidate.CandidateInterview.Invite" />}
        loading={loading}
        isEmpty={data?.length === 0 || loading}
        isEditable={canInvite}
        numColumns={1}
        parentKeyName={"profileId"}
        parentKeyValue={profileId}
        marginSize={margins.normal}
        data={data}
        itemRenderer={(data, index) => {
          const interviewCompleted = data?.status == "completed";

          return (
            <RenderIconWithText
              key={index}
              loading={loading}
              size={RENDER_SIZE.large}
              icon={<Icon type={ICON_TYPE.video} size={ICON_SIZE.xxlarge} color={colors.purpleRainDark2} />}
              text={{
                title: data?.interview?.name,
                link: (
                  <FormattedMessage
                    id={
                      interviewCompleted
                        ? `Candidate.CandidateInterview.AdminLink`
                        : `Candidate.CandidateInterview.AdminIncompleteLink`
                    }
                  />
                ),
                message: <FormattedMessage id={`Candidate.CandidateInterview.AdminLink`} />,
              }}
              hasLink={true}
              onClick={() => {
                if (interviewCompleted) {
                  window.open(data.privateUrl, "_blank");
                }
              }}
              status={{
                showIcon: interviewCompleted,
                success: interviewCompleted,
              }}
            />
          );
        }}
        {...props}
      />
    </>
  );
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        type: TYPE.select,
        size: SIZE.xlarge,
        label: "Select a Video Interview",
        properties: {
          name: "interviewId",
          required: true,
          queryName: GET_INTERVIEWS,
          queryKeyName: "interviews",
          queryFieldName: "interviews",
          labelKey: "name",
          valueKey: "id",
        },
      },
    ],
  },
];

const FORM_SETTINGS = {
  size: MODAL_SIZE.medium,
  data: FORM_DATA,
  title: "Video Interview Invitation",
  mutationData: {
    mutationName: {
      add: HIREFLIX_INVITE_CANDIDATE,
      update: HIREFLIX_INVITE_CANDIDATE,
    },
    refetchQueries: [GET_INTERVIEW_RESPONSES],
    keyName: "interviewResponses",
    refetchAfterMutate: true,
  },
  primaryButtonMessageId: "Global.Invite",
  onCompleted: () => toast.success(<FormattedMessage id="Candidate.CandidateInterview.Admin.InviteSuccess" />),
};

ProfileAdminVideoInterview.propTypes = {
  profileId: PropTypes.string.isRequired,
  isActiveMember: PropTypes.bool.isRequired,
};

export default withModalForm(ProfileAdminVideoInterview, FORM_SETTINGS);
