import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Card from "components/Card";
import Status from "components/Status";
import { DataTableProvider } from "components/DataTable";
import { EventForm } from "components/Events";
import { GET_EVENTS } from "graphql/queries";
import { ICON_TYPE } from "components/Icon";
import { useEvents } from "hooks";
import { PAGINATION_TYPE } from "components/Pagination";

/**
 * Mission Event List
 *
 * @param {Number} resultsPerPage
 */
const MissionEventList = ({ resultsPerPage }) => {
  const { handleUpdateMissionEvent, keyName, paginationType } = useEvents({
    skip: true,
    paginationType: PAGINATION_TYPE.classic,
  });
  const [showForm, setShowForm] = useState(false);
  const [selected, setSelected] = useState(false);

  const rowOptions = (row) => [
    {
      label: <FormattedMessage id={`Events.MissionEventRowAdvancedOptions.${row.hidden ? `Enable` : `Disable`}`} />,
      icon: row.hidden ? ICON_TYPE.checkDotted : ICON_TYPE.close,
      onClick: () => handleUpdateMissionEvent(row.id, { hidden: !row.hidden }),
    },
    {
      label: <FormattedMessage id={"Events.MissionEventRowAdvancedOptions.LabelUpdate"} />,
      icon: ICON_TYPE.edit,
      onClick: () => {
        setSelected(row);
        setShowForm(true);
      },
    },
  ];

  return (
    <>
      <Card>
        <DataTableProvider
          columns={COLUMN_DATA}
          resultsPerPage={resultsPerPage}
          rowOptions={rowOptions}
          keyName={keyName}
          queryName={GET_EVENTS}
          paginationType={paginationType}
          selectable={false}
          paginationProps={{
            countMessage: "Global.Events.Count",
          }}
        />
      </Card>
      <EventForm show={showForm} onClose={() => setShowForm(false)} isEditing data={selected} />
    </>
  );
};

const COLUMN_DATA = [
  {
    label: "Name",
    cell: (row) => row?.name,
    width: "25%",
  },
  {
    label: "Title",
    cell: (row) => row?.title,
    width: "25%",
  },
  {
    label: "Link",
    cell: (row) => row?.url,
    width: "20%",
  },
  {
    label: "Status",
    width: "15%",
    cell: ({ hidden }) => (
      <div>
        <Status enabled={!hidden}>
          <FormattedMessage id={`Events.MissionEvents.${hidden ? `Disabled` : `Enabled`}`} />
        </Status>
      </div>
    ),
  },
];

MissionEventList.propTypes = {
  resultsPerPage: PropTypes.number,
};

MissionEventList.defaultProps = {
  resultsPerPage: 10,
};

export default MissionEventList;
