import { GET_TEAM_INSIGHTS } from "graphql/queries";
import { useCallback, useEffect, useState } from "react";
import { useQueryData } from "hooks";
import { SQUAD_INSIGHTS_PER_PAGE } from "constants/index";

export default function useSquadInsights({
  teamId,
  currentPage = 1,
  resultsPerPage = SQUAD_INSIGHTS_PER_PAGE,
  readUrlPagination = false,
  paginationType,
  types,
  metrics,
  profileIds,
}) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(currentPage);
  const [perPage, setPerPage] = useState(resultsPerPage);

  const handlePageChange = useCallback((newPage, newPerPage) => {
    setPage(parseInt(newPage));
    setPerPage(parseInt(newPerPage));
  }, []);

  const {
    hasNextPage,
    handleNextPage,
    hasPreviousPage,
    handlePreviousPage,
    loading,
    loadingMore,
    data: response,
    error,
  } = useQueryData({
    queryName: GET_TEAM_INSIGHTS,
    keyName: "teamInsights",
    skip: !teamId,
    variables: {
      teamId,
      paginationInfo: {
        perPage,
        page,
      },
      filters: {
        ...(types?.length > 0 && { types: types.map((type) => type.value) }),
        ...(metrics?.length > 0 && { metrics: metrics.map((metric) => metric.value) }),
        ...(profileIds?.length > 0 && { profileIds: profileIds.map((profile) => profile.id) }),
      },
    },
    paginationType,
    readUrlPagination,
  });

  useEffect(() => {
    if (response?.teamInsights) {
      setData(response?.teamInsights);
    }
  }, [response]);

  return {
    loading,
    loadingMore,
    data: data?.nodes,
    error,
    hasNextPage,
    hasPreviousPage,
    handleNextPage,
    handlePreviousPage,
    handlePageChange,
    pageInfo: data?.pageInfo,
    totalCount: data?.totalCount,
    currentPage: data?.currentPage,
    resultsPerPage: data?.perPage,
  };
}
