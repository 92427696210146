import React from "react";
import { FormattedMessage } from "react-intl";
import { useOutletContext } from "react-router";
import { useConnection } from "hooks";
import { Grid, PageWrapper } from "components/Containers";
import PageTitle from "components/PageTitle";
import { ScopedRepositoriesForm, SignalInstallation, SignalStatus } from "components/Squads/settings";
import Error from "components/Messages/Error";
import { Spinner } from "components/Loader";

/**
 * Signal
 */
const Signal = ({ ...props }) => {
  const { selectedSquad, loading: squadLoading } = useOutletContext();
  const connectionId = selectedSquad?.organization?.githubApplicationConnection?.id;
  const { data, loading, error } = useConnection({ id: connectionId, shouldSkip: !connectionId });

  if (error) {
    return <Error />;
  }

  return (
    <>
      <PageWrapper {...props}>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle
              subtitle={<FormattedMessage id="Squads.Settings.SignalInstallation.Title" />}
              actionBar={<SignalStatus data={selectedSquad} githubApplicationConnection={data} loading={loading} />}
            />
          </Grid.col>
          {squadLoading ? (
            <Grid.col start={1} end={13}>
              <Spinner size="1.5rem" />
            </Grid.col>
          ) : (
            <>
              <Grid.col start={1} end={13}>
                <SignalInstallation
                  data={selectedSquad}
                  githubApplicationConnection={connectionId ? data : null}
                  loading={loading}
                />
              </Grid.col>
              <Grid.col start={1} end={13}>
                <ScopedRepositoriesForm
                  data={selectedSquad}
                  githubApplicationConnection={connectionId ? data : null}
                  loading={loading}
                />
              </Grid.col>
            </>
          )}
        </Grid>
      </PageWrapper>
    </>
  );
};

export default Signal;
