import React from "react";
import propTypes from "prop-types";
import { CONNECTION_STATES } from "constants/index";
import { Row } from "components/Containers";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import styled from "@emotion/styled";
import { colors, fontSize } from "style";
import { FormattedMessage } from "react-intl";

/**
 * SignalNotice
 *
 * @param {Object} teamData
 */
const SquadSignalNotice = ({ teamData }) => {
  const githubApplicationConnection = teamData?.organization?.githubApplicationConnection;
  const hasRepos = teamData?.gitRepositories && teamData.gitRepositories.split(",").length > 0;

  if (!githubApplicationConnection || githubApplicationConnection?.status === CONNECTION_STATES.suspended) {
    return (
      <Message>
        <IconContainer isError>
          <Icon type={ICON_TYPE.close} size={ICON_SIZE.small} color="inherit" />
        </IconContainer>
        <FormattedMessage id="Squads.SquadSignalNotice.Error" />
      </Message>
    );
  } else if (!hasRepos) {
    return (
      <Message>
        <IconContainer>
          <Icon type={ICON_TYPE.checkmark} size={ICON_SIZE.small} color="inherit" />
        </IconContainer>
        <FormattedMessage id="Squads.SquadSignalNotice.Warning" />
      </Message>
    );
  } else {
    return (
      <Message>
        <IconContainer isSuccess>
          <Icon type={ICON_TYPE.checkmark} size={ICON_SIZE.small} color="inherit" />
        </IconContainer>
        <FormattedMessage id="Squads.SquadSignalNotice.Success" />
      </Message>
    );
  }
};

const Message = styled(Row)`
  color: ${colors.grayAnatomyBase};
  font-size: ${fontSize.xsmall};
  padding-right: 1rem;
  border-right: 1px solid ${colors.grayAnatomyLight3};
`;

const IconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ isSuccess, isError }) =>
    isSuccess ? colors.lightGreen : isError ? colors.lightRed : colors.lightYellow};
  color: ${({ isSuccess, isError }) => (isSuccess ? colors.green : isError ? colors.red : colors.darkYellow)};
`;

SquadSignalNotice.propTypes = {
  teamData: propTypes.object.isRequired,
};

export default SquadSignalNotice;
