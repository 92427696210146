/**
 * Format a currency
 *
 * @param {Number}     amount
 * @param {String}    currency
 *
 * @returns {String} The formatted currency
 */
export default function formatCurrency(amount, currency = "USD", currencyDisplay = "code", abbreviate = false) {
  return Intl.NumberFormat("en-US", {
    style: "currency",
    notation: abbreviate ? "compact" : "standard",
    currency: currency || "USD",
    currencyDisplay: currencyDisplay || "code", // symbol, narrowSymbol, code, name (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat)
  }).format(amount);
}
