import React, { useState } from "react";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { css } from "@emotion/react";

/**
 * Overlay
 *
 * @param {Object} overlay
 * @param {Object} trigger
 * @param {Boolean} asCardOverlay
 * @param {String} placement
 */
const Overlay = ({ overlay, trigger, asCardOverlay, placement, width, ...props }) => {
  const [show, setShow] = useState(false);
  const [isOverOverlay, setIsOverOverlay] = useState(false);
  let timer = null;

  const handleLeave = () => {
    timer = setTimeout(() => {
      setIsOverOverlay(false);
      setShow(false);
    }, 1000);
  };

  const overlayProps = asCardOverlay
    ? {
        onMouseEnter: () => {
          clearInterval(timer);
          setIsOverOverlay(true);
          setShow(true);
        },
        onMouseLeave: handleLeave,
        css: css`
          width: ${width};
          box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.1);
          border-radius: 1rem;
          margin-bottom: 2rem;
        `,
      }
    : {};

  const handleToggle = (val) => {
    if (!asCardOverlay) {
      setShow(val);
    } else if (val) {
      setShow(true);
    } else if (!isOverOverlay) {
      setShow(false);
    }
  };

  return (
    <OverlayTrigger
      placement={placement}
      overlay={<div {...overlayProps}>{overlay}</div>}
      onToggle={handleToggle}
      show={show}
    >
      {({ ref, ...triggerHandler }) => (
        <span ref={ref} {...triggerHandler} {...props}>
          {trigger}
        </span>
      )}
    </OverlayTrigger>
  );
};

Overlay.propTypes = {
  overlay: PropTypes.node.isRequired,
  trigger: PropTypes.node.isRequired,
  asCardOverlay: PropTypes.bool,
  placement: PropTypes.string,
};

Overlay.defaultProps = {
  asCardOverlay: false,
  placement: "auto",
  width: "40rem",
};

export default Overlay;
