import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { FormattedMessage } from "react-intl";
import { useSquadDynamics } from "hooks";
import { Header } from "components/DisplayBox";
import { H3 } from "components/Text";
import Tooltip from "components/Tooltip";
import { colors } from "style";
import { ARCHETYPES } from "constants";
import Legend from "components/Legend";
import { Spinner } from "components/Loader";
import NoResults from "components/Messages/NoResults";

/**
 * SquadDynamics
 *
 * @param {Array}     data
 * @param {Boolean}   loading
 */
const SquadDynamics = ({ data, loading: loadingSquad, ...props }) => {
  const container = useRef(null);
  const { data: affinities, loading } = useSquadDynamics({
    profileIds: data?.assignments?.map((item) => item?.profile?.id),
  });
  const [formattedData, setFormattedData] = useState([]);

  /**
   * @description Format the data array
   */
  useEffect(() => {
    if (loading || !affinities || affinities?.length === 0) return;

    setFormattedData(
      affinities?.map((item) => ({
        name: item?.profile?.firstName,
        profile: item?.profile,
        value: 10,
        archetype: item?.profile?.archetype,
        archetypeName: ARCHETYPES[String(item?.profile?.archetype).toLowerCase()]?.name,
        link: item?.affinities?.map((affinity) => affinity?.profile?.firstName),
        linkWidths: item?.affinities?.reduce((acc, item) => ({ ...acc, [item?.profile?.firstName]: item?.value }), {}),
      }))
    );
  }, [data, affinities, loading]);

  /**
   * @description Draw the chart
   */
  useLayoutEffect(() => {
    if (loading || !formattedData || formattedData.length === 0 || container.current) return;

    container.current = true;
    const containerWidth = document.getElementById("chart_container").offsetWidth || 500;
    const size = { width: containerWidth * 0.75, height: 400 };
    const chartContainer = d3.select(".bar-chart").append("svg").attr("width", size.width).attr("height", size.height);
    const chart = chartContainer.append("defs").attr("id", "imgdefs");
    const center = { x: size.width / 2, y: size.height / 2 };
    const margin = { top: 20, right: 20, bottom: 20, left: 20 };
    const orbitRadius = center.y - margin.top - margin.bottom;
    const nodeRadius = 50;
    const numNodes = formattedData?.length || 0;
    const nodeData = [];
    const arcLen = ((2 * Math.PI) / numNodes) * orbitRadius;
    const minNodeRadius = Math.min((arcLen / 2) * 0.8, nodeRadius);
    const scaleFactor = minNodeRadius / nodeRadius;

    if (numNodes === 0) return;

    /**
     * @description Create the nodes array
     */
    formattedData.forEach((item, index) => {
      let rad = ((2 * Math.PI) / numNodes) * index;
      let cx = size.width / 2 + orbitRadius * Math.sin(rad);
      let cy = size.height / 2 - orbitRadius * Math.cos(rad);
      let id = `n${index}`;
      let radius = 40;

      nodeData.push({ ...item, id, cx, cy, radius });
    });

    /**
     * @description Draw the orbit
     */
    chartContainer
      .append("circle")
      .attr("cx", center.x)
      .attr("cy", center.y)
      .attr("r", orbitRadius)
      .attr("stroke", colors.grayAnatomyLight2)
      .attr("fill", "none")
      .attr("stroke-width", 2)
      .attr("stroke-dasharray", "10, 5");

    /**
     * @description Draw the links between nodes
     */
    nodeData.forEach((node) => {
      nodeData.forEach((related) => {
        chartContainer
          .append("line")
          .attr("id", `n${node.id}-n${related.id}`)
          .attr("stroke", getLinkColor(related?.linkWidths?.[node?.name]))
          .attr("stroke-width", 3)
          .attr("x1", node?.cx)
          .attr("y1", node?.cy)
          .attr("x2", related?.cx)
          .attr("y2", related?.cy)
          .style("opacity", 0.1);
      });
    });

    /**
     * @description Draw the nodes
     */
    nodeData.forEach((node) => {
      const archetype = Object.keys(ARCHETYPES).find((key) => ARCHETYPES[key].id === node.archetype);
      const color = getColor(ARCHETYPES[archetype]?.id)?.color;
      const graphNode = chartContainer.append("g");

      graphNode.attr("id", node.id);

      chart
        .append("pattern")
        .attr("id", `imgpattern${node?.profile?.id}`)
        .attr("width", 2)
        .attr("height", 2)
        .attr("x", "0")
        .attr("y", "0")
        .append("image")
        .attr("x", -2)
        .attr("y", -2)
        .attr("width", 80)
        .attr("height", 80)
        .attr("preserveAspectRatio", "xMidYMid slice")
        .attr("xlink:href", node?.profile?.avatarUrl);

      graphNode.append("circle").attr("r", node.radius).style("stroke", "white").style("fill", color);

      graphNode
        .append("circle")
        .attr("r", node.radius * 0.85)
        .style("stroke", "white")
        .attr("stroke-width", 2)
        .attr("fill", `url(#imgpattern${node?.profile?.id})`);

      graphNode.attr("transform", `translate(${node.cx}, ${node.cy}) scale(${scaleFactor})`);

      graphNode.on("mouseover", function () {
        let nodeId = this.id;
        d3.selectAll("line")
          .filter(function () {
            return this.id.match(`${nodeId}`);
          })
          .style("opacity", 1.0);
        d3.select(this).select("circle").style("fill", colors.green);
      });

      graphNode.on("mouseout", function () {
        let nodeId = this.id;
        d3.selectAll("line")
          .filter(function () {
            return this.id.match(`${nodeId}`);
          })
          .style("opacity", 0.1);
        d3.select(this).select("circle").style("fill", color);
      });
    });
  }, [formattedData, loading]);

  return (
    <>
      <Header {...props}>
        <H3>
          <Tooltip tooltipId="Squads.SquadDynamics.Title.Tooltip">
            <FormattedMessage id="Squads.SquadDynamics.Title" />
          </Tooltip>
        </H3>
      </Header>
      <Legend data={Object.keys(ARCHETYPES)?.map((key) => getColor(ARCHETYPES[key].id))} />
      <ChartCanvas id="chart_container" className="bar-chart">
        {!loading && !loadingSquad && (!formattedData?.length || formattedData?.length === 0) && (
          <NoResults
            title={!data?.gitRepositories ? "Squads.NoReposTitle" : "Squads.NoActivityTitle"}
            description={!data?.gitRepositories ? "Squads.NoReposDescription" : "Squads.NoActivityDescription"}
          />
        )}
        {(!data || loading) && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
      </ChartCanvas>
    </>
  );
};

/**
 * @description Returns an object with the color and name of the archetype
 *
 * @param {String} archetype
 * @returns {Object}
 */
function getColor(archetype) {
  switch (archetype) {
    case ARCHETYPES.tech_lead.id:
      return {
        name: ARCHETYPES.tech_lead.name,
        color: colors.purpleRainBase,
      };

    case ARCHETYPES.squad_lead.id:
      return {
        name: ARCHETYPES.squad_lead.name,
        color: colors.pinkPantherBase,
      };

    default:
      return {
        name: ARCHETYPES.contributor.name,
        color: colors.orangeCountyBase,
      };
  }
}

function getLinkColor(value) {
  const normalizedStrength = parseFloat(value) * 100;

  if (normalizedStrength < 25) {
    return colors.red;
  } else if (normalizedStrength < 50) {
    return colors.yellow;
  } else {
    return colors.green;
  }
}

const ChartCanvas = styled.div`
  background: ${colors.white};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 42rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

SquadDynamics.defaultProps = {
  loading: true,
};

SquadDynamics.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default SquadDynamics;
