import { css } from "@emotion/react";
import { colors } from "./colors";

export default css`
  html {
    font-size: 10px;
  }

  html,
  body {
    min-width: 300px;
    font-family: Matter;
  }

  body {
    font-size: 1.4rem;
    background: #f9f9f9;
  }

  body,
  button {
    font-variant-ligatures: none;
  }

  hr {
    background: ${colors.grayAnatomyLight4};
    opacity: 1;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: Matter;
  }

  p {
    margin-top: 1rem;
  }

  .modal .modal-dialog .modal-content .modal-body {
    @media only screen and (max-width: 1000px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .modal .modal-dialog .modal-content .modal-header .modal-title {
    @media only screen and (max-width: 1000px) {
      padding-left: 2rem;
    }
  }

  .modal .modal-dialog .modal-content .modal-footer {
    @media only screen and (max-width: 1000px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
`;
/*
  body .mobile-message {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background: linear-gradient(345deg, rgb(48, 48, 73) 63.45%, #393667);

    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30rem;
      margin-left: -15rem;
      margin-top: -2rem;
      max-width: 100%;
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
      padding: 1.5rem;
      border-radius: 0.6rem;
      text-align: center;
    }

    @media only screen and (max-width: 1199px) {
      display: block;
    }
  }
  */
