import React from "react";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { formatCurrency, formatDate, formatHours, getMonthFromDate, getYearFromDate } from "utils";
import { DataTableProvider } from "components/DataTable";
import { UserFullName } from "components/User";
import { PAGINATION_TYPE } from "components/Pagination";
import { DEEL_TASK_STATUS, LINKS } from "constants/index";
import { TimeCardDetails, OnboardedOffboardedDates, DeelTaskStatus, TimeCardReopenForm } from "components/TimeCard";
import { PrimaryLink } from "components/Links";
import { Grid, Row } from "components/Containers";
import { useAuth, useTimeCard } from "hooks";
import { GET_TIME_SHEETS } from "graphql/queries";
import Status from "components/Status";
 
/**
 * TimeCardList
 *
 * @param {object}   filters
 * @param {Number}   resultsPerPage
 * @param {Object}   selectedDate
 * @param {Function} setTotalCount
 * @param {Function} setSubmittedCount
 * @param {Function} onLoading
 */
const TimeCardList = ({ filters, resultsPerPage, selectedDate, setTotalCount, setSubmittedCount, onLoading }) => {
  const { isPartner } = useAuth();
  const { getAdvancedOptions, showReopenForm, setShowReopenForm, selected } = useTimeCard();

  const headerOptions = (selected, data) =>
    getAdvancedOptions({
      listData: data,
      selection: selected,
      isBatch: true,
    });

  const rowOptions = (row) =>
    !isPartner &&
    getAdvancedOptions({
      data: row,
      month: getMonthFromDate(selectedDate, "M"),
      year: getYearFromDate(selectedDate),
      isBatch: false,
    });

  return (
    <>
      <Grid>
        <Grid.col start={1} end={13}>
          <DataTableProvider
            queryName={GET_TIME_SHEETS}
            keyName="timeSheets"
            resultsPerPage={resultsPerPage}
            setTotalCount={(total, data) => {
              if (total) {
                setTotalCount(total);
              }

              if (data?.timeSheets?.submittedCount) {
                setSubmittedCount(data?.timeSheets?.submittedCount);
              }
            }}
            filterFunction={(data) =>
              // The id for time report needs to exist at root level
              data?.map((item) => ({ ...item, ...{ id: item?.timeCardReport?.id } }))
            }
            fetchPolicy={"network-only"}
            variables={{
              month: String(filters.month),
              year: String(filters.year),
              orderBy: { profileName: "asc" },
              filters: {
                teamId: filters.teamId,
                search: filters.search,
                status: filters.status,
                overAllocatedHours: filters.overAllocatedHours,
                withOnboardedOffboardedDates: filters.withOnboardedOffboardedDates,
              },
            }}
            rowOptions={rowOptions}
            headerOptions={headerOptions}
            columns={COLUMNS(selectedDate)}
            expandable={true}
            expandableKeyName={`timeCardReport.timeSheetsTotalPerTeam`}
            expandableColumns={EXPANDABLE_COLUMNS(selectedDate)}
            paginationType={PAGINATION_TYPE.classic}
            selectable={true}
            clearable={false}
            onLoading={onLoading}
          />
        </Grid.col>
      </Grid>
      <TimeCardReopenForm data={selected} show={showReopenForm} setShow={setShowReopenForm} />
    </>
  );
};

const EXPANDABLE_COLUMNS = (selectedDate) => [
  {
    cell: (row) => <PrimaryLink to={LINKS.squad_profile(row.team.slug)}>{row.team.name}</PrimaryLink>,
  },
  {
    cell: (row) => formatHours(row.totalLoggedMinutes),
  },
  {
    cell: (row) => formatHours(row.allocatedMinutes),
  },
  {
    cell: (row) => formatHours(parseFloat(row.allocatedMinutes) - parseFloat(row.totalLoggedMinutes)),
  },
  {
    cell: (row) => formatCurrency(row.rate.amount, row.rate.currency.isoCode),
  },
  {
    cell: (row) => (
      <Row css={styles.centered_row}>
        <DeelTaskStatus status={DEEL_TASK_STATUS[row.deelStatus]} />
      </Row>
    ),
  },
  {
    cell: (row) => (
      <OnboardedOffboardedDates
        onboardedDate={row.onboardedAt}
        offboardedDate={row.offboardedAt}
        selectedDate={selectedDate}
      />
    ),
    cellProps: {
      colSpan: 4,
      alignRight: true,
    },
  },
];

const COLUMNS = (selectedDate) => [
  {
    label: <FormattedMessage id="TimeCard.TimeCardTable.Name" />,
    width: `20%`,
    cell: (row) => (
      <UserFullName data={row} withPhoto photoProps={{ width: "2rem", height: "2rem", borderRadius: "100%" }} />
    ),
  },
  {
    label: <FormattedMessage id="TimeCard.TimeCardTable.Logged" />,
    width: 80,
    cell: (row) => formatHours(row?.timeCardReport.loggedMinutes),
  },
  {
    label: <FormattedMessage id="TimeCard.TimeCardTable.Allocated" />,
    width: 80,
    cell: (row) => formatHours(row?.timeCardReport.allocatedMinutes),
  },
  {
    label: <FormattedMessage id="TimeCard.TimeCardTable.Difference" />,
    width: 80,
    cell: (row) => formatHours(row?.timeCardReport.differenceMinutes),
  },
  {
    label: <FormattedMessage id="TimeCard.TimeCardTable.Rate" />,
    width: 80,
    cell: () => "-",
  },
  {
    label: <FormattedMessage id="TimeCard.TimeCardTable.Status" />,
    width: `10%`,
    cell: (row) => (
      <Status enabled={row?.timeCardReport.status}>
        {row?.timeCardReport.status ? <FormattedMessage id={`TimeCardStatus.${row?.timeCardReport.status}`} /> : "-"}
      </Status>
    ),
  },
  {
    label: <FormattedMessage id="TimeCard.TimeCardTable.LastEntry" />,
    width: `10%`,
    cell: (row) => (row?.timeCardReport.lastEntry ? formatDate(row?.timeCardReport.lastEntry) : "-"),
  },
  {
    label: <FormattedMessage id="TimeCard.TimeCardTable.DaysOff" />,
    width: `10%`,
    cell: (row) => row?.timeCardReport.daysOffCount || "-",
  },
  {
    cellProps: {
      alignRight: true,
    },
    width: `20%`,
    cell: (row) => (
      <Row css={styles.row}>
        <TimeCardDetails id={row.uuid} month={selectedDate} data={row} />
      </Row>
    ),
  },
];

const styles = {
  row: css`
    justify-content: flex-end;
  `,
  centered_row: css`
    justify-content: center;
  `,
};

TimeCardList.propTypes = {
  filters: PropTypes.object,
  resultsPerPage: PropTypes.number,
  selectedDate: PropTypes.object,
  setTotalCount: PropTypes.func,
  setSubmittedCount: PropTypes.func,
  onLoading: PropTypes.func,
};

TimeCardList.defaultProps = {
  resultsPerPage: 10,
};

export default TimeCardList;
