import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useMissionRoles, useAuth } from "hooks";
import { PrimaryLink } from "components/Links";
import { LINKS } from "constants/index";
import { Row, Grid, Col } from "components/Containers";
import { RoleCard, RoleForm, RoleLink } from "components/Missions/Roles";
import { useInView } from "react-intersection-observer";
import { DataTableProvider } from "components/DataTable";
import Card, { CardList } from "components/Card";
import { formatCurrency } from "utils";
import Tags from "components/Tags";
import { TUTORIAL_MISSIONS_KEYNAME, TutorialMissions } from "components/Tutorials";
import Status from "components/Status";
import { PAGINATION_TYPE } from "components/Pagination";
import { TAG_COLORS, TAG_SIZE } from "components/Tags/Tag";

/**
 * RolesList
 *
 * @param {Boolean}   isCardView
 * @param {String}    missionId
 * @param {Object}    filters
 * @param {Number}    resultsPerPage
 * @param {Function}  onSelect
 * @param {Array}     selectionInitialState
 * @param {Boolean}   hideTutorial
 */
const RolesList = ({
  isCardView,
  missionId,
  filters,
  resultsPerPage,
  onSelect,
  selectionInitialState,
  hideTutorial,
}) => {
  const { ref, inView } = useInView();
  const { preferences, isClient, isShowcase } = useAuth();
  const [showTutorial, setShowTutorial] = useState(false);
  const {
    loading,
    loadingMore,
    data,
    error,
    hasNextPage,
    handleNextPage,
    hasPreviousPage,
    handlePreviousPage,
    showForm,
    setShowForm,
    selectedRole,
    getAdvancedOptions,
  } = useMissionRoles({
    missionId,
    resultsPerPage,
    filters,
  });

  useEffect(() => {
    setShowTutorial(preferences?.[TUTORIAL_MISSIONS_KEYNAME] === false ? false : true);
  }, [preferences?.[TUTORIAL_MISSIONS_KEYNAME]]);

  useEffect(() => {
    if (inView && hasNextPage && !loading && !loadingMore) {
      handleNextPage();
    }
  }, [inView]);

  return (
    <>
      {isCardView && (
        <CardList
          loading={loading || loadingMore}
          data={data}
          resultsPerPage={resultsPerPage}
          cols={2}
          messageProps={{
            description: `Missions.Roles.RolesList.${isClient || isShowcase ? `ClientEmpty` : `Empty`}`,
          }}
          renderer={RoleCard}
        >
          {!loading && showTutorial && !hideTutorial && (
            <Grid.col css={styles.col}>
              <TutorialMissions handleToggle={setShowTutorial} />
            </Grid.col>
          )}
        </CardList>
      )}
      {!isCardView && (
        <Card>
          <DataTableProvider
            data={data}
            error={error}
            loading={loading || loadingMore}
            resultsPerPage={resultsPerPage}
            columns={COLUMN_DATA}
            onSelect={onSelect}
            selectionInitialState={selectionInitialState}
            selectable
            rowOptions={(data) => getAdvancedOptions(data)}
            headerOptions={(selection) => getAdvancedOptions(null, selection)}
            paginationType={PAGINATION_TYPE.cursor}
            paginationProps={{
              type: PAGINATION_TYPE.cursor, // FIXME: Change to classic
              resultsPerPage: resultsPerPage,
              onNextPage: handleNextPage,
              onPreviousPage: handlePreviousPage,
              hasNextPage: !loading && hasNextPage,
              hasPreviousPage: !loading && hasPreviousPage,
              loading,
            }}
          />
        </Card>
      )}
      <div ref={ref} />
      <RoleForm show={showForm} onClose={() => setShowForm(false)} isEditing={true} data={selectedRole} />
    </>
  );
};

const styles = {
  col: css``,
};

const COLUMN_DATA = [
  {
    label: <FormattedMessage id="MissionBoard.TableColumnRole" />,
    cell: (row) => (
      <Col gap="0.25rem">
        <RoleLink mission={row?.mission} missionRole={row} withOverlay />
        <span>
          <FormattedMessage
            id="MissionBoard.TableColumnNumApplicants"
            values={{
              count: row?.applicantsCount,
              link: (
                <PrimaryLink
                  to={LINKS.mission_applicants}
                  state={{
                    tab: 1,
                    missionRoleId: row?.id,
                    missionId: row?.mission?.id,
                    organizationId: row?.mission?.organization?.id,
                  }}
                  routerEnabled
                >
                  <FormattedMessage id="Global.View" />
                </PrimaryLink>
              ),
            }}
          />
        </span>
      </Col>
    ),
    width: "25%",
  },
  {
    label: <FormattedMessage id="MissionBoard.TableColumnSkillsAndDisciplines" />,
    cell: (row) => (
      <Row gap="0.25rem">
        <Tags data={row?.disciplines} limit={3} unwrap {...TAG_COLORS.purple} size={TAG_SIZE.small} />
        <Tags data={row?.primarySkills} limit={3} unwrap {...TAG_COLORS.pink} size={TAG_SIZE.small} />
      </Row>
    ),
    width: "25%",
  },
  {
    label: <FormattedMessage id="MissionBoard.TableColumnTargetRate" />,
    cell: (row) => formatCurrency(row?.targetRate, row?.targetRateCurrency),
    width: "15%",
  },
  {
    label: <FormattedMessage id="MissionBoard.TableColumnHours" />,
    cell: (row) => row?.engagementTime,
    width: "10%",
  },
  {
    label: <FormattedMessage id="MissionBoard.TableColumnStartDate" />,
    cell: (row) => moment(row?.startDate).format("MMM D, YYYY"),
    width: "10%",
  },
  {
    label: <FormattedMessage id="MissionBoard.TableColumnOpen" />,
    cell: (row) => <Status enabled={row?.isVisible}>{row?.isVisible ? "Yes" : "No"}</Status>,
    width: "10%",
  },
];

RolesList.defaultProps = {
  resultsPerPage: 20,
  filters: {},
  hideTutorial: true,
};

RolesList.propTypes = {
  isCardView: PropTypes.bool,
  missionId: PropTypes.string,
  filters: PropTypes.object,
  resultsPerPage: PropTypes.number,
  onSelect: PropTypes.func,
  selectionInitialState: PropTypes.array,
  hideTutorial: PropTypes.bool,
};

export default RolesList;
