import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useProfile, useCandidateCodeChallenge } from "hooks";
import DisplayBox from "components/DisplayBox";
import RenderIconWithText, { RENDER_SIZE } from "components/DisplayBox/Renderers/RenderIconWithText";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";
import { colors, fontSize, margins } from "style";
import { FORM_SETTINGS } from "components/Profile/ProfileSkills";
import { MEMBER_STATUS } from "constants/index";
import { withModalForm } from "components/Modal";
import { EditButton } from "components/Buttons";
import Tags, { TagDuration } from "components/Tags";
import { ShowMore } from "components/Pagination";
import { convertToPercent } from "utils";
import { EditLink } from "components/Links";

/**
 * CandidateCodeChallenge
 *
 * @params {String}   profileId
 * @params {Boolean}  isAdmin
 */
const CandidateCodeChallenge = ({ profileId, isAdmin, ...props }) => {
  const {
    data: profileData,
    profileCompletion,
    loading: profileLoading,
    isCodeChallengeComplete,
  } = useProfile({ profileId });

  const hasSkills = !profileLoading && profileCompletion?.skills?.isComplete;
  const { hasNextPage, handleNextPage, loading, data, handleCodeChallengeClick, STATUS_FAILED, STATUS_PASSED } =
    useCandidateCodeChallenge({
      profileId: profileData?.profile?.id,
      isAdmin,
    });
  const noResults = !loading && (!data || data?.length === 0);
  const isRejected = !isAdmin && profileData?.profile?.status === MEMBER_STATUS.rejected;
  const role = profileData?.profile?.missionRole;
  const roleNotAvailable = profileLoading || noResults || !role || !role.published;
  const isEmpty = (!isAdmin && !hasSkills && roleNotAvailable) || noResults || isRejected;
  const showTime = !isAdmin && !isCodeChallengeComplete && !isRejected && !loading && !profileLoading;

  return (
    <>
      <DisplayBox
        id="CodeChallenge"
        title={<FormattedMessage id="Candidate.CandidateCodeChallenge.Title" />}
        subtitle={showTime && <TagDuration text={"~2 hours"} />}
        description={
          <Description
            data={profileData?.profile?.skills}
            profileId={profileId}
            showSkills={roleNotAvailable}
            missionRole={role}
          />
        }
        emptyMsg={<FormattedMessage id="Candidate.CandidateCodeChallenge.Empty" />}
        loading={loading}
        isEmpty={isEmpty}
        numColumns={1}
        marginSize={margins.normal}
        data={data}
        itemRenderer={({ id, name, description, challengeResponse, icon, ...props }, index, loading) => {
          const challengePassed = challengeResponse?.status === STATUS_PASSED;
          const challengeFailed = challengeResponse?.status === STATUS_FAILED;
          const challengeCompleted = challengePassed || challengeFailed;
          const score = challengeResponse?.score / challengeResponse?.maxScore;
          const itemIcon = ICON_TYPE[icon] || ICON_TYPE.code;

          return (
            <RenderIconWithText
              key={name}
              loading={loading}
              size={RENDER_SIZE.large}
              icon={<Icon size={ICON_SIZE.xxlarge} color={colors.purpleRainDark2} {...props} type={itemIcon} />}
              text={{
                title: name,
                description: isAdmin
                  ? description
                    ? `${description} | ${convertToPercent(score)}`
                    : convertToPercent(score)
                  : description,
                link: (
                  <FormattedMessage
                    id={`Candidate.CandidateCodeChallenge.${isAdmin ? `AdminIncompleteLink` : `Link`}`}
                  />
                ),
                message: (
                  <div>
                    <FormattedMessage
                      id={
                        isAdmin
                          ? `Candidate.CandidateCodeChallenge.AdminLink`
                          : challengePassed
                          ? `Candidate.CandidateCodeChallenge.PassedMessage`
                          : `Candidate.CandidateCodeChallenge.FailedMessage`
                      }
                    />
                  </div>
                ),
              }}
              hasLink={isAdmin || !challengeCompleted}
              onClick={(event) => {
                event.stopPropagation();
                handleCodeChallengeClick(id, challengeResponse);
              }}
              status={{
                showIcon: challengeCompleted,
                success: challengePassed,
                fail: challengeFailed,
              }}
            />
          );
        }}
        {...props}
      />
      <ShowMore
        loading={loading}
        hasNextPage={!isEmpty && !loading && hasNextPage}
        onNextPage={handleNextPage}
        text={<FormattedMessage id="Candidate.CandidateCodeChallenge.ShowMore" />}
      />
    </>
  );
};

/**
 * Add Skills message and CTA
 *
 * @params {String}     id
 * @params {Function}   onEdit
 * @params {Object}     initialValues
 */
const AddSkillsButton = ({ id, onEdit, initialValues }) => (
  <div css={styles.add_skills_container}>
    <div css={styles.add_skills}>
      <FormattedMessage id="Candidate.CandidateCodeChallenge.AddSkills" />
    </div>
    <EditButton onClick={() => onEdit(initialValues, id)}>
      <FormattedMessage id="Profile.ProfileBio.AddSkills" />
    </EditButton>
  </div>
);

/**
 * AddSkillsLink
 *
 * @params {String}     id
 * @params {Function}   onEdit
 * @params {Object}     initialValues
 */
const AddSkillsLink = ({ id, onEdit, initialValues }) => (
  <EditLink onClick={() => onEdit(initialValues, id)}>
    <FormattedMessage id="Profile.ProfileBio.AddSkills" />
  </EditLink>
);

/**
 * Shows the description message with Tags list
 *
 * @params {Array}    data
 * @params {String}   profileId
 * @params {Boolean}  showSkills
 * @params {Object}   missionRole
 */
const Description = ({ data, profileId, showSkills, missionRole }) => (
  <div>
    <FormattedMessage
      id={`Candidate.CandidateCodeChallenge.${showSkills ? "" : "Challenges"}Description`}
      values={{
        role: <b>{missionRole?.name}</b>,
        mission: <b>{missionRole?.mission?.name}</b>,
      }}
    />
    {showSkills && (
      <>
        {!data || data.length === 0 ? (
          <AddSkillsWithModal id={profileId} initialValues={{}} />
        ) : (
          <div css={styles.skills_container}>
            <Tags data={data} />
            <AddSkillsLinkWithModal id={profileId} initialValues={{ skills: data }} />
          </div>
        )}
      </>
    )}
  </div>
);

const AddSkillsLinkWithModal = withModalForm(AddSkillsLink, FORM_SETTINGS);
const AddSkillsWithModal = withModalForm(AddSkillsButton, FORM_SETTINGS);

const styles = {
  icon_container: (isComplete, isEmpty) => css`
    width: ${RENDER_SIZE.large};
    height: ${RENDER_SIZE.large};
    background: ${colors.grayAnatomyLight5};
    color: ${isComplete ? colors.blueVelvetBase : !isEmpty ? colors.orangeCountyBase : colors.grayAnatomyLight2};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    position: relative;
  `,
  item_name: (isComplete) => css`
    color: ${isComplete ? colors.purpleRainDark2 : colors.grayAnatomyLight1};
    font-weight: ${isComplete ? `500` : `400`};
  `,
  success: css`
    width: 3rem;
    height: 3rem;
    border: 3px #fff solid;
    background: ${colors.blueVelvetBase};
    position: absolute;
    bottom: -3px;
    right: -3px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  add_skills: css`
    color: ${colors.purpleRainDark2};
    font-size: ${fontSize.small};
    line-height: 1.5;
    line-height: 1.5;
    background: ${colors.purpleRainLight5};
    padding: 1.5rem 3rem;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    text-align: center;
  `,
  add_skills_container: css`
    margin-bottom: 2rem;
    margin-top: 2rem;
  `,
  skills_container: css`
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 2rem;
  `,
};

AddSkillsLink.propTypes = {
  id: PropTypes.string,
  onEdit: PropTypes.func,
  initialValues: PropTypes.object,
};

AddSkillsButton.propTypes = {
  id: PropTypes.string,
  onEdit: PropTypes.func,
  initialValues: PropTypes.object,
};

Description.propTypes = {
  data: PropTypes.array,
  profileId: PropTypes.string,
  showSkills: PropTypes.bool,
  missionRole: PropTypes.object,
};

Description.defaultProps = {
  missionRole: null,
};

CandidateCodeChallenge.propTypes = {
  profileId: PropTypes.string,
  isAdmin: PropTypes.bool,
};

export default CandidateCodeChallenge;
