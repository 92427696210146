import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import PageTitle from "components/PageTitle";
import { Grid, PageWrapper } from "components/Containers";
import { DatePicker } from "components/Form";
import { PULSE_MODE, PulseTable } from "components/Squads/Pulse";

/**
 * Pulse Page
 */
const Pulse = () => {
  const intl = useIntl();
  const { id } = useParams();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const previousMonth = String(currentDate.getMonth() + 2).padStart(2, "0");
  const currentDay = String(currentDate.getDate()).padStart(2, "0");
  const startDate = `${currentYear - 1}-${previousMonth}-01`;
  const endDate = `${currentYear}-${currentMonth}-${currentDay}`;
  const [selectedDateRange, setSelectedDateRange] = useState([startDate, endDate]);

  const handleChange = (_name, value) => setSelectedDateRange(value);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.Pulse.Title" })}</title>
      </Helmet>
      <PageWrapper>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle
              subtitle={<FormattedMessage id="Squads.Pulse.Title" />}
              actionBar={
                <DatePicker
                  value={selectedDateRange}
                  onChange={handleChange}
                  maxDate={new Date()}
                  enableMonthRangePicker
                  enableDateCycle
                />
              }
            />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <PulseTable dateRange={selectedDateRange} teamId={id} mode={PULSE_MODE.team} />
          </Grid.col>
        </Grid>
        <Grid>
          <Grid.col start={1} end={13}>
            <PageTitle subtitle={<FormattedMessage id="Squads.Pulse.SquadDynamics" />} />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <PulseTable dateRange={selectedDateRange} teamId={id} mode={PULSE_MODE.profile} />
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default Pulse;
