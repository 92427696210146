import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { css } from "@emotion/react";
import Tabs from "components/Tabs";
import Card from "components/Card";
import PageTitle from "components/PageTitle";
import { Grid, PageWrapper } from "components/Containers";
import { useTeams, useAuth, useTimeSheet } from "hooks";
import { TimeSheetList, SubmitMonthButton, TimeSheetStatus } from "components/TimeSheet";
import { Select } from "components/Form";
import { Spinner } from "components/Loader";
import NoResults from "components/Messages/NoResults";

/**
 * TimeSheet Page
 */
const TimeSheet = () => {
  const { data: timeSheetData, loading: loadingtimeSheetData } = useTimeSheet();
  const { profileUuid } = useAuth();
  const [selectedMonth, setSelectedMonth] = useState();
  const [tabOptions, setTabOptions] = useState([]);
  const [total, setTotal] = useState(0);
  const [timeSlipsLoading, setTimeSlipsLoading] = useState(false);
  const [selectedTeam, setTeam] = useState(0);
  const [selectedTeamId, setSelectedTeamId] = useState("0");
  const intl = useIntl();

  // When there's no month selected picks first option as default
  useEffect(() => {
    if (!selectedMonth && timeSheetData?.length > 0) {
      setSelectedMonth(timeSheetData[0]);
    }
  }, [timeSheetData, loadingtimeSheetData]);

  // This should run after useTimeSheet hook sets selectedMonth data.
  const { data: teams, loading } = useTeams({
    filters: {
      includeOffboardedTeams: true,
      forMonth: selectedMonth?.monthPeriod,
    },
  });

  useEffect(() => {
    let options = [{ name: "All", id: "0" }];
    teams?.forEach(({ name, id }) => options.push({ name, id }));
    setTabOptions(options);
  }, [teams]);

  useEffect(() => {
    setSelectedTeamId(teams && teams[selectedTeam - 1] && teams[selectedTeam - 1].slug);
  }, [selectedTeam]);

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.TimeSheet.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle
            title={<FormattedMessage id={`TimeSheet.Title`} />}
            actionBar={
              <>
                <Select
                  name="monthPeriod"
                  options={timeSheetData}
                  labelKey={"period"}
                  valueKey={"monthPeriod"}
                  value={selectedMonth?.monthPeriod}
                  css={css`
                    width: 23rem;
                  `}
                  onChange={(name, value, obj) => setSelectedMonth(obj)}
                />
                <SubmitMonthButton
                  css={css`
                    order: 1;
                  `}
                  disabled={selectedMonth?.submitted === true || !total || timeSlipsLoading}
                  data={selectedMonth}
                  callBack={setSelectedMonth}
                  total={total}
                />
              </>
            }
          />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Card>
            <Grid>
              <Grid.col start={1} end={9}>
                <Tabs
                  data={tabOptions?.map(({ name }) => name)}
                  selected={selectedTeam}
                  onSelect={setTeam}
                  loading={loading}
                />
              </Grid.col>
              <Grid.col start={9} end={13}>
                <TimeSheetStatus selectedMonth={selectedMonth} allSelected={selectedTeam === 0} />
              </Grid.col>
              <Grid.col start={1} end={13}>
                {(loadingtimeSheetData || loading) && <Spinner margin={`1rem auto`} />}
                {timeSheetData?.length > 0 && !loadingtimeSheetData && !loading && (
                  <TimeSheetList
                    profileId={profileUuid}
                    selectedMonth={selectedMonth?.monthPeriod}
                    disabled={selectedMonth?.submitted === true}
                    selectedTeam={selectedTeamId}
                    onTotalUpdated={setTotal}
                    onLoading={setTimeSlipsLoading}
                  />
                )}
                {timeSheetData?.length === 0 && !loadingtimeSheetData && !loading && (
                  <NoResults messageId="TimeSheet.NoData" />
                )}
              </Grid.col>
            </Grid>
          </Card>
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default TimeSheet;
