import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { colors, fontSize } from "style";
import PropTypes from "prop-types";

/**
 * Formats the checkbox to accept a boolean for value
 *
 * @param {Function}     onChange
 * @param {Boolean}      value
 * @param {String}       label
 * @param {String}       name
 * @param {String}       description
 * @param {Boolean}      disabled
 * @param {Boolean}      noWrap
 */
const CheckBoxInputWithOnChange = ({ onChange, value, label, name, description, disabled, noWrap, ...props }) => {
  const handleChange = (e) => {
    if (onChange) {
      onChange(name, e.target.checked);
    }
  };
  const ranInt = Math.floor(Math.random() * 100);
  const formItemId = `label-${name}-${ranInt}`;

  if (label) {
    return (
      <span css={styles.checkbox_container}>
        <div>
          <CheckBox
            {...props}
            disabled={disabled}
            id={formItemId}
            onChange={handleChange}
            type="checkbox"
            checked={value}
            name={name}
          />
        </div>
        <div css={styles.label(description, value, disabled, noWrap)}>
          <label htmlFor={formItemId}>{label}</label>
          {description && (
            <label htmlFor={formItemId} css={styles.description}>
              {description}
            </label>
          )}
        </div>
      </span>
    );
  } else {
    return (
      <CheckBox {...props} disabled={disabled} onChange={handleChange} type="checkbox" checked={value} name={name} />
    );
  }
};

/**
 * CheckBox
 */
const CheckBox = styled.input`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 0.6rem;
  border: solid 1px ${colors.grayAnatomyLight3};
  background: ${(props) => (props.disabled ? colors.grayAnatomyLight5 : "#fff")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => (props.noMargin ? `0` : `0.25rem`)};
  vertical-align: top;
  appearance: none;
  color-adjust: exact;

  &:hover:not(:disabled) {
    border-color: ${colors.purpleRainBase};
  }

  &:checked {
    ${(props) =>
      props.inTable &&
      `
      background: ${colors.purpleRainDark1};
      border-color: ${colors.purpleRainDark1};
    `}

    &:after {
      content: "\\e912";
      position: absolute;
      font-family: "mission-icon" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: ${fontSize.medium};
      color: ${(props) => (props.disabled ? colors.grayAnatomyLight2 : props.inTable ? `#fff` : colors.purpleRainBase)};
    }
  }
`;

const styles = {
  checkbox_container: css`
    order: 1;
    display: inline-flex;
    align-items: flex-start;
  `,
  label: (description, checked, disabled, noWrap) => css`
    margin-left: 1rem;
    margin-top: 0.8rem;
    color: ${disabled ? colors.grayAnatomyLight2 : checked ? colors.purpleRainBase : colors.purpleRainDark2};
    font-size: ${fontSize.xsmall};
    font-weight: ${description ? `600` : `400`};
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    line-height: 1.25;
    white-space: ${noWrap ? `nowrap` : `normal`};
  `,
  description: css`
    font-weight: 400;
    margin-bottom: 0.5rem;
  `,
};

CheckBoxInputWithOnChange.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.bool,
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  noWrap: PropTypes.bool,
};

CheckBoxInputWithOnChange.defaultProps = {
  disabled: false,
};

export { CheckBox };

export default CheckBoxInputWithOnChange;
