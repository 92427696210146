import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import ReactSelect, { components } from "react-select";
import { useQueryData } from "hooks";
import { colors, fontSize } from "style";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { FormattedMessage } from "react-intl";

/**
 * Select
 *
 * @param {String}     value
 * @param {String}     defaultValue
 * @param {String}     name
 * @param {Array}      options
 * @param {Function}   onChange
 * @param {Boolean}    hasIcon
 * @param {Boolean}    disabled
 * @param {Boolean}    required
 * @param {String}     labelKey
 * @param {String}     valuekey
 * @param {String}     queryName
 * @param {String}     queryKeyName
 * @param {String}     queryVariables
 * @param {String}     querySearchFieldName       
 * @param {Boolean}    isSearchable
 * @param {Boolean}    isClearable
 * @param {Boolean}    loading
 * @param {String}     singleValuePrefix
 * @param {Function}   reloadData       
 * @param {String}     noOptionsMessage    
 * @param {String}     defaultSearch
 * @param {Boolean}    allowedSkip
 * @param {Object}     selection
 * @param {String}     placeholder
 *
 * Example Usage
   {
     options: [
       {
         value: "ca",
         label: "Canada",
       },
       {
         value: "us",
         label: "United States",
       },
     ],
   }
 */
const Select = ({
  value,
  defaultValue,
  name,
  options,
  onChange,
  hasIcon,
  disabled,
  required,
  labelKey,
  valueKey,
  queryName,
  queryKeyName,
  queryVariables,
  querySearchFieldName,
  isSearchable,
  isClearable,
  loading,
  singleValuePrefix,
  reloadData,
  noOptionsMessage,
  fetchPolicy,
  nextFetchPolicy,
  defaultSearch,
  allowedSkip,
  selection,
  placeholder,
  ...props
}) => {
  const selectInputRef = useRef();
  const [dataOptions, setDataOptions] = useState(options || []);
  const [focus, setFocus] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedDefaultValue, setSelectedDefaultValue] = useState([]);
  const [search, setSearch] = useState(defaultSearch);

  let filters = querySearchFieldName
    ? {
        ...queryVariables?.filters,
        [querySearchFieldName]: search,
      }
    : {
        ...queryVariables?.filters,
      };

  const isFiltersEmpty = Object.keys(filters).length === 0;

  const { data, loading: loadingData } = useQueryData({
    queryName,
    keyName: queryKeyName,
    skip: handleSkip(querySearchFieldName, search, queryKeyName, allowedSkip),
    fetchPolicy,
    nextFetchPolicy,
    variables: {
      ...queryVariables,
      // Only add filters to variables if it's not empty
      ...(isFiltersEmpty ? {} : { filters }),
    },
  });

  const handleChange = (val) => onChange(name, val && val[valueKey], val);

  const NoOptionsMessage = (props) => (
    <components.NoOptionsMessage {...props}>
      <FormattedMessage id={noOptionsMessage} />
    </components.NoOptionsMessage>
  );

  // Add the option that's already set but not in the options just like the paginated selections
  useEffect(() => {
    if (selection[valueKey] && !dataOptions?.find((item) => item[valueKey] === selection[valueKey])) {
      setDataOptions([...dataOptions, ...[selection]]);
    }
  }, [selection, dataOptions, valueKey]);

  // Set selected value
  useEffect(() => {
    setSelectedValue(dataOptions && dataOptions?.filter((item) => item[valueKey] === value));
    setFocus(false);
  }, [value, valueKey, dataOptions]);

  // set default value
  useEffect(() => {
    setSelectedDefaultValue(dataOptions && dataOptions.filter((item) => item[valueKey] === defaultValue));
  }, [defaultValue, valueKey, dataOptions]);

  useEffect(() => {
    if (data && data[queryKeyName]?.nodes) {
      const options = data[queryKeyName]?.nodes?.map((item) => ({
        [labelKey]: item[labelKey],
        [valueKey]: item[valueKey],
      }));

      // For selected value from the search results append the option
      // as it wouldn't be in the original options
      setDataOptions(selectedValue ? [...options, ...selectedValue] : options);
    }
  }, [data, queryKeyName, setDataOptions]);

  useEffect(() => {
    if (typeof options !== "undefined") {
      setDataOptions(options);
    }
  }, [options, setDataOptions]);

  useEffect(() => {
    if (!value && defaultValue) {
      onChange(name, value || defaultValue);
    }
  }, [value, defaultValue, onChange, name]);

  useEffect(() => {
    reloadData(value, queryKeyName);

    // Removing the logic to clear value for now
    // TODO: fix this logic to not break anything and support the clear field
    //if (!value && value != false) {setSelectedValue(null);

    if ((!value || value === -1) && value !== false && value !== 0) {
      setSelectedValue(null);
      selectInputRef?.current?.clearValue();
    }
  }, [value, queryKeyName]);

  return (
    <>
      <ReactSelect
        ref={selectInputRef}
        key={`select_box__${value}`}
        value={selectedValue ? selectedValue?.[0] : null}
        name={name}
        onChange={handleChange}
        options={dataOptions}
        isLoading={loading || loadingData}
        styles={styles(hasIcon, focus)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        defaultValue={selectedDefaultValue}
        onInputChange={(value) => setSearch(value)}
        isDisabled={disabled}
        isClearable={isClearable}
        isSearchable={isSearchable}
        getOptionLabel={(option) => option[labelKey]}
        isOptionSelected={(option) => option[valueKey] === selectedValue && selectedValue?.[0]?.[valueKey]}
        placeholder={focus && isSearchable ? <FormattedMessage id="Global.Search" /> : placeholder}
        components={{
          ClearIndicator,
          DropdownIndicator,
          NoOptionsMessage,
          SingleValue: (singleValueProps) => (
            <SingleValue {...singleValueProps} singleValuePrefix={singleValuePrefix} />
          ),
        }}
        {...props}
      />
      <input
        type="text"
        required={required || false}
        css={css`
          display: none;
        `}
        defaultValue={value}
      />
    </>
  );
};

/**
 * handleSkip
 *
 * If the querySearchFieldName is present then it will check, allowedSkip,
 * if the allowedSkip present and search is not present, in that case we will skip the query.
 */

const handleSkip = (querySearchFieldName, search, queryKeyName, allowedSkip) => {
  if (querySearchFieldName) {
    if (allowedSkip && !search) {
      return true;
    } else if (!allowedSkip && queryKeyName) {
      return false;
    }
    return !search || !queryKeyName;
  }
  return !queryKeyName;
};

/**
 * ClearIndicator
 *
 * @params {Function}     clearValue
 */
const ClearIndicator = ({ clearValue }) => (
  <div onClick={clearValue}>
    <Icon type={ICON_TYPE.close} size={ICON_SIZE.large} css={clearIndicatorStyle} />
  </div>
);

ClearIndicator.propTypes = {
  clearValue: PropTypes.func,
};

const clearIndicatorStyle = css`
  color: ${colors.grayAnatomyLight2};
  margin-right: 1rem;
  cursor: pointer;

  &:hover {
    color: ${colors.grayAnatomyLight1};
  }
`;

/**
 * ClearIndicator
 */
const DropdownIndicator = () => (
  <div>
    <Icon type={ICON_TYPE.chevronDown} size={ICON_SIZE.medium} css={dropdownIndicatorStyle} />
  </div>
);

const dropdownIndicatorStyle = css`
  color: ${colors.grayAnatomyLight2};
  margin-right: 1rem;
  cursor: pointer;
  border-left: 1px ${colors.grayAnatomyLight3} solid;
  height: 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 1rem;

  &:hover {
    color: ${colors.grayAnatomyLight1};
  }
`;

/**
 * SingleValue
 *
 * @params {Elements}     element
 * @params {Elements}     object
 */
const SingleValue = ({ children, singleValuePrefix, ...props }) => {
  return (
    <components.SingleValue {...props}>
      {singleValuePrefix && <b>{singleValuePrefix}</b>}
      {children}
    </components.SingleValue>
  );
};

SingleValue.propTypes = {
  children: PropTypes.string,
  singleValuePrefix: PropTypes.any,
};

const styles = (hasIcon, focus) => ({
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#fff" : colors.purpleRainDark2,
    background: state.isSelected ? colors.purpleRainBase : "#fff",
    height: `auto`,
    fontSize: fontSize.xsmall,
    padding: "1.4rem 1.6rem",
    lineHeight: "normal",
    "&:hover": {
      height: `auto`,
      background: state.isSelected ? colors.purpleRainBase : colors.grayAnatomyLight5,
    },
  }),
  menu: (provided) => ({
    ...provided,
    border: `1px solid ${colors.grayAnatomyLight3}`,
    boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.06)",
    fontSize: fontSize.small,
    color: colors.grayAnatomyLight3,
    zIndex: 10,
  }),
  container: (provided) => ({
    ...provided,
    width: "100%",
    order: 1,
    "&:hover + label": {
      color: colors.purpleRainBase,
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    marginLeft: 0,
    marginTop: 0,
    fontFamily: "Matter",
    fontSize: fontSize.small,
    color: colors.grayAnatomyLight2,
    position: `relative`,
    height: `4rem`,
    lineHeight: `4rem`,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: colors.grayAnatomyLight3,
    height: "46%",
    marginTop: "25%",
    display: "none",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    background: state.isDisabled ? colors.grayAnatomyLight5 : "#fff",
    transition: "all 0.3s ease",
    border: `1px solid ${focus ? colors.purpleRainBase : colors.grayAnatomyLight3}`,
    boxShadow: focus ? `0px 0px 0px 3px ${colors.purpleRainLight3}` : `none`,
    color: state.isDisabled ? colors.grayAnatomyLight2 : colors.grayAnatomyBase,
    fontFamily: "Matter",
    fontSize: fontSize.xsmall,
    borderRadius: `0.6rem`,
    paddingLeft: hasIcon ? `3.4rem` : `1rem`,
    height: `4rem`,
    minHeight: `4rem`,
    width: "100%",
    "&:hover": {
      borderColor: colors.purpleRainBase,
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "inherit",
    marginLeft: 0,
    marginRight: 0,
    position: `relative`,
    top: `-1px`,
    height: `4rem`,
    lineHeight: `4rem`,
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
  }),
});

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueKey: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  hasIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  labelKey: PropTypes.string,
  queryName: PropTypes.object,
  queryKeyName: PropTypes.string,
  queryVariables: PropTypes.object,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  loading: PropTypes.bool,
  singleValuePrefix: PropTypes.any,
  reloadData: PropTypes.func,
  querySearchFieldName: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  fetchPolicy: PropTypes.string,
  nextFetchPolicy: PropTypes.string,
  defaultSearch: PropTypes.string,
  allowedSkip: PropTypes.bool,
  selection: PropTypes.object,
  placeholder: PropTypes.string,
};

Select.defaultProps = {
  hasIcon: false,
  loading: false,
  labelKey: "label",
  valueKey: "value",
  singleValuePrefix: "",
  isClearable: false,
  reloadData: (v, t) => ({ v, t }),
  fetchPolicy: "cache-first",
  nextFetchPolicy: "cache-first",
  noOptionsMessage: "Form.Select.noOptions",
  defaultSearch: "",
  allowedSkip: false,
  selection: {},
};

export { styles };

export default Select;
