import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import debounce from "lodash.debounce";
import { DataTableProvider } from "components/DataTable";
import { PAGINATION_TYPE } from "components/Pagination";
import { formatDate, formatNumber } from "utils";
import { LINKS } from "constants/index";
import { useMissions } from "hooks";
import Tabs from "components/Tabs";
import Status from "components/Status";
import { TextInput } from "components/Form";
import { ICON_POSITION, ICON_SIZE, ICON_TYPE, withIcon } from "components/Icon";
import { Grid } from "components/Containers";

/**
 * MissionsList
 */
const MissionsList = () => {
  const paginationType = PAGINATION_TYPE.classic;
  const [selectedTab, setSelectedTab] = useState(0);
  const [keyword, setKeyword] = useState();
  const [debounceKeyword, setDebounceKeyword] = useState();
  const navigate = useNavigate();
  const debouncedSetKeyword = useMemo(() => debounce((val) => setDebounceKeyword(val), 500), []);

  const {
    loading,
    loadingMore,
    error,
    data,
    pageInfo,
    totalCount,
    handleNextPage,
    handlePreviousPage,
    handlePageChange,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    resultsPerPage,
  } = useMissions({
    published: TAB_OPTIONS[selectedTab]?.published,
    readUrlPagination: true,
    paginationType,
    filters: { search: debounceKeyword },
    additionalVariables: { includeRoles: false },
  });

  const handleSearchInputChange = (val) => {
    setKeyword(val);
    debouncedSetKeyword(val);
    handlePageChange(1, resultsPerPage);
  };

  return (
    <Grid>
      <Grid.col start={1} end={7}>
        <Tabs
          name="type"
          data={TAB_OPTIONS?.map(({ name }) => name)}
          selected={selectedTab}
          onSelect={setSelectedTab}
          withUrlParams
        />
      </Grid.col>
      <Grid.col start={7} end={13}>
        <SearchInput
          value={keyword}
          onChange={(name, val) => handleSearchInputChange(val)}
          type="search"
          placeholder="Search by mission or client name"
        />
      </Grid.col>
      <Grid.col start={1} end={13}>
        <DataTableProvider
          data={data}
          error={error}
          loading={loading || loadingMore}
          resultsPerPage={resultsPerPage}
          columns={COLUMN_DATA(navigate)}
          paginationProps={{
            type: paginationType,
            resultsPerPage: resultsPerPage,
            onNextPage: handleNextPage,
            handlePageChange: handlePageChange,
            onPreviousPage: handlePreviousPage,
            hasNextPage: !loading && hasNextPage,
            hasPreviousPage: !loading && hasPreviousPage,
            loading,
            pageInfo,
            totalCount,
            currentPage,
            countMessage: "Global.Missions.Count",
          }}
        />
      </Grid.col>
    </Grid>
  );
};

const COLUMN_DATA = (navigate) => [
  {
    label: <FormattedMessage id="Missions.TableColumnMission" />,
    cell: (row) => row?.name,
    onClick: (row) => navigate(LINKS.mission_edit(row?.uuid)),
    width: "25%",
  },
  {
    label: <FormattedMessage id="Missions.TableColumnClient" />,
    onClick: (row) => navigate(LINKS.organization_profile(row?.organization?.id)),
    cell: (row) => row?.organization?.name,
  },
  {
    label: <FormattedMessage id="Missions.TableColumnStatus" />,
    cell: (row) => (
      <Status enabled={row?.published}>
        <FormattedMessage id={`Missions.${row?.published ? "Published" : "Unpublished"}`} />
      </Status>
    ),
  },
  {
    label: <FormattedMessage id="Missions.TableColumnContact" />,
    cell: (row) => row?.client?.name,
  },
  {
    label: <FormattedMessage id="Missions.TableColumnStartDate" />,
    cell: (row) => formatDate(row?.startDate),
  },
  {
    label: <FormattedMessage id="Missions.TableColumnRoles" />,
    cell: (row) => formatNumber(row?.rolesCount),
    width: "10%",
  },
];

const TAB_OPTIONS = [
  { name: "All", id: 0, published: null },
  { name: "Published", id: 1, published: true },
  { name: "Unpublished", id: 2, published: false },
];

const SearchInput = withIcon(TextInput, {
  type: ICON_TYPE.search,
  size: ICON_SIZE.medium,
  position: ICON_POSITION.overlay,
  order: 0,
});

export default MissionsList;
