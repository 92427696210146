import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useInterviewResponses } from "hooks";
import { INTERVIEW_STATUSES } from "constants";
import { DestructableButton } from "components/Buttons";

/**
 * ProfileInterviewsDeleteButton
 * @param {String}    profileId
 * @param {String}    interviewId
 * @param {Boolean}   canDelete
 */
const ProfileInterviewsDeleteButton = ({ profileId, interviewId, canDelete }) => {
  const { handleDeleteInterviewResponse } = useInterviewResponses(
    { filters: { profileId, status: INTERVIEW_STATUSES.completed } },
    true
  );

  if (!canDelete) return null;

  return (
    <DestructableButton onClick={() => handleDeleteInterviewResponse(interviewId)} disabled={!interviewId}>
      <FormattedMessage id="Profile.ProfileInterviews.DeleteInterviewButton.Title" />
    </DestructableButton>
  );
};

ProfileInterviewsDeleteButton.propTypes = {
  profileId: PropTypes.string,
  interviewId: PropTypes.string,
  canDelete: PropTypes.bool,
};

export default ProfileInterviewsDeleteButton;
